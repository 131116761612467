import { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Flag: React.FunctionComponent<Props> = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path fill="#f0f0f0" d="M0 85h512v341H0z" />
    <g fill="#d80027">
      <path d="M0 85h512v114H0zM0 313h512v114H0z" />
    </g>
  </svg>
)

export default Flag
