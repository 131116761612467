import { makeStyles } from "@material-ui/core/styles"
import Typography from "common/Typography"
import Cart from "models/Cart"
import * as React from "react"
import { useTranslation } from "react-i18next"

type Props = {
  basket: Cart | null
}

const CartShipping: React.FunctionComponent<Props> = ({ basket }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  if (!basket || basket.lineItems.length <= 0) {
    return null
  }

  return (
    <div className={classes.root}>
      <Typography
        variant="subHeading"
        color="textPrimary"
        className={classes.shipping}
      >
        <strong className={classes.emphasized}>
          {basket.properties && basket.properties["orderQualifyForFreeShipping"]
            ? t("cart.free_shipping_achieved")
            : `${t("cart.free_shipping_from")} ${
                basket.properties &&
                basket.properties["freeShippingConditionAmountString"]
              }`}
        </strong>
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    width: spacing(51.75),
    height: "52px",
    backgroundColor: palette.rapunzel.b2bBackground,
    [breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  shipping: {
    textAlign: "center",
    marginTop: "6px"
  },
  emphasized: {
    display: "inline-block"
  }
}))

export default CartShipping
