import { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Flag: React.FunctionComponent<Props> = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path fill="#d80027" d="M0 85.3h512v341.3H0z" />
    <path
      fill="#f0f0f0"
      d="M512 295.9H202.2v130.8H122.4V295.9H0V216.1h122.4V85.3H202.2v130.8H512v61.2z"
    />
    <path
      fill="#2e52b2"
      d="M512 234.7v42.6H183.7v149.4H141V277.3H0v-42.6h141V85.3h42.7v149.4z"
    />
  </svg>
)

export default Flag
