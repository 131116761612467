const colors = {
  rapunzel: {
    primary: "#F2E3D8",
    textDefault: "rgba(0, 0, 0, 0.84)",
    textSecondary: "rgba(0, 0, 0, 0.54)",
    textDisabled: "rgba(0, 0, 0, 0.2)",
    dark: "rgba(0, 0, 0, 0.84)",
    lightDark: "rgba(0, 0, 0, 0.54)",
    transparentGray: "rgba(0, 0, 0, 0.4)",
    transparentDark: "rgba(0, 0, 0, 0.2)",
    darkBorder: "rgba(0, 0, 0, 0.1)",
    errorBorder: "#CB5E4F",
    warningBackground: "#fbba42",
    textDiscount: "#c37a7a",
    accentColorPowder1: "#D9C2B6",
    accentColorPowder1Light: "#dcc7bc",
    accentColorPowder2: "#ECE0DB",
    accentColorPowder3: "#F5F0ED",
    accentColorGray1: "#B1A7A2",
    accentColorGray2: "#D8D3D0",
    accentColorGray3: "#EBE9E8",
    accentColorGray4: "#999999",
    paperBackground: "#FFFFFF",
    white: "#FFFFFF",
    black: "#000000",
    lightGray: "#F7F7F7",
    mercury: "#E5E5E5",
    alto: "#DDDDDD",
    specialDay: "#FD1E44",
    starColor: "#FEDD00",
    darkGray: "#292929",
    pink: "#d65050",
    pinkLight: "#e9afaa",
    pinkMedium: "#f0c6c3",
    ctaButton: "#A9BF8D",
    ctaButtonDark: "#191919",
    complimentary: "#009900",
    brown: "#c3a78e",
    b2bBackground: "#f5ebe1",
    greenButton: "#A9BF8D",
    greenButtonHover: "#92AB72",
    darkCharcoal: "#333333",
    silverGray: "#F0F0F0"
  },

  text: {
    primary: "#000000",
    secondary: "#FFFFFF",
    disabled: "#000000",
    hint: "#000000"
  },
  white: {
    main: "#FFFFFF"
  },
  black: {
    main: "#000000"
  },
  red: {
    main: "#BA1B1B",
    light: "rgba(197, 56, 56, 0.1)"
  },
  branding: {
    yellow: {
      dark: "#DC880B", // 600
      main: "#F7A600", // 500
      light: "#FFC54D", // 400,
      400: "#FFC54D",
      500: "#F7A600",
      600: "#DC880B"
    },
    grey: {
      light: "#F6F6F5",
      100: "#F7F7F7",
      200: "#EAEAEA",
      300: "#D9D9D9",
      400: "#B2B2B2",
      main: "#9E9E9E",
      dark: "#707070",
      700: "#575757",
      800: "#3C3C3C"
    },
    toast: {
      100: "#000000", // Info
      200: "#000000", // Success
      300: "#000000", // Warning
      400: "#000000" // Error
    }
  },
  ui: {
    light: "rgba(0, 0, 0, 0.1)",
    main: "#F7F7F7",
    dark: "#DEDEDE"
  },
  info: {
    contrastText: "#000000"
  },
  success: {
    light: "rgb(204 239 204)",
    contrastText: "#000000"
  },
  warning: {
    contrastText: "#000000"
  },
  error: {
    light: "rgba(197, 56, 56, 0.1)",
    contrastText: "#000000"
  }
}

/**
 * Theme - Colors
 */
export default colors
