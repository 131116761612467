import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import { MenuItem as MenuItemType } from "@starrepublic/epi/types/cms/menu"
import classNames from "classnames"
import { browserHistory } from "common/Root"
import useStore from "global-hook-store"
import React from "react"
import siteSettingsStore from "stores/siteSettingsStore"

type Props = {
  item: MenuItemType
  goToSubMenu: (item: MenuItemType) => void
  closeDrawer: (isOpen: boolean) => void
}

const MenuItem: React.FunctionComponent<Props> = ({
  item,
  goToSubMenu,
  closeDrawer,
  children,
  ...rest
}) => {
  const hasChildren = item.children && item.children.length

  const classes = useStyles()

  const {
    state: { menuSignificantWords }
  } = useStore(siteSettingsStore)

  return hasChildren ? (
    <>
      <ListItem button onClick={() => goToSubMenu(item)} {...rest}>
        {children}
        <KeyboardArrowRight />
      </ListItem>
      <Divider className={classes.divider} />
    </>
  ) : (
    <>
      <ListItem
        className={classNames(classes.link, {
          [classes.significantMenuItem]: menuSignificantWords
            .map(x => x.toLowerCase())
            .includes(item.title.toLowerCase())
        })}
        component={"div"}
        button
        onClick={() => {
          closeDrawer(false)
          browserHistory.push(item.url)
        }}
      >
        {children}
      </ListItem>
      <Divider className={classes.divider} />
    </>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  divider: {
    margin: `0 ${spacing(2)}px`
  },
  link: {
    padding: `${spacing(2)}px ${spacing(3)}px;`
  },
  significantMenuItem: {
    color: palette.rapunzel.textDiscount
  }
}))

export default MenuItem
