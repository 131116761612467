import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import { makeStyles } from "@material-ui/core/styles"
import shadows from "@material-ui/core/styles/shadows"
import FullScreenMarketSelector from "common/FullScreenMarketSelector"
import ShoppingCart from "common/ShoppingCart"
import Typography from "common/Typography"
import useStore from "global-hook-store"
import React, { useCallback, useEffect, useState } from "react"
import { Transition } from "react-transition-group"
import authStore from "stores/authStore"
import cartDrawerStore from "stores/cartDrawerStore"

import InactiveLanguageInfo from "./InactiveLanguageInfo"
import MegaMenu from "./MegaMenu"
import PromotionBar from "./PromotionBar"
import TopHeader from "./TopHeader"

const HEADER_BOX_SHADOW = "0px 7px 7px 0px rgba(0, 0, 0, 0.06)"
const TRANSITION_DURATION = 250

type Props = {
  newSearchDesign?: boolean
}

let lastScrollPosition = 0
let isHeaderVisible = true

const Header: React.FunctionComponent<Props> = ({
  newSearchDesign = false
}) => {
  const {
    state: cartOpen,
    actions: { setCartOpen }
  } = useStore(cartDrawerStore)
  const {
    state: { isInitiallyLoaded }
  } = useStore(authStore)

  const classes = useStyles()
  const [activeHeader, setActiveHeader] = useState(true)
  const { ENVIRONMENT, CURRENT_LANGUAGE_IS_DEPRICATED } = window
  const [showInactiveLanguageInfo, setShowInactiveLanguageInfo] = useState(
    CURRENT_LANGUAGE_IS_DEPRICATED
  )

  const toggleHeader = useCallback(() => {
    const currentScroll = window.pageYOffset
    const scrollMax = newSearchDesign ? 100 : 200
    if (currentScroll > scrollMax && lastScrollPosition <= currentScroll) {
      lastScrollPosition = currentScroll
      isHeaderVisible && setActiveHeader(false)
      isHeaderVisible = false
    } else {
      lastScrollPosition = currentScroll
      !isHeaderVisible && setActiveHeader(true)
      isHeaderVisible = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHeaderVisible, lastScrollPosition])

  useEffect(() => {
    window.addEventListener("scroll", toggleHeader)
    return () => {
      window.removeEventListener("scroll", toggleHeader)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const transitionStyles = {
    entering: { transform: "translateY(0)" },
    entered: { transform: "translateY(0)" },
    exiting: { transform: "translateY(-60px)" },
    exited: { transform: "translateY(-60px)" }
  }

  return (
    <>
      {(!ENVIRONMENT ||
        ENVIRONMENT.toLowerCase() === "preproduction" ||
        ENVIRONMENT.toLowerCase() === "integration") && (
        <Typography component="div" className={classes.envBar} align="center">
          {ENVIRONMENT || "DEVELOPMENT"}
        </Typography>
      )}

      <FullScreenMarketSelector />

      <Hidden smDown>
        <Transition
          in={activeHeader}
          timeout={{ enter: TRANSITION_DURATION, exit: TRANSITION_DURATION }}
        >
          {state => (
            <>
              <InactiveLanguageInfo
                show={showInactiveLanguageInfo}
                triggerHide={() => setShowInactiveLanguageInfo(false)}
              />
              <div
                className={classes.header}
                style={{
                  ...transitionStyles[state]
                }}
              >
                <>
                  <TopHeader />
                  {isInitiallyLoaded && (
                    <MegaMenu
                      newSearchDesign={newSearchDesign}
                      activeHeader={!activeHeader}
                    />
                  )}
                </>
              </div>
            </>
          )}
        </Transition>
      </Hidden>
      <Hidden mdUp>
        <InactiveLanguageInfo
          show={showInactiveLanguageInfo}
          triggerHide={() => setShowInactiveLanguageInfo(false)}
        />
        <div className={classes.header}>
          <MegaMenu
            newSearchDesign={newSearchDesign}
            activeHeader={!activeHeader}
          />
        </div>
      </Hidden>
      <PromotionBar />
      <Drawer
        classes={{ paper: classes.shoppingDrawer }}
        anchor="top"
        open={cartOpen}
        onClose={() => setCartOpen(false)}
        BackdropProps={{
          classes: { root: classes.overLay }
        }}
      >
        <ShoppingCart />
      </Drawer>
    </>
  )
}

const SHADOW_DEPTH = 16

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  header: {
    backgroundColor: "white",
    position: "fixed",
    top: 0,
    zIndex: 1201,
    width: "100vw",
    boxShadow: HEADER_BOX_SHADOW,
    transition: `transform ${TRANSITION_DURATION}ms ease-in-out`,
    transform: "translateY(0)"
  },
  envBar: {
    width: "10%",
    background: palette.rapunzel.warningBackground,
    position: "fixed",
    zIndex: 1000,
    padding: `${spacing(0.5)}px 0px`,
    color: palette.rapunzel.textDefault,
    bottom: 0,
    left: 0,
    [breakpoints.down("md")]: {
      bottom: 0,
      width: "100%"
    }
  },
  shoppingDrawer: {
    left: "auto",
    marginTop: "50px",
    width: spacing(51.75),
    [breakpoints.up("sm")]: {
      right: "15px"
    },
    [breakpoints.down("xs")]: {
      width: "100%",
      borderBottomLeftRadius: "0px",
      borderBottomRightRadius: "0px"
    },
    boxShadow: shadows[SHADOW_DEPTH]
  },
  overLay: {
    backgroundColor: "rgba(0, 0, 0, 0.2)"
  }
}))

export default Header
export { HEADER_BOX_SHADOW }
