import { Initialization } from "@microsoft/applicationinsights-web/types/Initialization"
import React, { useEffect, useState } from "react"

type Props = {
  path: string
}

const { ENVIRONMENT, IS_BOT } = window

const instrumentationKeys = {
  Production: process.env.REACT_APP_APPLICATIONINSIGHTS_PRODUCTION_KEY,
  Preproduction: process.env.REACT_APP_APPLICATIONINSIGHTS_INTEGRATION_KEY,
  Integration: process.env.REACT_APP_APPLICATIONINSIGHTS_INTEGRATION_KEY,
  Development: process.env.REACT_APP_APPLICATIONINSIGHTS_INTEGRATION_KEY
}

const ApplicationInsights: React.FC<Props> = ({ path }: Props) => {
  const [appInsights, setAppInsights] = useState<Initialization>()
  const [lastAITrack, setLastAITrack] = useState<number | null>(null)

  useEffect(() => {
    const getAppInsights = async () => {
      const ApplicationInsights = await (
        await import("@microsoft/applicationinsights-web")
      ).ApplicationInsights

      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: instrumentationKeys[ENVIRONMENT || "Development"]
        }
      })

      appInsights.loadAppInsights()

      setAppInsights(appInsights)
    }

    getAppInsights()
  }, [])

  useEffect(() => {
    if (appInsights && !IS_BOT && !window["ignoreApiRequest"]) {
      let doTrack = true

      if (lastAITrack) {
        const millisecondsSinceLastTrack = Date.now() - lastAITrack
        if (millisecondsSinceLastTrack < 500) {
          doTrack = false
        }
      }

      if (doTrack) {
        appInsights.trackPageView({ name: path })
        setLastAITrack(Date.now())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInsights, path])

  return null
}

export default ApplicationInsights
