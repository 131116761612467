export const ContentSavedTopic = "beta/contentSaved"
export const EpiReadyTopic = "beta/epiReady"

export interface EpiContext {
  inEditMode: boolean
  isEditable: boolean
  ready: boolean
}

export interface EpiConnector {
  beta: EpiContext
  subscribe: (
    topic: typeof ContentSavedTopic | typeof EpiReadyTopic,
    callback: ContentSavedCallback
  ) => Subscription
}

type ContentSavedCallback = (details: ContentSavedDetails) => void

export interface Subscription {
  remove: () => void
}

interface ContentSavedDetails {
  successful: boolean
  contentLink: string
  hasContentLinkChanged: boolean
  publishedContentLink: string

  properties: Property[]
  savedContentLink: string
  validationErrors: []
}

export interface Property {
  name: string
  value: any
  successful: boolean
  validationErrors: []
  committed: boolean
}

export interface ChannelManager {
  subscribeToContentSaved: (
    callback: (properties: Property[]) => void
  ) => Promise<Subscription | undefined>
  isEditMode: () => Promise<boolean>
}
