import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import classNames from "classnames"
import Typography from "common/Typography"
import useStore from "global-hook-store"
import ArrowSmall from "icons/ArrowSmall"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import checkoutStore from "stores/checkoutStore"
import colors from "theme/colors"

const CouponField: React.FunctionComponent = () => {
  const [couponCode, setCouponCode] = useState("")
  const [error, setError] = useState("")
  const { t } = useTranslation()
  const classes = useStyles()

  const couponCodeErrorMessage = t("checkout.invalid_coupon_code")

  const {
    actions: { addCouponCode, resetCouponCode },
    state: {
      coupon: { error: apiError, data: code, loading }
    }
  } = useStore(checkoutStore)

  useEffect(() => {
    if (apiError) {
      setError(couponCodeErrorMessage)
    } else {
      setError("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError])

  const handleSubmit = () => {
    if (couponCode.length < 3 || couponCode.length > 24) {
      setError(couponCodeErrorMessage)
    } else {
      addCouponCode(couponCode)
    }
  }
  useEffect(() => {
    if (apiError) {
      const couponCodeErrorMessage = t(
        `error.${apiError.toString().toLocaleLowerCase()}`
      )
      setError(couponCodeErrorMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError])

  useEffect(() => {
    if (code) {
      setCouponCode("")
    }
  }, [code])

  return (
    <>
      <div className={classes.root}>
        <div
          className={classNames(
            classes.couponForm,

            {
              [classes.formGroupError]: error
            }
          )}
        >
          <TextField
            autoFocus
            disabled={loading}
            placeholder={t("checkout.coupon_input_label")}
            InputProps={{
              disableUnderline: true,
              onKeyDown: e => {
                if (e.key === "Enter") {
                  handleSubmit()
                }
              }
            }}
            fullWidth={true}
            value={couponCode}
            className={classes.input}
            onChange={e => {
              if (error) {
                resetCouponCode()
              }
              setError("")
              setCouponCode(e.target.value)
            }}
          />
        </div>
        <div
          className={classNames(
            {
              [classes.disabled]: loading
            },
            classes.buttonField
          )}
          onClick={handleSubmit}
        >
          <ArrowSmall
            direction="left"
            stroke={colors.rapunzel.white}
            className={classes.arrowForwardIcon}
          />
        </div>
      </div>

      <Typography component="div" variant="body2" color="error" align="left">
        {error}
      </Typography>
    </>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: "flex"
  },

  couponForm: {
    display: "flex",
    justifyContent: "flex-start",
    border: `1px solid ${palette.rapunzel.darkBorder}`,
    flexDirection: "row",
    alignItems: "center",
    width: "100%"
  },
  couponWidth: {
    minWidth: "calc(100% - 89px)"
  },
  errorWidth: {
    Width: "calc(100% - 89px)"
  },
  disabled: {
    opacity: 0.4
  },
  formGroupError: {
    border: `1px solid ${palette.rapunzel.errorBorder}`
  },
  input: {
    backgroundColor: palette.primary.contrastText,
    padding: `7px ${spacing(2)}px`
  },
  buttonField: {
    color: palette.rapunzel.white,
    fontSize: spacing(7),
    minWidth: 75,
    height: spacing(6),
    backgroundColor: palette.rapunzel.ctaButtonDark,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: "14px"
  },
  arrowForwardIcon: {
    transform: "rotate(180deg)"
  }
}))

export default CouponField
