/* eslint-disable */
export default () => {
  var tehEvent = new Array()
  tehEvent.closetimer = 0
  tehEvent.contentSet = 0

  var mywidth = 238
  var myheight = 269

  var targetReachable = false

  function TEH_EVENT_CreateIframe() {
    var element = document.createElement("iframe")
    var teJsHost = "https:" === document.location.protocol ? "https" : "http"
    element.src = "https://cert.tryggehandel.se/legacy/verifystore/134"
    element.style.display = "none"
    element.style.position = "fixed"
    element.style.width = mywidth + "px"
    element.style.height = myheight + "px"
    element.style.border = "0px"
    element.style.zIndex = "9999"
    element.frameBorder = "0"
    element.scrolling = "no"
    element.allowTransparency = "true"

    // Make sure target is reachable
    var targetImage = new Image()
    targetImage.onload = function () {
      targetReachable = true
    }
    targetImage.src =
      teJsHost +
      "://www.tryggehandel.se/images/trans.gif?t=" +
      new Date().getTime()

    if (element.addEventListener) {
      element.addEventListener("mouseover", TEH_EVENT_Show, false)
      element.addEventListener("mouseout", TEH_EVENT_HideTime, false)
    } else {
      element.attachEvent("onmouseover", TEH_EVENT_Show)
      element.attachEvent("onmouseout", TEH_EVENT_HideTime)
    }

    return element
  }
  function findPosX(obj) {
    var curleft = 0
    if (obj.offsetParent) {
      while (obj.offsetParent) {
        curleft += obj.offsetLeft
        obj = obj.offsetParent
      }
    } else if (obj.x) {
      curleft += obj.x
    }

    return curleft
  }
  function findPosY(obj) {
    var curtop = 0
    if (obj.offsetParent) {
      while (obj.offsetParent) {
        curtop += obj.offsetTop
        obj = obj.offsetParent
      }
    } else if (obj.y) {
      curtop += obj.y
    }

    return curtop
  }

  var tehEventShowI = 0
  function TEH_EVENT_Show(event) {
    if (!tehEvent.iframe) {
      tehEvent.iframe = TEH_EVENT_CreateIframe()
      document.body.appendChild(tehEvent.iframe)
    }

    if (targetReachable === true) {
      TEH_EVENT_Show_popup()
    } else if (tehEventShowI < 10) {
      tehEventShowI++
      tehEvent.opentimer = window.setTimeout(TEH_EVENT_Show, 300)
    }
  }

  function TEH_EVENT_Show_popup(event) {
    TEH_EVENT_CancelClose()
    if (!tehEvent.iframe) {
      tehEvent.iframe = TEH_EVENT_CreateIframe()
      document.body.appendChild(tehEvent.iframe)
    }
    if (tehEvent.iframe.style.display === "none") {
      var yy = document.documentElement.scrollTop
        ? document.documentElement.scrollTop
        : document.body.scrollTop
      var px = GetWndCliSiz("Width")
      var py = GetWndCliSiz("Height")
      var llogo = document.getElementById("TEH_LOGOTYPE")
      var tx = llogo.width
      var ty = llogo.height
      var lx = findPosX(llogo)
      var ly = findPosY(llogo)
      var totalwidth = tx + lx + mywidth
      var totalheight = ly + ty + myheight + yy
      var yDiff = null
      if (totalwidth > px) {
        var newposx = lx - mywidth + 20
        tehEvent.iframe.style.left = newposx + "px"
      } else {
        tehEvent.iframe.style.left = lx + tx + "px"
      }

      if (window.scrollY !== undefined) {
        yDiff = ly - window.scrollY
        //@ts-check
        tehEvent.iframe.style.top = self.pageYOffset + yDiff + "px"
      } else {
        yDiff = ly - document.body.parentNode.scrollTop
        tehEvent.iframe.style.top =
          document.documentElement.scrollTop + yDiff + "px"
      }
    }
    tehEvent.iframe.style.display = "inline"
  }
  function TEH_EVENT_Hide() {
    if (tehEvent.iframe) {
      tehEvent.iframe.style.display = "none"
    }
  }
  function TEH_EVENT_ShowTime() {
    tehEvent.opentimer = window.setTimeout(TEH_EVENT_Show, 300)
  }
  function TEH_EVENT_HideTime() {
    if (tehEvent.iframe) {
      tehEvent.closetimer = window.setTimeout(TEH_EVENT_Hide, 500)
    } else {
      window.clearTimeout(tehEvent.opentimer)
    }
  }
  function TEH_EVENT_CancelClose() {
    if (tehEvent.closetimer) {
      window.clearTimeout(tehEvent.closetimer)
      tehEvent.closetimer = null
    }
  }
  function GetWndCliSiz(s) {
    var db
    var y = window["inner" + s]
    var sC = "client" + s
    var sN = "number"
    if (typeof y === sN) {
      var y2
      return (db = document.body) &&
        typeof (y2 = db[sC]) === sN &&
        y2 &&
        y2 <= y
        ? y2
        : (db = document.documentElement) &&
          typeof (y2 = db[sC]) === sN &&
          y2 &&
          y2 <= y
        ? y2
        : y
    }
    return (db = document.documentElement) && (y = db[sC])
      ? y
      : document.body[sC]
  }
  if (document.addEventListener) {
    document
      .getElementById("TEH_LOGOTYPE")
      .addEventListener("mouseover", TEH_EVENT_Show, false)
    document
      .getElementById("TEH_LOGOTYPE")
      .addEventListener("mouseout", TEH_EVENT_HideTime, false)
  } else {
    document
      .getElementById("TEH_LOGOTYPE")
      .attachEvent("onmouseover", TEH_EVENT_Show)
    document
      .getElementById("TEH_LOGOTYPE")
      .attachEvent("onmouseout", TEH_EVENT_HideTime)
  }
}
