import React from "react"

const GenericRenderer: React.FunctionComponent<{
  component?: React.ComponentType
  [key: string]: any
}> = ({ children, component: Component, ...props }) => {
  if (!Component) {
    return <div {...props}>{children}</div>
  }

  return <Component {...props}>{children}</Component>
}

export { GenericRenderer }
