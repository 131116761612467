import React from "react"

import { PropertyInfo } from "../../types/cms/content"
import editor from "../utils/editorAttributes"
import { GenericRenderer } from "./utils"

export type XhtmlStringProperty = string

type Props = {
  content: XhtmlStringProperty
  propInfo?: PropertyInfo
  component?: React.ComponentType
  [key: string]: any
}

const XhtmlString: React.FunctionComponent<Props> = ({
  content,
  propInfo,
  component: Component,
  ...rest
}) => (
  <GenericRenderer
    {...editor(propInfo)}
    component={Component}
    {...rest}
    dangerouslySetInnerHTML={{
      __html: content ? content : propInfo ? "" : null
    }}
  />
)

export default XhtmlString
