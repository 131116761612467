import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Search: React.FunctionComponent<Props> = props => (
  <SvgIcon {...props} fill="currentColor" viewBox="0 0 22 22">
    <path d="M9 18A9 9 0 1 1 9 0a9 9 0 0 1 0 18ZM9 2a7 7 0 1 0 0 14A7 7 0 0 0 9 2Zm12.72 18.3-4.3-4.3c-.42.52-.9 1-1.42 1.43l4.3 4.29a1 1 0 0 0 1.42-1.43Z" />
  </SvgIcon>
)

export default Search
