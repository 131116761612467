import { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Flag: React.FunctionComponent<Props> = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <g fill="#f0f0f0">
      <path d="M0 85.3h512v341.3H0z" />
      <path d="M0 85.3h512V256H0z" />
    </g>
    <path fill="#d80027" d="M0 256h512v170.7H0z" />
  </svg>
)

export default Flag
