import Root from "common/Root"
import * as React from "react"
import * as ReactDOM from "react-dom"

const init = () => {
  if ("scrollRestoration" in window.history) {
    // Back off, browser, I got this...
    window.history.scrollRestoration = "manual"
  }
  ReactDOM.render(<Root />, document.getElementById("root") as HTMLElement)
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", init)
} else {
  init()
}
