import Divider from "@material-ui/core/Divider"
import List, { ListProps } from "@material-ui/core/List"
import ListItem, { ListItemProps } from "@material-ui/core/ListItem"
import ListItemText, { ListItemTextProps } from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import { MenuItem as CmsMenuItem } from "@starrepublic/epi/types/cms/menu"
import Typography from "common/Typography"
// Icons
import ArrowForward from "icons/ArrowForward"
import * as React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import MenuItem from "./MenuItem"

type Props = ListProps & {
  items: CmsMenuItem[]
  parentItem?: CmsMenuItem | null
  goToSubMenu: (item: CmsMenuItem) => void
  closeDrawer: (isOpen: boolean) => void
  listItemTextProps?: ListItemTextProps
  listItemProps?: ListItemProps
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>
}

const MenuList: React.FunctionComponent<Props> = ({
  items,
  goToSubMenu,
  parentItem,
  closeDrawer,
  listItemProps,
  listItemTextProps,

  ...rest
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return items && items.length ? (
    <div>
      {parentItem && (
        // TODO: Move out this component
        <>
          <Link to={parentItem.url} onClick={() => closeDrawer(false)}>
            <ListItem component="div" className={classes.subheader}>
              <ListItemText primary={parentItem.title} disableTypography />
              <Typography variant="link2">{t("common.show_all")}</Typography>
              <ArrowForward />
            </ListItem>
          </Link>
          <Divider />
        </>
      )}
      <List {...rest}>
        {items.map(item => (
          <MenuItem
            disableGutters
            key={item._id}
            item={item}
            goToSubMenu={goToSubMenu}
            closeDrawer={closeDrawer}
            button
            {...listItemProps}
          >
            <ListItemText
              primary={item.title}
              {...listItemTextProps}
              disableTypography
            />
          </MenuItem>
        ))}
      </List>
    </div>
  ) : null
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  subheader: {
    fontWeight: 700,
    color: palette.text.primary,
    paddingRight: spacing(3),
    paddingLeft: spacing(3),
    paddingTop: "18px",
    paddingBottom: "18px"
  }
}))

export default MenuList
