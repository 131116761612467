import { Market } from "@starrepublic/epi/api/markets"
import request from "@starrepublic/epi/api/request"

type MarketModel = {
  marketId: string
  language: string
  currency: string
}

const markets = {
  getAll: (includeInactive: boolean, excludeHostless: boolean) =>
    request.get<Market[]>(`/markets/${includeInactive}/${excludeHostless}`),
  updateMarket: (marketId: string, model: MarketModel) =>
    request.post(`/markets/${marketId}`, model),
  getCountries: (marketId: string) =>
    request.get(`/markets/countries/${marketId}`),
  getAlternativePage: async (
    currentLang: string,
    targetLang: string,
    identifier: string
  ) => {
    return await request.get(
      `contents/langroute/${currentLang}/${targetLang}${identifier}`
    )
  }
}

export default markets
