import React from "react"

import DesktopMarketsMenu from "./DesktopMarketsMenu"
import MobileMarketsMenu from "./MobileMarketsMenu"

type OwnProps = {
  mobile?: boolean
}

type Props = OwnProps

const MarketsMenu: React.FC<Props> = ({ mobile = false }) => {
  return mobile ? <MobileMarketsMenu /> : <DesktopMarketsMenu />
}

export default MarketsMenu
