import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = {
  filled?: boolean
} & SvgIconProps

const FacebookIcon: React.FunctionComponent<Props> = ({ filled, ...props }) => (
  <SvgIcon viewBox="0 0 20 22" {...props}>
    {filled ? (
      <>
        <path d="M6.2 13h7.48L20 22H0z" />
        <path
          fillRule="nonzero"
          d="M10 13.96a7 7 0 0 1-7-6.98 7 7 0 0 1 14 0 7 7 0 0 1-7 6.98Zm0-11.97a5 5 0 1 0 0 9.99 5 5 0 0 0 0-10Z"
        />
        <circle cx="10" cy="7" r="6" />
        <path
          fillRule="nonzero"
          d="M20 21.93h-2a8 8 0 0 0-16 0H0a10 10 0 0 1 20 0Z"
        />
      </>
    ) : (
      <>
        <path d="M10 13.96a7 7 0 0 1-7-6.98 7 7 0 0 1 14 0 7 7 0 0 1-7 6.98Zm0-11.97a5 5 0 1 0 0 9.99 5 5 0 0 0 0-10Z" />
        <path d="M20 21.93h-2a8 8 0 0 0-16 0H0a10 10 0 0 1 20 0Z" />
      </>
    )}
  </SvgIcon>
)

export default FacebookIcon
