import "i18n"
import "assets/css/style.css"

import CssBaseLine from "@material-ui/core/CssBaseline"
import { MuiThemeProvider } from "@material-ui/core/styles"
import { CmsProvider } from "@starrepublic/epi/cms/components/CmsContext"
import App from "common/App"
import GoogleTagManagerTracking from "common/GoogleTagManagerTracking"
import { BlockMapping, FormMapping, PageMapping } from "componentMapping"
import { createBrowserHistory } from "history"
import * as React from "react"
import { Router } from "react-router-dom"
import theme from "theme"

const browserHistory = createBrowserHistory({ basename: "" })

const Root: React.FunctionComponent = () => (
  <Router history={browserHistory}>
    <MuiThemeProvider theme={theme}>
      <CmsProvider
        blockMap={BlockMapping}
        pageMap={PageMapping}
        formMap={FormMapping}
      >
        <CssBaseLine />
        <App />
        <GoogleTagManagerTracking />
      </CmsProvider>
    </MuiThemeProvider>
  </Router>
)
export default Root
export { browserHistory }
