import { Market as ApiMarket, MarketModel } from "@starrepublic/epi/api/markets"
import api from "api"
import { asyncState, createStore } from "global-hook-store"
import i18nCountries from "i18n-iso-countries"
import i18next from "i18next"

import pageStore from "./pageStore"

// Importing English manually is required to use getName() from i18n-iso-countries
// eslint-disable-next-line @typescript-eslint/no-var-requires
const data = require(`i18n-iso-countries/langs/en.json`)
i18nCountries.registerLocale(data)

type Market = ApiMarket & {
  countries: string[]
}
export type Country = {
  name: string
  alpha2: string
  alpha3: string
}
type MarketsByCountryCode = Record<string, Market[]>

const marketStore = createStore(
  {
    markets: asyncState<Market[]>(),
    languages: [] as string[],
    countries: [] as Country[],
    defaultMarket: undefined as Market | undefined,
    changeMarketResponse: asyncState<Market>(),
    marketsByCountryCode: {} as MarketsByCountryCode,
    selectedCountry: "" as string
  },
  {
    fetchMarkets: async (state, _payload: null, { asyncAction }) => {
      const { markets } = await asyncAction(
        "markets",
        api.markets.getAll(false, false)
      )
      if (markets.error) {
        return { ...state, markets }
      }
      const languages =
        markets.data && markets.data.length ? markets.data[0].languages : []

      let defaultMarket: Market | undefined = markets.data?.find(
        m => m.isSelected
      )
      const marketsByCountryCode = {}

      let selectedCountry = ""
      const allCountries: Record<string, Country> = {}

      markets.data?.forEach(market => {
        if (market.isSelected) {
          defaultMarket = market
        }

        if (market.selectedCountry && market.isSelected) {
          selectedCountry =
            market.selectedCountry.length === 3
              ? i18nCountries.alpha3ToAlpha2(market.selectedCountry)
              : market.selectedCountry
        }

        market.countries.forEach(alpha3 => {
          const name = i18nCountries.getName(alpha3, "en")
          const alpha2 = i18nCountries.alpha3ToAlpha2(alpha3)
          if (name) {
            allCountries[alpha3] = {
              name,
              alpha2,
              alpha3
            }
          }
          if (marketsByCountryCode[alpha2]) {
            marketsByCountryCode[alpha2].push(market)
          } else {
            marketsByCountryCode[alpha2] = [market]
          }
        })
      })

      const sortedCountries = sortCountries(allCountries, selectedCountry)

      return {
        ...state,
        languages,
        markets,
        marketsByCountryCode,
        selectedCountry,
        countries: sortedCountries,
        defaultMarket
      }
    },

    changeMarket: async (state, model: MarketModel, { asyncAction }) => {
      await asyncAction(
        "changeMarketResponse",
        api.markets.updateMarket(model.marketId, model)
      )

      if (document.documentElement.lang !== model.language) {
        i18next.changeLanguage(model.language)
      }

      if (pageStore && pageStore.state.page.data) {
        const currentLang = document.documentElement.lang
        const targetLang = model.language

        const alternativeMarketPage = await api.markets.getAlternativePage(
          currentLang,
          targetLang,
          pageStore.state.page.data._route
        )
        window.location.href = alternativeMarketPage
      } else {
        window.location.href = `/${model.language}`
      }

      return state
    },

    changeLanguage: async (state, language: string) => {
      const model: MarketModel = {
        marketId: state.defaultMarket?.marketId.value || "",
        currency: state.defaultMarket?.defaultCurrency || "",
        language,
        country: state.defaultMarket?.selectedCountry || ""
      }
      await marketStore.actions.changeMarket(model)
      return state
    },

    changeCountry: async (state, countryData: string | [string, string]) => {
      let alpha2
      let lang

      // Country data can be just alpha2 string or [alpha2: string, lang: string] array
      if (typeof countryData === "string") {
        alpha2 = countryData
      } else {
        alpha2 = countryData[0]
        lang = countryData[1]
      }

      const market =
        (state.marketsByCountryCode[alpha2] &&
          state.marketsByCountryCode[alpha2][0]) ||
        null

      if (!market) {
        return state
      }

      const model: MarketModel = {
        marketId: market.marketId.value,
        currency: market.defaultCurrency,
        language: lang ? lang : market.defaultLanguage,
        country: alpha2
      }

      marketStore.actions.changeMarket(model)

      return state
    }
  }
)

function sortCountries(
  allCountries: Record<string, Country>,
  selectedCountry: string
) {
  let sortedCountries = Object.values(allCountries).sort((a, b) =>
    a.name.localeCompare(b.name)
  )

  const selectedCountryObject = sortedCountries.filter(
    country =>
      country.alpha2 === selectedCountry || country.alpha3 === selectedCountry
  )[0]

  sortedCountries = sortedCountries.filter(
    country =>
      country.alpha2 !== selectedCountry && country.alpha3 !== selectedCountry
  )

  if (selectedCountryObject) {
    sortedCountries.unshift(selectedCountryObject)
  }
  return sortedCountries
}

marketStore.actions.fetchMarkets()

export default marketStore
