import ButtonBase from "@material-ui/core/ButtonBase"
import { makeStyles } from "@material-ui/core/styles"
import { Hit } from "models/Search"
import React from "react"

import Image from "../Image"
import SearchResultText from "./SearchResultText"

type Props = {
  product: Hit
  onClick: () => void
}

const ProductResult: React.FC<Props> = ({ product, onClick }) => {
  let src: string | undefined
  let alt: string | undefined

  if (product.image) {
    src = product.image._url
    alt = product.image.alt
  }

  const classes = useStyles()

  return (
    <ButtonBase onClick={onClick} className={classes.button}>
      <Image
        containerProps={{ className: classes.imageContainer }}
        className={classes.image}
        src={src}
        alt={alt || product.title}
      />
      <SearchResultText secondary={product.title2}>
        {product.title}
      </SearchResultText>
    </ButtonBase>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    margin: `${spacing(0.5)}px 0`
  },
  imageContainer: {
    width: spacing(6),
    height: spacing(6),
    marginRight: spacing(6)
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%"
  },
  button: {
    display: "flex",
    width: "100%",
    justifyContent: "left"
  }
}))

export default ProductResult
