import api from "api"
import { asyncState, createStore } from "global-hook-store"
import Cart from "models/Cart"
import CheckoutResponse, { OrderConfirmationDetails } from "models/Checkout"

import cartStore, { CartType } from "./cartStore"

const CART_TYPE: CartType = "default"

const checkoutStore = createStore(
  {
    checkout: asyncState<CheckoutResponse>(),
    coupon: asyncState<string>(),
    showCoupon: false,
    updatingCoupon: false,
    orderConfirmationDetails: {} as OrderConfirmationDetails
  },
  {
    reset: (_state, _payload: null, { reset }) => reset(),

    setShowCoupon: (state, showCoupon: boolean) => ({
      ...state,
      showCoupon
    }),

    setOrderConfirmationDetails: (
      state,
      orderConfirmationDetails: OrderConfirmationDetails
    ) => ({
      ...state,
      orderConfirmationDetails
    }),

    fetchCheckout: async (state, _payload: null, { asyncAction }) =>
      asyncAction("checkout", api.checkout.getCheckout(CART_TYPE)),

    resetCouponCode: (_state, _payload: null, { reset }) => reset("coupon"),

    _handleCoupon: async (
      _state,
      {
        couponCode,
        api
      }: {
        couponCode: string
        api: (cartType: string, code: string) => Promise<Cart>
      },
      { asyncAction }
    ) => {
      let newCart: Cart | null = null
      let apiError = ``

      const newState = await asyncAction(
        "coupon" as any,
        api(CART_TYPE, couponCode)
          .then<string | null>(cart => {
            newCart = cart
            cartStore.actions.setCart({
              cartType: CART_TYPE,
              cart
            })
            return couponCode
          })
          .catch(err => {
            apiError = err
          })
      )

      if (newCart && newState.checkout.data) {
        newState.checkout.data.cart = newCart
      } else {
        newState.coupon.error = apiError
      }

      newState.updatingCoupon = false

      return newState
    },

    removeCouponCode: (state, couponCode: string) => {
      state.updatingCoupon = true
      checkoutStore.actions._handleCoupon({
        couponCode,
        api: api.checkout.deleteCouponCode
      })
      return state
    },

    addCouponCode: async (state, couponCode: string) => {
      state.updatingCoupon = true
      checkoutStore.actions._handleCoupon({
        couponCode,
        api: api.checkout.addCouponCode
      })
      return state
    }
  }
)

export default checkoutStore
