import ButtonBase from "@material-ui/core/ButtonBase"
import Drawer from "@material-ui/core/Drawer"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import Typography from "common/Typography"
import useStore from "global-hook-store"
import MarketFlag from "icons/flags"
import React, { useState } from "react"
import marketStore from "stores/marketStore"
import { langCodeToName } from "utils/language"

import MarketsMenuContent from "./Content"

const DesktopMarketsMenu: React.FC = () => {
  const {
    state: { defaultMarket }
  } = useStore(marketStore)

  const classes = useStyles()

  const [open, setOpen] = useState(false)

  return (
    <>
      <ButtonBase className={classes.root} onClick={() => setOpen(true)}>
        {defaultMarket && (
          <>
            <MarketFlag
              className={classes.defaultMarketFlag}
              countryCode={defaultMarket.selectedCountry}
            />

            <Typography variant="currency" className={classes.marketLabel}>
              {defaultMarket.selectedCountry}
            </Typography>

            <Typography variant="currency" className={classes.marketLabel}>
              {langCodeToName(defaultMarket.selectedLanguage)}
            </Typography>

            <Typography variant="currency" className={classes.marketLabel}>
              {defaultMarket.selectedCurrency}
            </Typography>

            {open ? (
              <KeyboardArrowUp color="inherit" />
            ) : (
              <KeyboardArrowDown color="inherit" />
            )}
          </>
        )}
      </ButtonBase>

      <Drawer open={open} onClose={() => setOpen(false)} anchor="bottom">
        <Paper className={classes.marketMenu}>
          <MarketsMenuContent onClose={() => setOpen(false)} />
        </Paper>
      </Drawer>
    </>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    fontWeight: "bold",
    borderRight: "1px solid #EEEEEE",
    height: "100%",
    width: "100%",
    justifyContent: "flex-start",
    flex: "1 0 0",
    paddingRight: spacing(1),
    paddingLeft: spacing(2),
    "& svg": {
      marginLeft: spacing(1)
    }
  },
  menuWrapper: {
    width: "100%"
  },
  marketMenu: {
    width: "100%"
  },
  defaultMarketFlag: {
    width: spacing(3),
    marginRight: spacing(1)
  },
  marketLabel: {
    fontWeight: 700,
    marginTop: "1px",
    textTransform: "uppercase",
    "&:after": {
      content: "'|'",
      fontWeight: "normal",
      marginLeft: spacing(1),
      marginRight: spacing(1)
    },
    "&:last-of-type:after": {
      content: "''",
      marginLeft: 0,
      marginRight: 0
    }
  }
}))

export default DesktopMarketsMenu
