import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import XhtmlString from "@starrepublic/epi/cms/components/XhtmlString"
import { footerBackground } from "common/backgrounds"
import NewsLetter from "common/Footer/NewsLetter"
import NewsletterDialog from "common/NewsletterDialog"
import Wrapper from "common/Wrapper"
import useStore from "global-hook-store"
import React from "react"
import { Link } from "react-router-dom"
import authStore from "stores/authStore"
import marketStore from "stores/marketStore"
import pageStore from "stores/pageStore"
import siteSettingsStore from "stores/siteSettingsStore"

import MarketFlag from "../../icons/flags"
import AffiliateImages from "./AffiliateImages"
import FooterMenu from "./FooterMenu"
import SocialMedia from "./SocialMediaIcons"

const Footer: React.FunctionComponent = () => {
  const {
    state: { footerContent, menus, newsletter }
  } = useStore(siteSettingsStore)

  const {
    state: { languages }
  } = useStore(marketStore)

  const {
    state: { page }
  } = useStore(pageStore)

  const {
    state: { isLoggedIn }
  } = useStore(authStore)

  const classes = useStyles()

  if (!page.data || page.data._type === "CheckoutPage") {
    return null
  }

  return (
    <footer>
      <div className={classes.constantTopPadding} />
      <Wrapper containerClassName={classes.container}>
        <Grid container justifyContent="center" spacing={8}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              className={classes.footerTitle}
              color="inherit"
            >
              {(footerContent && footerContent.footerHeadline) || ""}
            </Typography>

            <Typography
              variant="body1"
              color="inherit"
              component="div"
              className={classes.footerBody}
            >
              <XhtmlString
                content={(footerContent && footerContent.content) || ""}
              />
            </Typography>
            <Grid item className={classes.menuItemsContainer}>
              {menus.footer && <FooterMenu items={menus.footer} />}
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            {!isLoggedIn && (
              <Grid item className={classes.newsLetterContainer}>
                <NewsLetter
                  footerNewsLetterText={
                    (footerContent && footerContent.footerNewsLetterText) || ""
                  }
                  footerNewsLetterHeadline={
                    (footerContent && footerContent.footerNewsLetterHeadline) ||
                    ""
                  }
                />
              </Grid>
            )}

            <Typography
              variant="h6"
              color="inherit"
              className={classes.socialTitle}
            >
              {(footerContent && footerContent.socialLinksHeadline) || ""}
            </Typography>
            <Grid
              item
              className={isLoggedIn ? classes.socialMediaContainer : ""}
            >
              <SocialMedia
                socialMedias={
                  (footerContent && footerContent.socialLinks) || []
                }
                iconWidth="24"
                iconHeight="24"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item xs={12} className={classes.affiliateImageContainer}>
            <AffiliateImages
              images={(footerContent && footerContent.affiliateImages) || []}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.copy}>
            <Typography component="div" color="inherit">
              <XhtmlString
                content={(footerContent && footerContent.copyrightNotice) || ""}
              />
            </Typography>
          </Grid>
          {languages && (
            <Grid item xs={12} md={6} className={classes.regions}>
              <Typography color="inherit">
                {languages.map((language, index) => (
                  <Link to={`/${language}/`} key={index}>
                    <MarketFlag
                      countryCode={language}
                      className={classes.region}
                    />
                  </Link>
                ))}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Wrapper>

      {newsletter.enabled && <NewsletterDialog />}
    </footer>
  )
}

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  "@global": {
    footer: {
      backgroundColor: "#000000",
      backgroundImage: footerBackground
    }
  },
  space: {
    width: "100%",
    height: "60px",
    backgroundColor: "#ffffff"
  },
  container: {
    textAlign: "left",
    color: palette.primary.contrastText,
    padding: `${spacing(3)}px 0`,
    [breakpoints.up("md")]: {
      padding: `${spacing(6)}px 0`
    }
  },
  footerTitle: {
    marginBottom: spacing(3)
  },
  footerBody: {
    marginBottom: spacing(3)
  },
  newsLetterContainer: {
    marginBottom: spacing(1)
  },
  affiliateImageContainer: {
    backgroundColor: "#000000",
    border: "2px solid #191919",
    marginBottom: `${spacing(5)}px`,
    padding: spacing(2),
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: spacing(4)
  },
  copy: {
    textAlign: "center",
    [breakpoints.up("md")]: {
      textAlign: "inherit"
    }
  },
  regions: {
    textAlign: "center",
    [breakpoints.up("md")]: {
      textAlign: "right"
    }
  },
  region: {
    width: "15px",
    marginRight: "3px"
  },
  menuItemsContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    paddingBottom: spacing(1)
  },
  socialMediaContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    "& img": {
      border: `${spacing(0.25)}px solid ${palette.primary.contrastText}`,
      padding: spacing(2),
      marginRight: spacing(2)
    }
  },
  socialTitle: {
    paddingBottom: spacing(2)
  },
  constantTopPadding: {
    width: "100%",
    height: "60px",
    backgroundColor: "#ffffff"
  }
}))

export default Footer
