import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import React from "react"

type Size = "xs" | "sm" | "md"

type Props = {
  size?: Size
  className?: string
}

const Spinner = ({ size = "md", className }: Props) => {
  const classes = useStyles()

  return (
    <div
      className={classNames(classes.root, className, {
        [classes.xs]: size === "xs",
        [classes.sm]: size === "sm"
      })}
    />
  )
}

const useStyles = makeStyles(() => ({
  root: {
    width: 46,
    height: 46,
    border: "5px solid #ccc",
    borderBottomColor: "#333",
    borderRadius: "50%",
    display: "inline-block",
    boxSizing: "border-box",
    animation: "rotation 1s linear infinite"
  },
  xs: {
    width: 16,
    height: 16,
    borderWidth: 2
  },
  sm: {
    width: 24,
    height: 24,
    borderWidth: 2
  }
}))

export default Spinner
