import { PageInfo } from "@starrepublic/epi/types/cms/content"
import { createStore } from "global-hook-store"
import { ColorLengthVariation, OptionsVariation } from "models/Products"
import Variant from "models/Variant"
import { WebProductContent } from "pages/WebVariant/types"

type VariantMap = Record<string, string>

type VariantAssociation = Record<
  string,
  Record<string, Record<string, Set<any>>>
>

export type ProductPage = {
  content?: WebProductContent
  pageInfo?: PageInfo
  propsInfo?: any
}

const productStore = createStore(
  {
    productPage: {} as ProductPage,
    variantMap: {} as VariantMap,
    onSalesMap: {} as Record<string, Record<string, boolean>>,
    variantAssociations: {} as VariantAssociation,
    variantModels: {} as Record<string, Variant>,
    selectedVariant: {} as Variant
  },
  {
    setProductPage: (state, productPage: ProductPage) => {
      const variantAssociations = {}

      const onSalesMap = Object.values(
        productPage.content?.variations || {}
      ).reduce((acc, value) => {
        if ((value as OptionsVariation).options) {
          // We do not handle onSale for option based variations
          return acc
        }

        // If it's not option based, it's color/length based
        const variant = value as ColorLengthVariation

        if (!acc[variant.color]) {
          acc[variant.color] = {}
        }

        acc[variant.color][variant.length] = variant.onSale
        return acc
      }, {})

      const variantMap: VariantMap = Object.entries(
        productPage.content?.variations || {}
      ).reduce((acc, [key, value]) => {
        let newKey = ""

        const valueEntities = Object.entries(value)

        valueEntities
          .filter(item => item[0] !== "onSale")
          .forEach(([property, propertyValue], i, a) => {
            newKey += `${propertyValue}${(i !== a.length - 1 && "_") || ""}`

            if (!variantAssociations[property]) {
              variantAssociations[property] = {}
            }

            if (!variantAssociations[property][propertyValue]) {
              variantAssociations[property][propertyValue] = {}
            }

            valueEntities.forEach(([p, v]) => {
              if (p === "onSale") {
                // Special handling in onSalesMap above
                return
              }

              if (p !== property) {
                if (!variantAssociations[property][propertyValue][p]) {
                  variantAssociations[property][propertyValue][p] = new Set()
                }

                variantAssociations[property][propertyValue][p].add(v)
              }
            })
          })

        if (newKey) {
          acc[newKey] = key
        }

        return acc
      }, {})

      return {
        ...state,
        variantMap,
        onSalesMap,
        productPage,
        variantAssociations
      }
    },

    setVariantModels: (state, variantModels: Record<string, Variant>) => ({
      ...state,
      variantModels
    }),

    setSelectedVariant: (state, selectedVariant: Variant) => ({
      ...state,
      selectedVariant
    })
  }
)

export default productStore
