import { SvgIconProps } from "@material-ui/core/SvgIcon"
import useStore from "global-hook-store"
import React from "react"
import authStore from "stores/authStore"

type OwnProps = {
  basketHasItems: boolean
  mobile?: boolean
  collapsed?: boolean
}

type Props = OwnProps & SvgIconProps

const ShoppingBasket: React.FunctionComponent<Props> = ({
  basketHasItems = false,
  mobile = false,
  collapsed = false,
  ...props
}) => {
  const {
    state: { isLoggedIn }
  } = useStore(authStore)

  const getStrokeColor = () => {
    if ((!mobile && isLoggedIn) || (mobile && !isLoggedIn) || collapsed) {
      return "#292929"
    }
    return "#fff"
  }

  const getFillColor = () => {
    if (isLoggedIn && !basketHasItems && collapsed) {
      return "#fff"
    }
    if (
      (!isLoggedIn && !mobile && !basketHasItems && !collapsed) ||
      (isLoggedIn && mobile && !basketHasItems) ||
      (isLoggedIn && !mobile && basketHasItems) ||
      (!isLoggedIn && mobile && basketHasItems) ||
      (basketHasItems && collapsed)
    ) {
      return "#292929"
    }
    return "#fff"
  }

  return (
    <svg {...props} viewBox="0 0 20 24">
      <g
        fill="none"
        fillRule="evenodd"
        stroke={getStrokeColor()}
        strokeWidth="2"
      >
        <path
          fill={getFillColor()}
          fillRule={"inherit"}
          d="M19 23V6H1v17h18Z"
        />
        <path strokeLinecap="square" d="M6 9V5a4 4 0 0 1 4-4 4 4 0 0 1 4 4v4" />
      </g>
    </svg>
  )
}

export default ShoppingBasket
