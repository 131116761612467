import Dialog, { DialogProps } from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import { makeStyles } from "@material-ui/core/styles"
import React, { useEffect } from "react"

type OwnProps = {
  open: boolean
  backgroundColor?: string
  color?: string
}

type Props = DialogProps & OwnProps

const MarketSelector: React.FunctionComponent<Props> = ({
  open = false,
  backgroundColor = "#fff",
  color = "#000",
  children,
  ...restProps
}) => {
  const classes = useStyles()

  const cssVariables = {
    outline: 0,
    "--backgroundColor": backgroundColor,
    "--color": color
  }

  useEffect(() => {
    // Prevent scroll bars
    if (open) {
      document.body.style.maxHeight = "100vh"
    } else {
      document.body.style.maxHeight = "none"
    }
  }, [open])

  return (
    <Dialog
      fullScreen={true}
      PaperProps={{ className: classes.dialogPaper, style: cssVariables }}
      open={open}
      className={classes.root}
      {...restProps}
    >
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    zIndex: 9999
  },
  dialogPaper: {
    background: "var(--backgroundColor)",
    color: "var(--color)",
    padding: `${spacing(4)}px ${spacing(2)}px`,

    "& p": {
      color: "inherit",

      [breakpoints.up("sm")]: {
        fontSize: 24
      }
    },

    "& a, & span": {
      color: "inherit"
    },

    [breakpoints.up("sm")]: {
      padding: spacing(6)
    }
  },
  dialogBody: {
    [breakpoints.up("sm")]: {
      width: "80%"
    }
  },
  dialogContent: {
    overflowX: "hidden",
    padding: "0 !important"
  }
}))

export default MarketSelector
