import { makeStyles } from "@material-ui/core/styles"
import Logotype from "common/Logotype"
import Typography from "common/Typography"
import useStore from "global-hook-store"
import React, { useEffect, useState } from "react"
import marketStore from "stores/marketStore"
import theme from "theme"

import FullScreenDialog from "../FullScreenDialog"
import MarketSelectorItems from "./FullScreenMarketSelectorItems"

export const selectorClosedLocalStorageKey = "FullScreenMarketSelectorClosed"

const MarketSelector: React.FunctionComponent = () => {
  const classes = useStyles()

  const {
    state: { countries },
    actions: { changeCountry }
  } = useStore(marketStore)

  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    // Open modal only if the user has not closed this modal before
    const selectorClosed = window.localStorage.getItem(
      selectorClosedLocalStorageKey
    )

    const localMarketInaccessible = !!window.LOCALMARKETINACCESSIBLE

    setDialogOpen(localMarketInaccessible && !selectorClosed)
  }, [])

  return (
    <FullScreenDialog
      open={dialogOpen}
      backgroundColor={theme.palette.rapunzel.pinkLight}
      color={theme.palette.common.white}
      transitionDuration={0}
    >
      <div className={classes.logoContainer}>
        <Logotype />
      </div>

      <Typography
        component="span"
        variant="headline1"
        className={classes.heading}
      >
        We no longer ship to {window.DETECTEDCOUNTRYNAME}
      </Typography>

      <div className={classes.body}>
        <Typography paragraph>
          Rapunzel of Sweden no longer delivers to this market. This means
          you’re no longer able to order via our online store.
        </Typography>

        <Typography paragraph>
          If you want to visit our site anyway, you can choose a valid market
          below:
        </Typography>

        <div className={classes.marketSelector}>
          <MarketSelectorItems
            countries={countries}
            changeCountry={changeCountry}
          />
        </div>

        <Typography paragraph>
          For all questions, contact our Customer Service at{" "}
          <a href="mailto:info@rapunzel.com">info@rapunzel.com</a>
        </Typography>
      </div>
    </FullScreenDialog>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  logoContainer: {
    color: palette.rapunzel.white,
    width: 115,
    [breakpoints.up("sm")]: {
      width: 180
    }
  },
  heading: {
    fontSize: 35,
    lineHeight: "40px",
    marginTop: spacing(3),
    marginBottom: spacing(2),
    color: palette.primary.contrastText,

    [breakpoints.up("md")]: {
      width: "40%",
      fontSize: 60,
      lineHeight: "70px",
      marginTop: spacing(6),
      marginBottom: spacing(4)
    }
  },
  body: {
    [breakpoints.up("lg")]: {
      maxWidth: 1200
    }
  },
  marketSelector: {
    marginBottom: spacing(4),

    [breakpoints.up("lg")]: {
      marginBottom: spacing(6)
    }
  }
}))

export default MarketSelector
