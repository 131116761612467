import Collapse from "@material-ui/core/Collapse"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import DarkButton from "common/DarkButton"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import useStore from "global-hook-store"
import React from "react"
import { useTranslation } from "react-i18next"
import marketStore from "stores/marketStore"
import { pxToRem } from "theme/typography"

import { langCodeToEnglishName } from "../../utils/language"

type Props = {
  show: boolean
  triggerHide: () => void
}

const InactiveLanguageInfo: React.FC<Props> = ({ show, triggerHide }) => {
  const {
    state: { defaultMarket },
    actions: { changeLanguage }
  } = useStore(marketStore)

  const classes = useStyles()
  const { t } = useTranslation()

  const redirectToEnglishSite = () => {
    changeLanguage("en")
  }

  return (
    <Collapse in={show} unmountOnExit>
      <div className={classes.root}>
        <Wrapper
          className={classes.wrapper}
          containerClassName={classes.container}
        >
          <div className={classes.headingContainer}>
            <Typography variant="display4" className={classes.heading}>
              {t("header.inactive_$$language$$_heading", {
                language:
                  defaultMarket &&
                  langCodeToEnglishName(defaultMarket.defaultLanguage)
              })}
            </Typography>
          </div>

          <Typography>
            {t("header.inactive_$$language$$_message", {
              language: defaultMarket && defaultMarket.marketName
            })}
          </Typography>

          <div className={classes.buttonContainer}>
            <DarkButton onClick={redirectToEnglishSite}>
              {t("header.inactive_cta_label")}
            </DarkButton>
          </div>
        </Wrapper>

        <IconButton onClick={triggerHide} className={classes.closeIcon}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
    </Collapse>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  root: {
    position: "relative"
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    backgroundColor: palette.rapunzel.lightGray,
    [breakpoints.up("md")]: {
      backgroundColor: "transparent"
    }
  },
  container: {
    margin: `${spacing(3)}px 0`,
    [breakpoints.up("sm")]: {
      maxWidth: 500
    }
  },
  headingContainer: {
    [breakpoints.down("sm")]: {
      maxWidth: "90%"
    }
  },
  heading: {
    fontSize: pxToRem(24),
    marginBottom: "0.5em",
    textTransform: "none"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: spacing(2)
  },
  closeIcon: {
    position: "absolute",
    top: spacing(1),
    right: spacing(6),
    fontSize: 28,
    color: "#000",
    [breakpoints.down("sm")]: {
      right: spacing(1)
    }
  }
}))

export default InactiveLanguageInfo
