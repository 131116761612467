import { makeStyles } from "@material-ui/core/styles"
import Typography from "common/Typography"
import React from "react"

import MarketsMenu from "../../MarketsMenu"

const MenuFooter: React.FunctionComponent = () => {
  const classes = useStyles()

  return (
    <Typography className={classes.root} component="div">
      <MarketsMenu mobile />
    </Typography>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    height: spacing(8),
    width: "100%",
    display: "flex",
    alignItems: "stretch"
  },
  marketButton: {
    fontWeight: "bold",
    paddingRight: spacing(3),
    borderRight: "1px solid #EEEEEE",
    "& span": {
      marginLeft: spacing(2)
    }
  },
  certificationLink: {
    display: "flex",
    marginLeft: spacing(3),
    justifyContent: "center",
    alignItems: "center"
  }
}))

export default MenuFooter
