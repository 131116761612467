import ButtonBase from "@material-ui/core/ButtonBase"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import classNames from "classnames"
import Typography from "common/Typography"
import useStore from "global-hook-store"
import MarketFlag from "icons/flags"
import React, { useState } from "react"
import authStore from "stores/authStore"
import marketStore from "stores/marketStore"
import { langCodeToName } from "utils/language"

import MarketsMenuContent from "./Content"

const DesktopMarketsMenu: React.FC = () => {
  const {
    state: { defaultMarket }
  } = useStore(marketStore)

  const classes = useStyles()

  const {
    state: { isLoggedIn }
  } = useStore(authStore)

  const [open, setOpen] = useState(false)
  const listRef = React.useRef<HTMLButtonElement>(null)

  const handleClickAway = (e: any) => {
    const triggerButton = e.target.closest("#market-menu-trigger")
    if (triggerButton) {
      return
    }
    setOpen(false)
  }

  return (
    <>
      <li>
        <ButtonBase
          ref={listRef}
          id="market-menu-trigger"
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          className={classNames(classes.root, {
            [classes.open]: open,
            [classes.openLoggedIn]: isLoggedIn && open
          })}
          onClick={() => setOpen(!open)}
        >
          {defaultMarket && (
            <>
              <MarketFlag
                className={classes.defaultMarketFlag}
                countryCode={defaultMarket.selectedCountry}
              />

              <Typography
                variant="currency"
                className={classNames(classes.marketLabel, {
                  [classes.marketMenuLoggedIn]: isLoggedIn
                })}
              >
                {defaultMarket.selectedCountry}
              </Typography>

              <Typography
                variant="currency"
                className={classNames(classes.marketLabel, {
                  [classes.marketMenuLoggedIn]: isLoggedIn
                })}
              >
                {langCodeToName(defaultMarket.selectedLanguage)}
              </Typography>

              <Typography
                variant="currency"
                className={classNames(classes.marketLabel, {
                  [classes.marketMenuLoggedIn]: isLoggedIn
                })}
              >
                {defaultMarket.selectedCurrency}
              </Typography>

              {open ? (
                <KeyboardArrowUp
                  className={classNames(classes.arrowIcon, {
                    [classes.arrowIconLoggedIn]: isLoggedIn
                  })}
                />
              ) : (
                <KeyboardArrowDown
                  className={classNames(classes.arrowIcon, {
                    [classes.arrowIconLoggedIn]: isLoggedIn
                  })}
                />
              )}
            </>
          )}
        </ButtonBase>
      </li>

      <Popper
        open={open}
        anchorEl={listRef.current}
        transition
        disablePortal
        className={classes.popper}
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "center top"
            }}
            timeout={100}
          >
            <Paper className={classes.marketMenu}>
              <ClickAwayListener
                onClickAway={handleClickAway}
                mouseEvent="onMouseUp"
              >
                <div>
                  <MarketsMenuContent onClose={() => setOpen(false)} />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    fontWeight: "bold",
    padding: `8px ${spacing(2)} 8px 0px`,
    paddingTop: 8,
    paddingBottom: 8,
    height: "100%",
    "& svg": {
      marginLeft: spacing(1)
    }
  },
  open: {
    background: palette.background.default
  },
  openLoggedIn: {
    background: "#444"
  },
  popper: {
    zIndex: 9999,
    "& > div": {
      width: 375
    }
  },
  marketMenu: {
    width: spacing(22),
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)"
  },
  marketMenuLoggedIn: {
    color: palette.rapunzel.white
  },
  defaultMarketFlag: {
    width: spacing(3),
    marginRight: spacing(1)
  },
  marketLabel: {
    fontWeight: 700,
    marginTop: "1px",
    textTransform: "uppercase",
    "&:after": {
      content: "'|'",
      fontWeight: "normal",
      marginLeft: spacing(1),
      marginRight: spacing(1)
    },
    "&:last-of-type:after": {
      content: "''",
      marginLeft: 0,
      marginRight: 0
    }
  },
  arrowIcon: {
    color: "inherit"
  },
  arrowIconLoggedIn: {
    color: "#fff"
  }
}))

export default DesktopMarketsMenu
