import List from "@material-ui/core/List"
import { makeStyles } from "@material-ui/core/styles"
import useStore from "global-hook-store"
import * as React from "react"
import cartStore from "stores/cartStore"

import CartItem from "./CartItem"

const CartItemList: React.FC = () => {
  const {
    state: {
      carts: { data: carts }
    }
  } = useStore(cartStore)

  const classes = useStyles()
  const basket = carts.default

  return (
    <>
      <List className={classes.root}>
        {basket?.lineItems?.map(item => (
          <CartItem key={item.code} item={item} />
        ))}
      </List>
    </>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    minHeight: spacing(4),
    paddingTop: 0,
    marginBottom: "auto",
    paddingBottom: 0
  }
}))

export default CartItemList
