import React, { lazy } from "react"

const WebProduct = lazy(
  () => import(/* webpackChunkName: 'webProduct' */ "pages/WebProduct")
)
const WebVariant = lazy(
  () => import(/* webpackChunkName: 'WebVariant' */ "pages/WebVariant")
)
const WebBundle = lazy(
  () => import(/* webpackChunkName: 'WebBundle' */ "pages/WebBundle")
)
const WebNode = lazy(
  () => import(/* webpackChunkName: 'WebNode' */ "pages/WebNode")
)
const StandardPage = lazy(
  () => import(/* webpackChunkName: 'standardPage' */ "pages/Standard")
)
const StartPage = lazy(
  () => import(/* webpackChunkName: 'startPage' */ "pages/Start")
)
const AllStoresPage = lazy(
  () => import(/* webpackChunkName: 'allStoresPage' */ "pages/AllStores")
)
const StorePage = lazy(
  () => import(/* webpackChunkName: 'storePage' */ "pages/Store")
)
const CheckoutPage = lazy(
  () => import(/* webpackChunkName: 'checkoutPage' */ "pages/Checkout")
)
const ArticlePage = lazy(
  () => import(/* webpackChunkName: 'articlePage' */ "pages/Article")
)
const LoginPage = lazy(
  () => import(/* webpackChunkName: 'loginPage' */ "pages/Login")
)
const ErrorPage = lazy(
  () => import(/* webpackChunkName: 'errorPage' */ "pages/Error")
)
const OrderConfirmationPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'orderConfirmationPage' */ "pages/OrderConfirmation"
    )
)

const PrivacyPolicyPage = lazy(
  () =>
    import(/* webpackChunkName: 'privacyPolicyPage' */ "pages/PrivacyPolicy")
)

const MyProfilePage = lazy(
  () => import(/* webpackChunkName: 'myProfilePage' */ "pages/MyProfile")
)
const OrderHistoryPage = lazy(
  () =>
    import(/* webpackChunkName: 'orderHistoryPage' */ "pages/OrderHistoryPage")
)
const StandardB2BPage = lazy(
  () =>
    import(/* webpackChunkName: 'standardB2BPage' */ "pages/StandardB2BPage")
)
const OrderDetailsPage = lazy(
  () =>
    import(/* webpackChunkName: 'orderDetailsPage' */ "pages/OrderDetailsPage")
)
const EditCustomerInfoPage = lazy(
  () =>
    import(
      /* webpackChunkName: 'editCustomerInfoPage' */ "pages/EditCustomerInfoPage"
    )
)

import { BlockProps, ContentProps } from "@starrepublic/epi/types/cms/props"

// Content Blocks

const AccordionBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/AccordionBlock")
)
const ArticleBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/ArticleBlock")
)
const CallToActionBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/CallToActionBlock")
)
const CampaignBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/CampaignBlock")
)
const CategoryBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/CategoryBlock")
)
const CevoidBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/CevoidBlock")
)
const CheckoutTextBlock = React.lazy(
  () =>
    import(/* webpackChunkName: 'checkoutBlocks' */ "blocks/CheckoutTextBlock")
)
const ChildContentBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/ChildContentBlock")
)
const ColorConverterBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/ColorConverterBlock")
)
const ColorMapBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/ColorMapBlock")
)
const ContainerBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/ContainerBlock")
)

const HeroBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/HeroBlock")
)
const ImageSliderBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/ImageSliderBlock")
)
const LinksListBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/LinksListBlock")
)

const MediaBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/MediaBlock")
)
const PopularCategoriesBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/PopularCategoriesBlock")
)
const ProductListingBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/ProductListingBlock")
)
const ProductsCarousel = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/ProductsCarousel")
)
const StandardHeaderBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/StandardHeaderBlock")
)
const StandardModuleBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/StandardModuleBlock")
)
const UnsubscribeBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/UnsubscribeBlock")
)
const VideoMediaBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/VideoFileBlock")
)
const RichTextBlock = React.lazy(
  () => import(/* webpackChunkName: 'blocks' */ "blocks/RichTextBlock")
)
// Forms
const ChoiceElementBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'formBlocks' */ "blocks/FormContainerBlock/ChoiceElementBlock"
    )
)
const FileUploadElementBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'formBlocks' */ "blocks/FormContainerBlock/FileUploadElementBlock"
    )
)
const RecaptchaElementBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'formBlocks' */ "blocks/FormContainerBlock/RecaptchaElementBlock"
    )
)
const SelectionElementBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'formBlocks' */ "blocks/FormContainerBlock/SelectionElementBlock"
    )
)
const MarketSelectionBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'formBlocks' */ "blocks/FormContainerBlock/MarketSelectionBlock"
    )
)
const SubmitButtonElementBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'formBlocks' */ "blocks/FormContainerBlock/SubmitButtonElementBlock"
    )
)
const TextareaElementBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'formBlocks' */ "blocks/FormContainerBlock/TextareaElementBlock"
    )
)
const TextboxElementBlock = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'formBlocks' */ "blocks/FormContainerBlock/TextboxElementBlock"
    )
)

const FormContainerBlock = React.lazy(
  () => import(/* webpackChunkName: 'formBlocks' */ "blocks/FormContainerBlock")
)

const ImageFileBlock = React.lazy(
  () => import(/* webpackChunkName: 'formBlocks' */ "blocks/ImageFileBlock")
)

// Klarna
const KlarnaOSMBlock = React.lazy(
  () => import(/* webpackChunkName: 'klarnaBlocks' */ "blocks/KlarnaOSMBlock")
)

export const PageMapping: {
  [key: string]: React.ComponentType<ContentProps>
} = {
  ArticlePage,
  StandardPage,
  StartPage,
  WebProduct,
  AllStoresPage,
  StorePage,
  WebNode,
  AllProductsPage: WebNode,
  CheckoutPage,
  WebVariant,
  WebBundle,
  LoginPage,
  RapunzelErrorPage: ErrorPage,
  PasswordResetPage: LoginPage,
  OrderConfirmationPage,
  PrivacyPolicyPage,
  CultureSpecificArticlePage: ArticlePage,
  SelectedProductsPage: WebNode,
  MyProfilePage,
  OrderHistoryPage,
  OrderDetailsPage,
  StandardB2BPage,
  EditCustomerInfoPage
}

export const BlockMapping: {
  [key: string]: React.ComponentType<BlockProps>
} = {
  HeroBlock,
  MediaBlock,
  CallToActionBlock,
  ProductsCarousel,
  AccordionBlock,
  ChildContentBlock,
  ArticleBlock,
  ProductListingBlock,
  CategoryBlock,
  CheckoutTextBlock,
  LinksListBlock,
  ColorMapBlock,
  RichTextBlock,
  ColorConverterBlock,
  UnsubscribeBlock,
  ImageSliderBlock,
  ContainerBlock,
  CategoryCarousel: ProductsCarousel,
  ImageFile: ImageFileBlock,
  VideoMedia: VideoMediaBlock,
  KlarnaOSMBlock,
  StandardModuleBlock,
  StandardHeaderBlock,
  PopularCategoriesBlock,
  CampaignBlock,
  CevoidBlock
}

export const FormMapping: {
  [key: string]: React.ComponentType<BlockProps>
} = {
  FormContainerBlock,
  TextareaElementBlock,
  TextboxElementBlock,
  SelectionElementBlock,
  MarketSelectionBlock,
  ChoiceElementBlock,
  NumberElementBlock: TextboxElementBlock,
  SubmitButtonElementBlock,
  RecaptchaElementBlock,
  FileUploadElementBlock
}
