import { makeStyles } from "@material-ui/core/styles"
import { Footer, Header } from "common"
import PageSpinner from "common/Spinners/PageSpinner"
import PageContainer from "pages/PageContainer"
import React, { Suspense } from "react"
import { Route, Switch, withRouter } from "react-router-dom"

const App = () => {
  const classes = useStyles()

  return (
    <Suspense fallback={<PageSpinner fetching />}>
      <div className={classes.root}>
        <Header />

        <main className={classes.main}>
          <Suspense fallback={<PageSpinner fetching />}>
            <Switch>
              <Route exact path="*" component={PageContainer} />
            </Switch>
          </Suspense>
        </main>

        <Footer />
      </div>
    </Suspense>
  )
}

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  "@global": {
    a: {
      textDecoration: "none"
    },
    "*, *::after, *::before": {
      "-webkit-user-select": "text",
      "-webkit-user-drag": "none",
      "-webkit-app-region": "no-drag"
    }
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflow: "hidden"
  },
  main: {
    flexGrow: 1,
    position: "relative",
    background: palette.background.default,
    minHeight: "calc(100vh - 224px)",
    marginTop: 72,
    [breakpoints.down("md")]: {
      minHeight: "calc(100vh - 136px)",
      marginTop: -6
    }
  }
}))

export default withRouter(App)
