import Button, { ButtonProps } from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { pxToRem } from "theme/typography"

type Props = ButtonProps

const DarkButton: React.FunctionComponent<Props> = ({
  children,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <Button className={classes.root} {...restProps}>
      {children}
    </Button>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    background: palette.rapunzel.dark,
    paddingRight: spacing(1),
    paddingLeft: spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: palette.rapunzel.white,
    alignContent: "center",
    textTransform: "none",
    fontSize: pxToRem(18),
    letterSpacing: 0,
    padding: `${spacing(2)}px ${spacing(4)}px`,
    "&:hover, &:focus": {
      background: palette.rapunzel.dark
    }
  }
}))

export default DarkButton
