import { makeStyles } from "@material-ui/core/styles"
import Image from "common/Image"
import * as React from "react"
import { ImageBlock } from "types/content/media"

type Props = {
  images: ImageBlock[]
}

const AffiliateImages: React.FunctionComponent<Props> = ({ images }) => {
  const classes = useStyles()

  return (
    <>
      {images.map(img => {
        return (
          <Image
            lazy
            key={img._url}
            src={img._url}
            alt={img.alt}
            containerProps={{ className: classes.root }}
          />
        )
      })}
    </>
  )
}

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    height: 40,
    width: "auto",
    margin: 5,
    [breakpoints.down("sm")]: {
      height: 33
    }
  }
}))

export default AffiliateImages
