import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import * as React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"

import CheckoutHeader from "./CheckoutHeader"
import HeaderComponent from "./Header"

type Props = { newSearchDesign?: boolean } & RouteComponentProps

const Header: React.FunctionComponent<Props> = ({
  newSearchDesign = false
}) => {
  const classes = useStyles()

  const isCheckoutPage = location.pathname.includes("checkout")
  return (
    <div
      className={classNames(classes.root, {
        [classes.checkoutHeader]: isCheckoutPage,
        [classes.rootNewSearchDesign]: newSearchDesign
      })}
    >
      {isCheckoutPage ? (
        <CheckoutHeader />
      ) : (
        <HeaderComponent newSearchDesign={newSearchDesign} />
      )}
    </div>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    paddingTop: spacing(19),
    [breakpoints.down("md")]: {
      paddingTop: spacing(8)
    }
  },
  rootNewSearchDesign: {
    paddingTop: spacing(27),
    [breakpoints.down("sm")]: {
      paddingTop: spacing(15)
    }
  },
  checkoutHeader: {
    paddingTop: spacing(8),
    [breakpoints.down("sm")]: {
      paddingTop: 46
    }
  }
}))

export default withRouter(Header)
