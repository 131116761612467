import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import Spinner from "common/Spinners/Spinner"
import React from "react"

type Props = {
  fetching: boolean
  className?: string
}

const PageSpinner: React.FC<Props> = ({ className, fetching }: Props) => {
  const classes = useStyles()

  if (!fetching) return null

  return (
    <div className={classNames(classes.root, className)}>
      <Spinner className={classes.spinner} />
    </div>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "fixed",
    zIndex: 99999,
    width: "100%",
    top: "calc(50% - 23px)",
    left: 0
  },
  spinner: {
    boxShadow: "0px 0px 20px rgba(0,0,0,0.3)",
    backgroundColor: "#fff",
    boxSizing: "content-box"
  }
}))

export default PageSpinner
