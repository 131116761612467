const { ENVIRONMENT } = window

const urls = {
  Production: process.env.REACT_APP_EPI_PRODUCTION_API_ROOT,
  Preproduction: process.env.REACT_APP_EPI_PREPRODUCTION_API_ROOT,
  Integration: process.env.REACT_APP_EPI_INTEGRATION_API_ROOT,
  Development: process.env.REACT_APP_EPI_DEV_API_ROOT
}

const config = {
  apiRoot: window["BASE_URL"] || urls[ENVIRONMENT || "Integration"],
  runAsDetached: process.env.REACT_APP_RUN_AS_DETACHED || "false",
  runInNgrok: process.env.REACT_APP_RUN_IN_NGROK || "false",
  alwaysReturnApiErrors:
    process.env.REACT_APP_ALWAYS_RETURN_API_ERRORS || "false"
}

export default config
