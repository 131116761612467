import { DynamicPage } from "@starrepublic/epi/types/cms/content"
import api from "api"
import { asyncState, createStore } from "global-hook-store"

const langRegex = new RegExp("^[a-zA-Z]{0,2}(-[a-zA-Z]{0,2})?$")

const pageStore = createStore(
  {
    page: asyncState<DynamicPage>(),
    fetchLocation: true,
    autoScroll: true,
    shouldTrackPageView: true,
    scrollBackToPosition: window.scrollY
  },
  {
    fetch: async (state, { id, useCache = true }, { asyncAction }) => {
      let parsedId = id
      const isLang: boolean = langRegex.test(id.split("/")[1])
      if (isLang) {
        parsedId = id.split("/").splice(2).join("/")
      }

      return asyncAction(
        "page",
        api.pages.getContentByIdentifier(parsedId, useCache)
      )
    },
    setFetchLocation: (state, fetchLocation) => ({ ...state, fetchLocation }),
    setShouldTrackPageView: (state, shouldTrackPageView) => ({
      ...state,
      shouldTrackPageView
    }),
    setAutoscroll: (state, autoScroll) => ({ ...state, autoScroll }),
    setScrollBackToPosition: (state, scrollBackToPosition) => ({
      ...state,
      scrollBackToPosition
    })
  }
)

export default pageStore
