import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import Typography from "common/Typography"
import React from "react"

type Props = {
  title: string
  hitCount?: number
  divider?: boolean
}

const SearchSection: React.FC<Props> = ({
  title,
  children,
  hitCount,
  divider
}) => {
  const classes = useStyles()

  return (
    <div className={classNames({ [classes.divider]: divider }, classes.root)}>
      <Typography className={classes.title} component="span" variant="caption">
        {title}
        {hitCount !== undefined && ` (${hitCount})`}
      </Typography>
      {children}
    </div>
  )
}

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  title: {
    textAlign: "left",
    margin: `0 0 ${spacing(2)}px`,
    color: palette.rapunzel.textSecondary
  },
  root: {
    marginBottom: spacing(3)
  },
  divider: {
    [breakpoints.down("sm")]: {
      paddingBottom: spacing(2),
      borderBottom: `1px solid ${palette.rapunzel.alto}`
    }
  }
}))

export default SearchSection
