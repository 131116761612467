import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"
type Props = SvgIconProps

const TwitterIcon: React.FunctionComponent<Props> = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M24 4.5998C23.1 4.9998 22.2 5.2998 21.2 5.3998C22.2 4.7998 23 3.7998 23.4 2.6998C22.4 3.2998 21.4 3.6998 20.3 3.8998C19.4 2.8998 18.1 2.2998 16.7 2.2998C14 2.2998 11.8 4.4998 11.8 7.1998C11.8 7.5998 11.8 7.9998 11.9 8.2998C7.7 8.0998 4.1 6.0998 1.7 3.0998C1.2 3.8998 1 4.6998 1 5.5998C1 7.2998 1.9 8.7998 3.2 9.6998C2.4 9.6998 1.6 9.4998 1 9.0998C1 9.0998 1 9.0998 1 9.1998C1 11.5998 2.7 13.5998 4.9 13.9998C4.5 14.0998 4.1 14.1998 3.6 14.1998C3.3 14.1998 3 14.1998 2.7 14.0998C3.3 16.0998 5.1 17.4998 7.3 17.4998C5.6 18.7998 3.5 19.5998 1.2 19.5998C0.8 19.5998 0.4 19.5998 0 19.4998C2.2 20.8998 4.8 21.6998 7.5 21.6998C16.6 21.6998 21.5 14.1998 21.5 7.6998C21.5 7.4998 21.5 7.2998 21.5 7.0998C22.5 6.39981 23.3 5.4998 24 4.5998Z"
      fill="white"
    />
  </SvgIcon>
)

export default TwitterIcon
