import ButtonBase from "@material-ui/core/ButtonBase"
import { makeStyles } from "@material-ui/core/styles"
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon
} from "icons/index"
import * as React from "react"
import { SocialLink } from "types/content/footer"

type Props = {
  iconWidth: string
  iconHeight: string
  socialMedias: SocialLink[]
}

const socialMediaMapper = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedin: LinkedinIcon,
  twitter: TwitterIcon,
  youtube: YoutubeIcon
}

const SocialMedia: React.FunctionComponent<Props> = ({
  iconWidth,
  iconHeight,
  socialMedias
}) => {
  const classes = useStyles()

  return (
    <>
      {socialMedias.map(socialMedia => {
        const Component = socialMediaMapper[socialMedia.text]
        return (
          <ButtonBase
            key={socialMedia.text}
            href={socialMedia.href}
            target={socialMedia.target}
            rel="noopener"
          >
            <Component
              key={socialMedia.text}
              width={iconWidth}
              height={iconHeight}
              className={classes.item}
            />
          </ButtonBase>
        )
      })}
    </>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  item: {
    border: `${spacing(0.25)}px solid ${palette.primary.contrastText}`,
    padding: spacing(1),
    width: spacing(6),
    height: spacing(6),
    marginRight: spacing(2)
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row"
  }
}))

export default SocialMedia
