import request from "@starrepublic/epi/api/request"
import { SearchResult } from "models/Search"

const search = {
  search: (q: string) => request.get<SearchResult>(`/search/${q.trim()}`),

  popular: (n = 5) => request.get<string[]>(`/search/popular/${n}`),

  track: (q: string, hitId: string, trackId: string) =>
    request.get(`/search/track?query=${q}&hitId=${hitId}&trackId=${trackId}`),

  autocomplete: (q: string, limit = 5) =>
    request.get<string[]>(`/search/autocomplete/${q.trim()}?limit=${limit}`)
}

export default search
