import React from "react"

import { BlockProps, ContentProps } from "../../../types/cms/props"

export type CmsContextProps = {
  pageMap: PageComponentMap
  blockMap: BlockComponentMap
  formMap: BlockComponentMap
  isEditMode: boolean
}

export type PageComponentMap = {
  [key: string]: React.ComponentType<ContentProps>
}
export type BlockComponentMap = {
  [key: string]: React.ComponentType<BlockProps>
}

const CmsContext = React.createContext<CmsContextProps>({
  isEditMode: false,
  pageMap: {},
  blockMap: {},
  formMap: {}
})

export default CmsContext
