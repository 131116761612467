import request from "@starrepublic/epi/api/request"

const newsLetter = {
  post: (email: string) =>
    request.post<Record<string, unknown>>(`/subscriptions/${email}`),
  unsubscribe: (email: string, listId: string) =>
    request.delete(`/subscriptions/${email}/${listId}`)
}

export default newsLetter
