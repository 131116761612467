import { makeStyles } from "@material-ui/core/styles"
import { TextFieldProps } from "@material-ui/core/TextField"
import FormikTextField from "common/Formik/FormikTextField"
import * as React from "react"

type Props = TextFieldProps

const CustomTextField: React.FunctionComponent<Props> = ({
  name,
  label,
  placeholder = "",
  select,
  children,
  type,
  required,
  multiline = false,

  onChange,
  disabled,
  SelectProps = {},
  InputProps,
  InputLabelProps
}) => {
  const classes = useStyles()

  return (
    <FormikTextField
      InputLabelProps={{
        shrink: true,
        className: classes.label,
        ...InputLabelProps
      }}
      InputProps={{
        className: classes.input,
        disableUnderline: true,
        fullWidth: true,
        classes: { error: classes.error },
        ...InputProps
      }}
      FormHelperTextProps={{ classes: { error: classes.helperTextError } }}
      select={select}
      SelectProps={SelectProps}
      multiline={multiline}
      onChange={onChange}
      required={required}
      name={name || ""}
      label={label}
      disabled={disabled}
      placeholder={placeholder}
      type={type}
    >
      {children}
    </FormikTextField>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  label: {
    fontWeight: 300,
    fontSize: 12,
    marginBottom: spacing(0.5),
    color: palette.text.secondary
  },
  input: {
    border: `1px solid ${palette.rapunzel.darkBorder}`,
    padding: `6px ${spacing(1)}px`,
    boxSizing: "border-box",
    "& input": {
      fontWeight: 300,
      fontSize: 16,
      "&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus, ":
        {
          "-webkit-text-fill-color": palette.rapunzel.textDefault,
          "-webkit-box-shadow": " 0 0 0px 1000px transparent inset",
          transition: "background-color 5000s ease-in-out 0s"
        }
    },
    "& select": {
      fontFamily: "'Circular Pro','Helvetica Neue',Helvetica,sans-serif",
      fontWeight: 300,
      fontSize: 16,
      "&:-webkit-autofill,&:-webkit-autofill:hover,&:-webkit-autofill:focus, ":
        {
          "-webkit-text-fill-color": palette.rapunzel.textDefault,
          "-webkit-box-shadow": " 0 0 0px 1000px transparent inset",
          transition: "background-color 5000s ease-in-out 0s"
        }
    }
  },
  error: {
    border: `1px solid ${palette.rapunzel.errorBorder}`
  },
  helperTextError: {
    fontWeight: 300,
    fontSize: 12,
    marginTop: spacing(0.5)
  }
}))

export default CustomTextField
