import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import { browserHistory } from "common/Root"
import useStore from "global-hook-store"
import React, { useEffect, useState } from "react"
import authStore from "stores/authStore"
import siteSettingsStore from "stores/siteSettingsStore"

import NewsletterDialogSignup from "./NewsletterDialogSignup"
import NewsletterDialogSignupConfirmation from "./NewsletterDialogSignupConfirmation"

const NewsletterDialog: React.FunctionComponent = () => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isSignupDone, setIsSignupDone] = useState(false)

  const newsletterHashString = "#newsletter"
  const newsletterStorageClosedDialog = "NewsletterModalClosed"
  const newsletterStorageRegistered = "NewsleterModalRegistered"

  let menuObserver: MutationObserver | null = null

  const classes = useStyles()

  const {
    state: { newsletter }
  } = useStore(siteSettingsStore)

  const {
    state: { isLoggedIn },
    actions: { getToken }
  } = useStore(authStore)

  browserHistory.listen(location => {
    if (location.hash === newsletterHashString) {
      setDialogOpen(true)
    }
  })

  const openDialogWhenAppropriate = () => {
    const root = document.getElementById("root")

    if (!root) {
      return
    }

    setTimeout(() => {
      const isMenuesOpened = root.hasAttribute("aria-hidden")
      if (isLoggedIn) {
        return
      }

      if (!isMenuesOpened) {
        // No menues seems to be opened, let's show the newsletter modal
        setDialogOpen(true)
        return
      }

      // Menues are opened, we'll create a listener for when they are closed
      menuObserver = new MutationObserver(mutations => {
        const attributeMutation = mutations.find(
          x => x.type === "attributes" && x.attributeName === "aria-hidden"
        )

        if (attributeMutation) {
          setTimeout(() => {
            setDialogOpen(true)

            if (menuObserver) {
              menuObserver.disconnect()
            }
          }, 2000)
        }
      })

      menuObserver.observe(root, { childList: false, attributes: true })
    }, newsletter.popupDelaySecs * 1000)
  }

  useEffect(() => {
    // Check if the modal should be shown automatically depending on localStorage
    const userAlreadyRegistered = window.localStorage.getItem(
      newsletterStorageRegistered
    )
    const userClosedDialogStorage = window.localStorage.getItem(
      newsletterStorageClosedDialog
    )
    const userClosedDialogAt = userClosedDialogStorage
      ? parseInt(userClosedDialogStorage, 10)
      : 0
    const dialogTimeoutInMs = newsletter.cookieTimeout * 60 * 60 * 1000
    const dialogShouldShowBasedOnTime =
      userClosedDialogAt + dialogTimeoutInMs < new Date().getTime()

    const newsLetterHashIsInUrl =
      browserHistory.location.hash === newsletterHashString

    getToken()
      .then(state => {
        if (
          !state.isLoggedIn &&
          !userAlreadyRegistered &&
          (dialogShouldShowBasedOnTime || newsLetterHashIsInUrl)
        ) {
          openDialogWhenAppropriate()
        }
      })
      .catch(() => {
        // If we can't get the token, we'll show the dialog anyway
        if (
          !userAlreadyRegistered &&
          (dialogShouldShowBasedOnTime || newsLetterHashIsInUrl)
        ) {
          openDialogWhenAppropriate()
        }
      })

    return () => {
      // Unsubscribe from menuObserver when component unload
      if (menuObserver) {
        menuObserver.disconnect()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  const closeDialog = () => {
    window.localStorage.setItem(
      newsletterStorageClosedDialog,
      new Date().getTime().toString()
    )

    if (browserHistory.location.hash === newsletterHashString) {
      browserHistory.push("", {
        hash: ""
      })
    }

    setDialogOpen(false)
  }

  return (
    <>
      <Dialog
        PaperProps={{ className: classes.dialogPaper }}
        onClose={closeDialog}
        open={dialogOpen}
        className={classes.root}
      >
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={closeDialog}
        >
          <CloseIcon fontSize="large" className={classes.closeIcon} />
        </IconButton>

        {!isSignupDone && (
          <NewsletterDialogSignup setIsSignupDone={setIsSignupDone} />
        )}

        {isSignupDone && (
          <NewsletterDialogSignupConfirmation closeDialog={closeDialog} />
        )}
      </Dialog>
    </>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints, typography }) => ({
  root: {
    zIndex: 9999
  },
  dialogPaper: {
    [breakpoints.down("sm")]: {
      marginLeft: spacing(2),
      marginRight: spacing(2)
    }
  },
  triggerLink: {
    color: "#fff"
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
    color: "#000",
    [breakpoints.down("sm")]: {
      padding: spacing(0.5)
    }
  },
  closeIcon: {
    [breakpoints.down("sm")]: {
      fontSize: typography.fontSize * 2
    }
  }
}))

export default NewsletterDialog
