import request from "@starrepublic/epi/api/request"
import { CardProduct, RecommendationGroup } from "models/Products"

const recommendations = {
  track: (recId: string, url: string) =>
    request.get(`/recommendations/track/${recId}/${url}`),

  items: (recommendations: RecommendationGroup) =>
    request.post<CardProduct[]>(`/recommendations/items`, recommendations),

  get: (code: string) =>
    request.get<RecommendationGroup>(`/recommendations/${code}`),

  getRecommendedItems: (take?: number) =>
    request.get<CardProduct[]>(`/cart/recommendedItems/?take=${take}`)
}

export default recommendations
