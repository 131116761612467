import React from "react"
import { Link, LinkProps } from "react-router-dom"

import config from "../../config"
import { isLocalHost } from "../../utils/location"
import { logDevMessage, Severity } from "../utils/logging"

const toRelativeLink = (source: string) => {
  if (source === "" || source === null) {
    return ""
  }
  if (source.startsWith("/")) {
    return source
  }
  try {
    const url = new URL(source)
    const apiRootUrl = new URL(config.apiRoot)
    const windowHostname = window.location.hostname
    const urlHostname = url.hostname
    const isSourceLocalHost = isLocalHost()
    if (
      urlHostname === windowHostname ||
      (isSourceLocalHost &&
        (urlHostname.endsWith("episerver.net") ||
          urlHostname.endsWith(apiRootUrl.hostname)))
    ) {
      return url.pathname + url.searchParams
    }

    return source
  } catch (e) {
    logDevMessage(`Invalid URL: ${source}`, Severity.Error)
    return ""
  }
}

type Props = LinkProps & {
  to: string
}

const RelativeLink: React.FunctionComponent<Props> = ({ to, ...props }) => (
  <Link to={toRelativeLink(to)} {...props} />
)

export default RelativeLink
export { toRelativeLink }
