import { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Flag: React.FunctionComponent<Props> = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path fill="#f0f0f0" d="M0 85.3h512v341.3H0z" />
    <g fill="#d80027">
      <path d="M0 128h512v42.7H0zM0 213.3h512V256H0zM0 298.7h512v42.7H0zM0 384h512v42.7H0z" />
    </g>
    <path fill="#2e52b2" d="M0 85.3h256v183.8H0z" />
    <g fill="#f0f0f0">
      <path d="M99.8 160.6l-4.1 12.7H82.4l10.8 7.8-4.2 12.7 10.8-7.8 10.8 7.8-4.1-12.7 10.8-7.8h-13.4zM103.9 219.1l-4.1-12.7-4.1 12.7H82.4l10.8 7.8-4.2 12.7 10.8-7.8 10.8 7.8-4.1-12.7 10.8-7.8zM47.6 219.1l-4.1-12.7-4.2 12.7H26l10.8 7.8-4.1 12.7 10.8-7.8 10.7 7.8-4.1-12.7 10.8-7.8zM43.5 160.6l-4.2 12.7H26l10.8 7.8-4.1 12.7 10.8-7.8 10.7 7.8-4.1-12.7 10.8-7.8H47.6zM99.8 114.8l-4.1 12.7H82.4l10.8 7.9-4.2 12.7 10.8-7.9 10.8 7.9-4.1-12.7 10.8-7.9h-13.4zM43.5 114.8l-4.2 12.7H26l10.8 7.9-4.1 12.7 10.8-7.9 10.7 7.9-4.1-12.7 10.8-7.9H47.6zM156.2 160.6l-4.1 12.7h-13.4l10.8 7.8-4.1 12.7 10.8-7.8 10.8 7.8-4.2-12.7 10.8-7.8h-13.3zM160.3 219.1l-4.1-12.7-4.1 12.7h-13.4l10.8 7.8-4.1 12.7 10.8-7.8 10.8 7.8-4.2-12.7 10.8-7.8zM216.7 219.1l-4.2-12.7-4.1 12.7h-13.3l10.8 7.8-4.1 12.7 10.7-7.8 10.8 7.8-4.1-12.7 10.8-7.8zM212.5 160.6l-4.1 12.7h-13.3l10.8 7.8-4.1 12.7 10.7-7.8 10.8 7.8-4.1-12.7 10.8-7.8h-13.3zM156.2 114.8l-4.1 12.7h-13.4l10.8 7.9-4.1 12.7 10.8-7.9 10.8 7.9-4.2-12.7 10.8-7.9h-13.3zM212.5 114.8l-4.1 12.7h-13.3l10.8 7.9-4.1 12.7 10.7-7.9 10.8 7.9-4.1-12.7 10.8-7.9h-13.3z" />
    </g>
  </svg>
)

export default Flag
