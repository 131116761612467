import { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Flag: React.FunctionComponent<Props> = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path fill="#d80027" d="M0 85.3h512v341.3H0z" />
    <path
      fill="#f0f0f0"
      d="M192 85.3h-64V224H0v64h128v138.7h64V288h320v-64H192z"
    />
  </svg>
)

export default Flag
