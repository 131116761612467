import ButtonBase from "@material-ui/core/ButtonBase"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import classNames from "classnames"
import useStore from "global-hook-store"
import useBreakpoint from "hooks/useBreakpoint"
import ShoppingBasketIcon from "icons/ShoppingBasket"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import authStore from "stores/authStore"
import cartDrawerStore from "stores/cartDrawerStore"
import cartStore from "stores/cartStore"

type Props = {
  mobile?: boolean
  className?: string
  collapsed?: boolean
}

const BasketButton: React.FunctionComponent<Props> = ({
  mobile,
  collapsed,
  className
}) => {
  const {
    state: cartOpen,
    actions: { setCartOpen }
  } = useStore(cartDrawerStore)

  const classes = useStyles()

  const isMobile = useBreakpoint("md")

  const {
    state: { addingItem, itemCount }
  } = useStore(cartStore)

  const {
    state: { isLoggedIn }
  } = useStore(authStore)

  const { t } = useTranslation()
  const handleButtonClick = () => {
    setCartOpen(!cartOpen)
  }

  const windowsOffset: React.CSSProperties =
    (navigator.appVersion.toLowerCase().includes("windows") && {
      marginTop: 4
    }) ||
    {}

  const basketHasItems =
    itemCount.default && itemCount.default > 0 ? true : false

  useEffect(() => {
    if (cartOpen) {
      document.documentElement.style.overflowY = "hidden"
      if (isMobile) {
        document.documentElement.style.height = "100vh"
        document.body.style.position = "fixed"
        document.body.style.maxWidth = "100%"
        document.body.style.cssText = "overflow-anchor: none;"
      }
    } else {
      document.documentElement.removeAttribute("style")
      document.body.removeAttribute("style")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartOpen])

  const content = (
    <span className={classes.iconContainer}>
      <ShoppingBasketIcon
        className={classes.icon}
        color="inherit"
        mobile={mobile}
        collapsed={collapsed}
        basketHasItems={basketHasItems}
        style={
          addingItem
            ? {
                animationName: "bounce",
                animationDuration: "1000ms",
                animationIterationCount: 1,
                animationTimingFunction: "ease-in-out"
              }
            : {}
        }
      />
      {!!itemCount && (
        <Typography
          variant="button"
          className={classNames(
            classes.countText,
            { [classes.countTextLoggedIn]: isLoggedIn },
            {
              [classes.countTextMobile]:
                (basketHasItems && isLoggedIn) || collapsed
            }
          )}
          style={windowsOffset}
        >
          {itemCount.default && itemCount.default > 0 ? itemCount.default : ""}
        </Typography>
      )}
    </span>
  )

  if (mobile) {
    return (
      <IconButton
        component="div"
        color="inherit"
        aria-label="Menu"
        onClick={handleButtonClick}
        className={`${classes.mobileButton} ${className}`}
      >
        {content}
      </IconButton>
    )
  }

  return (
    <ButtonBase
      className={classNames(classes.button, {
        [classes.buttonLoggedIn]: isLoggedIn
      })}
      onClick={handleButtonClick}
    >
      {content}
      <Typography
        variant="button"
        color="inherit"
        className={classNames(classes.text, {
          [classes.textLoggedIn]: isLoggedIn
        })}
      >
        {t("common.basket")}
      </Typography>
    </ButtonBase>
  )
}

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  button: {
    backgroundColor: palette.rapunzel.darkGray,
    paddingRight: spacing(3),
    paddingLeft: spacing(3),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: palette.rapunzel.white,
    alignContent: "center"
  },
  buttonLoggedIn: {
    backgroundColor: palette.rapunzel.white,
    marginRight: -5
  },
  iconContainer: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    [breakpoints.up("lg")]: {
      paddingBottom: 4
    }
  },
  countText: {
    color: palette.rapunzel.textDefault,
    position: "absolute",
    marginTop: 3,
    letterSpacing: -0.1,
    [breakpoints.down("md")]: {
      color: palette.rapunzel.white
    }
  },
  countTextLoggedIn: {
    color: palette.rapunzel.white
  },
  countTextMobile: {
    color: palette.rapunzel.white,
    [breakpoints.down("md")]: {
      color: palette.rapunzel.black
    }
  },
  text: {
    textTransform: "uppercase",
    marginLeft: spacing(1)
  },
  textLoggedIn: {
    color: palette.rapunzel.black
  },
  icon: {
    width: 20,
    height: 23,
    marginBottom: 1
  },
  mobileButton: {
    padding: 0
  }
}))

export default BasketButton
