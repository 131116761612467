const langCodeToName = (code: string): string => {
  switch (code) {
    case "en":
      return "English"
    case "es":
      return "Español"
    case "fr":
      return "Français"
    case "fi":
      return "Suomi"
    case "de":
      return "Deutsch"
    case "it":
      return "Italiano"
    case "pl":
      return "Polski"
    case "da":
      return "Dansk"
    case "no":
      return "Norsk"
    case "sv":
      return "Svenska"
    default:
      return "Unknown"
  }
}

const langCodeToEnglishName = (code: string): string => {
  switch (code) {
    case "en":
      return "English"
    case "es":
      return "Spanish"
    case "fr":
      return "French"
    case "fi":
      return "Finnish"
    case "de":
      return "German"
    case "it":
      return "Italian"
    case "pl":
      return "Polish"
    case "da":
      return "Danish"
    case "no":
      return "Norwegian"
    case "sv":
      return "Swedish"
    default:
      return "Unknown"
  }
}

export { langCodeToName, langCodeToEnglishName }
