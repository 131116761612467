type EditAttributes = {
  "data-epi-edit": string
}

interface EditPropInfo {
  name?: string
}

const editorAttributes = (
  propInfo?: EditPropInfo
): EditAttributes | undefined => {
  if (!propInfo || !propInfo.name) {
    return undefined
  }

  return {
    "data-epi-edit": propInfo.name
  }
}

export default editorAttributes
