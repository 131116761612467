import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import IconButton from "@material-ui/core/IconButton"
import Input from "@material-ui/core/Input"
import ListItem from "@material-ui/core/ListItem"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
// Material UI Component
import { makeStyles } from "@material-ui/core/styles"
import config from "@starrepublic/epi/config"
import classNames from "classnames"
import {
  trackAddToCart,
  trackRemoveFromCart
} from "common/GoogleTagManagerTracking"
import Image from "common/Image"
import LinkButton from "common/LinkButton"
import ProductPrice from "common/Product/ProductPrice"
// Common Component
import Typography from "common/Typography"
import useStore from "global-hook-store"
import ArrowDown from "icons/ArrowDown"
// Icons
import Delete from "icons/Delete"
import { LineItem } from "models/Cart"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import cartDrawerStore from "stores/cartDrawerStore"
import cartStore from "stores/cartStore"
import marketStore from "stores/marketStore"

type Props = {
  item: LineItem
}

const maxQuantity = 999

const CartItem: React.FC<Props> = ({ item }) => {
  const [handleChangeQtyCalled, setHandleChangeQtyCalled] = useState(false)
  const [handleDeleteItem, sethandleDeleteItem] = useState(false)
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    state: { defaultMarket }
  } = useStore(marketStore)

  const {
    actions: { setCartOpen }
  } = useStore(cartDrawerStore)

  const {
    state: {
      _currentItem: { loading },
      itemCount,
      addingItem,
      carts: { data: carts }
    },
    actions: { updateItemQuantity }
  } = useStore(cartStore)

  const closeCart = () => {
    setCartOpen(false)
  }

  const handleDelete = () => {
    changeItemQuantity(-item.quantity)

    if (-item.quantity) {
      trackRemoveFromCart({
        item,
        orderGroupId: carts?.default?.orderGroupId,
        isClearCartEvent: (itemCount.default || 0) - item.quantity < 1
      })

      sethandleDeleteItem(true)
    }
  }

  const handleChangeQuantity = (e: any) => {
    let quantity = parseInt(e.target.value, 10)

    if (isNaN(quantity)) {
      return
    }
    if (quantity !== item.quantity) {
      changeItemQuantity(quantity - item.quantity)
    }
    if (quantity > maxQuantity) {
      quantity = maxQuantity
    }
    if (quantity > item.quantity) {
      trackAddToCart({
        item,
        quantity: quantity - item.quantity,
        orderGroupId: carts?.default?.orderGroupId
      })
    } else {
      trackRemoveFromCart({
        item,
        quantity: item.quantity - quantity,
        orderGroupId: carts?.default?.orderGroupId
      })
    }
  }

  const changeItemQuantity = (quantityDelta: number) => {
    if (!loading) {
      updateItemQuantity({
        cartType: "default",
        item,
        quantity: quantityDelta
      })
      setHandleChangeQtyCalled(true)
    }
  }

  useEffect(() => {
    if (
      !loading &&
      !!carts?.default &&
      (handleChangeQtyCalled || handleDeleteItem)
    ) {
      setHandleChangeQtyCalled(false)
      sethandleDeleteItem(false)
    }
  }, [carts.default, loading, handleChangeQtyCalled, handleDeleteItem])

  return (
    <ListItem disableGutters className={classes.root} component="li">
      <Card className={classes.card}>
        <LinkButton
          link={item.properties.url || "/"}
          className={classes.imageBlock}
          onClick={closeCart}
          buttonClassName={classes.imageBlockButton}
        >
          <Image
            src={
              item.properties.image &&
              `${config.apiRoot}/${item.properties.image}`
            }
            width={85}
            height={104}
          />
        </LinkButton>
        <div className={classes.productOrdered}>
          <div className={classes.textDeleteBlock}>
            <CardContent className={classes.content}>
              <LinkButton
                link={item.properties.url || "/"}
                variant="body5"
                color="textPrimary"
                component="h3"
                linkText={item.properties.productName}
                onClick={closeCart}
                linkContentClassName={classes.productName}
                buttonClassName={classes.displayName}
              />
              <Typography
                variant="body6"
                color="textPrimary"
                component="h3"
                className={classes.productName2}
              >
                {item.properties.productName2}
              </Typography>
              <Typography variant="body6" color="textPrimary">
                {item.properties.displayName2}
                {item.properties.productOption}
              </Typography>
            </CardContent>

            <div className={classes.deleteIcon}>
              {!item.isGift && (
                <IconButton
                  className={classNames(
                    classes.iconButton,
                    classes.iconButtonClear,
                    {
                      [classes.isAdding]: addingItem
                    }
                  )}
                  onClick={handleDelete}
                >
                  <Delete className={classes.iconButtonSvg} />
                </IconButton>
              )}
            </div>
          </div>

          {!item.isGift && (
            <div className={classes.quantityPriceBlock}>
              <CardActions className={classes.actions}>
                <div className={classes.quantitySelect}>
                  <Select
                    value={item.quantity}
                    classes={{
                      root: classes.rootSelect,
                      select: classes.select
                    }}
                    onChange={handleChangeQuantity}
                    input={
                      <Input
                        disableUnderline
                        classes={{
                          input: classes.input,
                          focused: classes.selectInputFocused
                        }}
                      />
                    }
                    IconComponent={ArrowDown}
                  >
                    {Array.from(Array(50), (_, index) => (
                      <MenuItem
                        className={classes.quantityItem}
                        value={index + 1}
                        key={index + 1}
                      >
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </CardActions>

              <ProductPrice
                currency={defaultMarket?.defaultCurrency}
                className={classes.itemPrice}
                price={item.extendedPlacedPrice}
                priceString={item.extendedPlacedPriceString}
                discount={item.discountedPriceString}
                isSmall
              />
            </div>
          )}
        </div>
        {item.isGift && (
          <Typography variant="body2" color="textPrimary">
            {t("checkout.free_gift")}
          </Typography>
        )}
      </Card>
    </ListItem>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    flex: "0 0 auto",
    padding: 0,
    flexDirection: "column",
    [breakpoints.down("sm")]: {
      paddingBottom: 0
    }
  },
  quantityItem: {
    display: "flex !important",
    padding: "12px 16px !important",
    fontWeight: 400,
    fontSize: 18
  },
  card: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    paddingBottom: spacing(1),
    boxShadow: "inherit",
    paddingTop: spacing(1)
  },
  imageBlock: {
    flex: "0 0 auto",
    padding: 0,
    textAlign: "left"
  },
  imageBlockButton: {
    padding: 0,
    [breakpoints.down("xs")]: {
      padding: 0
    }
  },
  productOrdered: {
    flex: "1",
    display: "flex",
    flexDirection: "column"
  },
  textDeleteBlock: {
    flex: "1",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    paddingLeft: spacing(1.25),
    maxHeight: spacing(9.5),
    marginBottom: spacing(0.5)
  },
  content: {
    flex: 8,
    textAlign: "left",
    padding: 0,
    "&:last-child": {
      paddingBottom: 0
    }
  },
  deleteIcon: {
    flex: 1,
    marginTop: spacing(2),
    padding: 0,
    [breakpoints.down(390)]: {
      flex: 2
    }
  },
  quantityPriceBlock: {
    maxHeight: spacing(3),
    display: "flex",
    flex: "1 0 auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  iconButton: {
    padding: 0
  },
  iconButtonClear: {
    position: "absolute",
    right: spacing(-0.25),
    [breakpoints.down("xs")]: {
      right: spacing(0.5)
    }
  },
  isAdding: {
    opacity: 0.5
  },
  iconButtonSvg: {
    color: "#000",
    fontSize: spacing(2)
  },
  actions: {
    flex: 0.2,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: 0
  },
  quantitySelect: {
    display: "flex",
    alignItems: "center",
    margin: "0 0 0 8px"
  },
  itemPrice: {
    justifyContent: "center",
    alignItems: "flex-end",
    maxHeight: spacing(3),
    margin: 0
  },
  stockStatus: {
    borderBottom: 0,
    paddingTop: spacing(1),
    paddingBottom: spacing(1)
  },
  displayName: {
    padding: "0 0 2px",
    textAlign: "left"
  },
  productName: {
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1,
    overflow: "hidden"
  },
  productName2: {
    paddingBottom: spacing(0.25),
    textAlign: "left",
    display: "box",
    lineClamp: 1,
    boxOrient: "vertical",
    overflow: "hidden"
  },
  rootSelect: {
    fontSize: spacing(2),
    fontWeight: 300,
    border: 0,
    "&::before": {
      content: ""
    }
  },
  select: {
    backgroundColor: "rgba(0,0,0,0.03)",
    color: "rgba(0, 0, 0, 0.84)",
    "&:focus": {
      backgroundColor: "rgba(0,0,0,0.03)",
      color: "rgba(0, 0, 0, 0.84)"
    }
  },
  input: {
    padding: "2px 24px 1px 8px"
  },
  selectInputFocused: {
    background: "transparent"
  }
}))

export default CartItem
