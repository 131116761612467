import FormControl from "@material-ui/core/FormControl"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import ArrowForward from "@material-ui/icons/ArrowForward"
import Check from "@material-ui/icons/Check"
import XhtmlString, {
  XhtmlStringProperty
} from "@starrepublic/epi/cms/components/XhtmlString"
import newsLetter from "api/newsletter"
import classNames from "classnames"
import { pushNewsletterAction } from "common/GoogleTagManagerTracking"
import Spinner from "common/Spinners/Spinner"
import * as React from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { string, ValidationError } from "yup"

type Props = {
  footerNewsLetterText: XhtmlStringProperty
  footerNewsLetterHeadline: string
}

const NewsLetter = ({
  footerNewsLetterHeadline,
  footerNewsLetterText
}: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const emailSchema = string()
    .required(t("common.required_field") as string)
    .email(t("common.email_invalid_message") as string)

  const handleSubmit = async (e?: React.SyntheticEvent<HTMLElement>) => {
    e?.preventDefault()

    if (isLoading || isDone) {
      return
    }

    try {
      await emailSchema.validate(email)
      setIsLoading(true)
      setIsDone(false)
      setErrorMessage("")
      await newsLetter.post(email)
      pushNewsletterAction("email", "Subscribe", "NewsletterFooter")
      setIsDone(true)
    } catch (e) {
      let errorMessage

      if (e instanceof ValidationError) {
        errorMessage = (e as ValidationError).message
      } else if (e === "EMAIL_ALREADY_REGISTERED") {
        errorMessage = t("error.email_already_registered")
      } else {
        errorMessage = t("common.general_error_message")
      }

      setErrorMessage(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDone(false)
    setErrorMessage("")
    setEmail(e.target.value)
  }

  const placeholder = t("common.enter_email")

  return (
    <>
      <Typography variant="h6" className={classes.title} color="inherit">
        {footerNewsLetterHeadline}
      </Typography>
      <Typography
        component="div"
        variant="body2"
        color="inherit"
        className={classes.body}
      >
        <XhtmlString content={footerNewsLetterText} />
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl className={classes.button} error={!!errorMessage}>
          <TextField
            placeholder={placeholder}
            InputProps={{ disableUnderline: true }}
            fullWidth={true}
            className={classes.input}
            onChange={handleInputChange}
            disabled={isLoading}
            type="email"
          />
          <div
            className={classNames(classes.iconContainer, {
              [classes.successIconContainer]: isDone,
              [classes.loadingIconContainer]: isLoading
            })}
            onClick={handleSubmit}
          >
            {isLoading ? (
              <Spinner size="xs" />
            ) : isDone ? (
              <Check />
            ) : (
              <ArrowForward />
            )}
          </div>
        </FormControl>
      </form>

      <Typography
        component="div"
        variant="body2"
        color="error"
        className={classes.errorMessage}
      >
        {errorMessage}
      </Typography>
    </>
  )
}

const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  body: {
    marginBottom: spacing(1.5)
  },
  errorMessage: { height: spacing(3), marginTop: spacing(1) },
  root: {},
  title: {
    marginBottom: spacing(3)
  },
  button: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center"
  },
  input: {
    backgroundColor: palette.primary.contrastText,
    fontSize: spacing(3),
    padding: `7px ${spacing(2)}px`,
    [breakpoints.up("lg")]: {
      minWidth: `${spacing(64)}px`
    }
  },
  iconContainer: {
    border: `${spacing(0.25)}px solid ${palette.primary.contrastText}`,
    fontSize: spacing(7),
    minWidth: `${spacing(6)}px`,
    height: `${spacing(6)}px`,
    backgroundColor: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  successIconContainer: {
    backgroundColor: "#27793A"
  },
  loadingIconContainer: {
    cursor: "initial"
  }
}))

export default NewsLetter
