import MenuItem from "@material-ui/core/MenuItem"
import MuiSelect, { SelectProps } from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import Typography from "common/Typography"
import useBreakpoint from "hooks/useBreakpoint"
import React from "react"

type Value = {
  label: string
  value: string
  Icon?: React.ReactElement
}

type OwnProps = SelectProps & {
  label: string
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
  values: Value[]
  currentValue: string
}

type Props = OwnProps

const SelectList: React.FC<Props> = ({
  onChange,
  values,
  disabled,
  label,
  currentValue
}) => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>

      <MuiSelect
        onChange={onChange}
        value={currentValue}
        variant="filled"
        disabled={disabled}
        IconComponent={KeyboardArrowDownIcon}
        disableUnderline
        className={classes.select}
      >
        {values.map((value, index) => (
          <MenuItem key={`Select_Item_${index}`} value={value.value}>
            {value.Icon} {value.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </>
  )
}

const SelectListNative: React.FC<Props> = ({
  label,
  onChange,
  values,
  disabled,
  currentValue
}) => {
  const classes = useStyles()

  return (
    <>
      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>

      <MuiSelect
        native
        onChange={onChange}
        value={currentValue}
        disabled={disabled}
        disableUnderline
        variant="filled"
        className={classes.select}
      >
        {values.map((value, index) => (
          <option key={`Select_Item_${index}`} value={value.value}>
            {value.label}
          </option>
        ))}
      </MuiSelect>
    </>
  )
}

const Select: React.FC<Props> = props => {
  const isMobile = useBreakpoint("md")

  return isMobile ? <SelectListNative {...props} /> : <SelectList {...props} />
}

const useStyles = makeStyles(({ spacing }) => ({
  select: {
    width: "100%",
    marginBottom: spacing(3),
    "& > div > div": {
      display: "flex"
    }
  },
  label: {
    fontWeight: "normal",
    marginBottom: spacing(1)
  }
}))

export default Select
