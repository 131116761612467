import { extractPath } from "@starrepublic/epi/utils/location"
import ErrorMessage from "common/ErrorMessage"
import { browserHistory } from "common/Root"
import PageSpinner from "common/Spinners/PageSpinner"
import useStore from "global-hook-store"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { RouteComponentProps } from "react-router-dom"
import authStore from "stores/authStore"
import marketStore from "stores/marketStore"
import pageStore from "stores/pageStore"
import siteSettingsStore from "stores/siteSettingsStore"

import ApplicationInsights from "../common/ApplicationInsights"
import PageResolver from "./PageResolver"

type Props = RouteComponentProps

const setDixaPage = (path: string) => {
  if (window._dixa) {
    window._dixa("api.setUserUrl", path)
  }
}

const PageContainer: React.FC<Props> = ({ location, history }) => {
  const { state, actions } = useStore(pageStore)
  const {
    actions: { logout }
  } = useStore(authStore)

  const {
    state: { defaultMarket }
  } = useStore(marketStore)

  const {
    state: { links }
  } = useStore(siteSettingsStore)

  const loginLink = (links.login && links.login.href) || ""

  const market =
    defaultMarket && defaultMarket.selectedLanguage
      ? defaultMarket.selectedLanguage
      : "en"

  const extractedPath = extractPath(location)

  const { IS_BOT } = window

  const {
    page: { loading, data: page, error },
    fetchLocation
  } = state

  useEffect(() => {
    if (fetchLocation) {
      if (history.action === "PUSH") {
        actions.setScrollBackToPosition(window.scrollY)
      }

      if (!window["ignoreApiRequest"]) {
        actions.fetch({ id: extractedPath })
      }

      setDixaPage(window.location.href)
      window["ignoreApiRequest"] = false
    } else {
      actions.setFetchLocation(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractedPath])

  useEffect(() => {
    if (!loading) {
      if (
        page !== undefined &&
        !isNaN(parseInt(extractedPath.replace(/\//g, "").substr(2), 10))
      ) {
        actions.setFetchLocation(false).then(() => {
          if (page) {
            browserHistory.replace(page?._route)
          }
        })
      }

      if (IS_BOT && page !== undefined && page !== null) {
        clearPreRenderStatusCode()
        setPreRenderReady()
      }

      actions.setAutoscroll(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [`${page && page._id}_${loading}`])

  useEffect(() => {
    const loginStateListener = (e: StorageEvent) => {
      // If there isn't a token value, logout browser tab.
      if (e.key === "token" && e.oldValue && !e.newValue) {
        logout()
        window.location.href = `/${market}`
      }
      // If there is a token value, reload window to login browser tab.
      if (e.key === "token" && !e.oldValue && e.newValue) {
        window.location.reload()
      }
    }
    window.addEventListener("storage", loginStateListener)
    return () => {
      window.removeEventListener("storage", loginStateListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!page) {
    return <PageSpinner fetching={loading} />
  }

  if (error) {
    if (IS_BOT) {
      clearPreRenderStatusCode()
      setPreRenderStatusCode(error)
      setPreRenderReady()
    }
    if (error === "REDIRECT") {
      window.location.href = `${loginLink}?ReturnUrl=${extractedPath}`
    }
    return <ErrorMessage error={error.toString()} />
  }

  if (!page) {
    return null
  }

  return (
    <>
      {page && <Helmet title={page.siteTitle} />}

      <PageSpinner fetching={loading} />

      <PageResolver
        autoScroll={state.autoScroll}
        scrollBackToPosition={state.scrollBackToPosition}
        fetchContent={(id, useCache) => actions.fetch({ id, useCache })}
        content={page}
      />

      <ApplicationInsights path={extractedPath} />
    </>
  )
}

function setPreRenderStatusCode(error: string | Record<string, unknown>) {
  let statusCode = 0

  if (error === "NETWORK_ERROR") {
    statusCode = 500
  }
  if (error === "CONTENT_NOT_FOUND") {
    statusCode = 404
  }

  if (statusCode > 0) {
    const meta = document.createElement("meta")
    meta.name = "prerender-status-code"
    meta.setAttribute("content", `${statusCode}`)
    document.getElementsByTagName("head")[0].appendChild(meta)
  }
}

function setPreRenderReady() {
  setTimeout(() => {
    window.prerenderReady = true
  }, 500)
}

function clearPreRenderStatusCode() {
  const existing = document.querySelector("meta[name='prerender-status-code']")

  if (existing) {
    document.getElementsByTagName("head")[0].removeChild(existing)
  }
}

export default PageContainer
