import { DynamicPage, PageInfo } from "@starrepublic/epi/types/cms/content"
import { browserHistory } from "common/Root"
import useStore from "global-hook-store"
import { DataLayerPushEvent } from "models/Analytics"
import Cart, { LineItem } from "models/Cart"
import { OrderConfirmationDetails } from "models/Checkout"
import { CardProduct } from "models/Products"
import Variant from "models/Variant"
import { WebProductContent } from "pages/WebVariant/types"
import qs from "qs"
import React, { useEffect, useMemo, useState } from "react"
import { RouteComponentProps, withRouter } from "react-router"
import authStore from "stores/authStore"
import cartStore from "stores/cartStore"
import checkoutStore from "stores/checkoutStore"
import marketStore from "stores/marketStore"
import pageStore from "stores/pageStore"
import productStore from "stores/productStore"

window.dataLayer = window.dataLayer || []

const quantityCache = {}
const { EPI_CURRENT_CURRENCY } = window

export const pushToDataLayer = (data: DataLayerPushEvent) => {
  try {
    if (window.ENVIRONMENT !== "Production") console.log(data)
    window.dataLayer.push(data)
  } catch (error) {
    console.log(error)
  }
}

export const pushGaEvent = (event: {
  category: string
  action: string
  label?: string
  value?: number
}) => {
  pushToDataLayer({
    event: "ga_event",
    ga_event: {
      label: "",
      value: 1,
      ...event
    }
  })
}

export const pushNewsletterAction = (
  type: "email" | "sms",
  action: "Subscribe" | "Unsubscribe",
  label?: string
) => {
  pushGaEvent({
    category: type === "email" ? "Email Newsletter" : "SMS Newsletter",
    action,
    label: label ? label : ""
  })
}

const pageTypeTranslations = {
  startpage: "home page",
  webnode: "category page",
  webvariant: "product page",
  allproductspage: "all products page",
  selectedproductspage: "selected products page",
  standardpage: "standard page",
  articlepage: "article page",
  culturespecificarticlepage: "article page",
  checkoutpage: "checkout page",
  orderconfirmationpage: "order confirmation page",
  rapunzelerrorpage: "error page",
  allstorespage: "shops and salons page",
  storepage: "salon page",
  loginpage: "user login page",
  passwordresetpage: "password reset page",
  myprofilepage: "profile page",
  edicustomerinfopage: "customer info page",
  orderhistorypage: "order history page",
  standardb2bpage: "b2b user page",
  rapunzelsettingspage: "user setttings page",
  privacypolicypage: "privacy policy page"
}

const virtualPageViewParameters = (
  page: DynamicPage | null,
  isLoggedIn: boolean,
  parentItem?: WebProductContent | null | undefined,
  item?: Variant
) => {
  const pageType = page?._type.toLowerCase()
  const pageTranslated =
    pageType && pageTypeTranslations[pageType]
      ? pageTypeTranslations[pageType]
      : "unknown page"

  const epiPageName =
    page?.title ||
    `${parentItem?.productName} ${
      parentItem?.productName2 || "product name 2 unavailable"
    } ${item?.displayName2 || "variant info unavailable"}`?.replace(",", ".") ||
    "epi page name unavailable"

  return {
    event: "virtual_page_view",
    page_path: page?._route,
    page_title: page?.siteTitle?.toLowerCase(),
    epi_page_name: epiPageName?.toLowerCase(),
    epi_page_type: page?._type?.toLowerCase(),
    content_group: pageTranslated,
    page_location: window.location.href,
    user_authenticated: isLoggedIn,
    shop_timestamp: new Date().getTime(),
    language: page?._lang
  }
}

const clearEcommerce = () => {
  pushToDataLayer({ ecommerce: null })
}

export const trackVirtualPageView = (
  page: DynamicPage | null,
  isLoggedIn: boolean,
  parentItem?: WebProductContent | null | undefined,
  item?: Variant
) => {
  pushToDataLayer(virtualPageViewParameters(page, isLoggedIn, parentItem, item))
}

export const trackViewItemList = (
  items: CardProduct[] | undefined,
  categoryInfo: DynamicPage | null
) => {
  const categoryLevels = categoryInfo?._route
    .split("/")
    .filter(p => p !== "")
    .map(category => category.replace(/-/g, " "))

  const itemCategories = {}

  if (categoryLevels) {
    for (let index = 0; index <= 4; index++) {
      const key = index === 0 ? "item_category" : `item_category${index + 1}`
      itemCategories[key] = categoryLevels[index + 1]
        ?.toLowerCase()
        .replace(/ +(?= )/g, "")
    }
  }

  clearEcommerce()

  pushToDataLayer({
    event: "view_item_list",
    event_category: "ecommerce",
    event_action: "view item list",
    event_status: "success",
    event_message: "success - an item list was pressented for the user",
    ecommerce: {
      item_list_id: categoryInfo?._id.toString(),
      item_list_name: categoryInfo?.title?.toLowerCase(),
      market: marketStore.state.defaultMarket?.marketName.toLocaleLowerCase(),
      currency: marketStore.state.defaultMarket?.defaultCurrency,
      items: items?.slice(0, 200).map((item, index) => ({
        item_id: item?.productCode?.replace(/\D/g, ""),
        item_name: `${item.displayName} ${
          item.displayName2 || "≈ product name 2 unavailable"
        } » ${item.variantName || "variant info unavailable"}`
          .replace(",", ".")
          .toLowerCase(),
        discount:
          item.priceData.originalPrice != 0
            ? parseFloat(
                (item.priceData.originalPrice - item.priceData.price).toFixed(2)
              )
            : undefined,
        index: index + 1,
        item_brand: undefined,
        ...itemCategories,
        item_variant: item.code.replace(",", "."),
        price: item.priceData.price
      }))
    }
  })
}

export const trackSelectItem = (
  item: CardProduct,
  index: number,
  categoryInfo: DynamicPage | null
) => {
  const categoryLevels = categoryInfo?._route
    .split("/")
    .filter(p => p !== "")
    .map(category => category.replace(/-/g, " "))

  const itemCategories = {}

  if (categoryLevels) {
    for (let index = 0; index <= 4; index++) {
      const key = index === 0 ? "item_category" : `item_category${index + 1}`
      itemCategories[key] = categoryLevels[index + 1]
        ?.toLowerCase()
        .replace(/ +(?= )/g, "")
    }
  }

  const itemName = `${item.displayName} ${
    item.displayName2 || "≈ product name 2 unavailable"
  } » ${item.variantName || "variant info unavailable"}`

  clearEcommerce()

  pushToDataLayer({
    event: "select_item",
    event_category: "ecommerce",
    event_action: "item list click",
    event_status: "success",
    event_message: "success - an item in a list was followed to pdp page",
    ecommerce: {
      item_list_id: categoryInfo?._id.toString(),
      item_list_name: categoryInfo?.title.toLowerCase(),
      currency: marketStore.state.defaultMarket?.defaultCurrency,
      value: item.priceData.price || item.priceData.originalPrice,
      market: marketStore.state.defaultMarket?.marketName.toLocaleLowerCase(),
      items: [
        {
          item_id: item.productCode?.replace(/\D/g, ""),
          item_name: itemName.replace(",", ".").toLowerCase(),

          discount:
            item.priceData.price != null && item.priceData.originalPrice > 0
              ? parseFloat(
                  (item.priceData.originalPrice - item.priceData.price).toFixed(
                    2
                  )
                )
              : undefined,
          index: index + 1,
          item_brand: undefined,

          ...itemCategories,
          item_variant: item.code.replace(",", "."),
          price: item.priceData.price || item.priceData.originalPrice
        }
      ]
    }
  })
}

export const trackViewItem = (
  item: Variant,
  parentItem: WebProductContent | null | undefined,
  parentPageInfo: PageInfo | null | undefined
) => {
  const itemCategories = {}

  if (parentPageInfo?._parents) {
    for (let index = 0; index <= 4; index++) {
      const key = index === 0 ? "item_category" : `item_category${index + 1}`
      itemCategories[key] =
        parentPageInfo._parents[index + 1]?.title.toLowerCase()
    }
  }

  clearEcommerce()

  const itemName = `${parentItem?.productName} ${
    parentItem?.productName2 || "≈ product name 2 unavailable"
  } » ${item.displayName2 || "variant info unavailable"}`

  pushToDataLayer({
    event: "view_item",
    event_category: "ecommerce",
    event_action: "view item",
    event_status: "success",
    event_message: "success - an item detail page was visited",
    ecommerce: {
      currency: item._price?.currencyCode,
      value: item?._discount?.discountedPrice?.amount || item._price?.amount,
      market: marketStore.state.defaultMarket?.marketName.toLocaleLowerCase(),
      items: [
        {
          item_id: parentItem?.code.replace(/\D/g, "").replace(",", "."),
          item_name: itemName.replace(",", ".").toLowerCase(),
          discount: item?._discount
            ? parseFloat(
                (
                  item._price?.amount - item?._discount?.discountedPrice?.amount
                ).toFixed(2)
              )
            : undefined,
          item_brand: undefined,
          ...itemCategories,
          item_variant: item.code.replace(",", "."),
          price: item?._discount?.discountedPrice?.amount || item._price?.amount
        }
      ]
    }
  })
}

export const trackAddToCart = ({
  item,
  quantity,
  orderGroupId
}: {
  item: LineItem
  quantity: number
  orderGroupId: Cart["orderGroupId"] | undefined
}) => {
  const itemName = `${item.properties.productName} ${
    item.properties.productName2 || "≈ product name 2 unavailable"
  } » ${item.properties.displayName2 || "variant info unavailable"}`
  const singleItemPrice =
    item.discountedPrice / item.quantity || item.placedPrice

  const itemCategories = {}

  if (item.properties.ancestors) {
    for (let index = 0; index <= 4; index++) {
      const key = index === 0 ? "item_category" : `item_category${index + 1}`
      itemCategories[key] = item.properties.ancestors[index]?.toLowerCase()
    }
  }

  clearEcommerce()

  pushToDataLayer({
    event: "add_to_cart",
    event_category: "ecommerce",
    event_action: "add to cart",
    event_status: "success",
    event_message: "success - item(s) added to cart successfully",
    ecommerce: {
      currency: marketStore.state.defaultMarket?.defaultCurrency,
      value: singleItemPrice * quantity,
      market: marketStore.state.defaultMarket?.marketName.toLocaleLowerCase(),
      cart_ref: orderGroupId?.toString(),
      items: [
        {
          item_id: item.properties.productCode.replace(/\D/g, ""),
          item_name: itemName.replace(",", ".").toLowerCase(),
          discount:
            (item.placedPrice - item.discountedPrice / item.quantity) *
              quantity || undefined,
          item_brand: undefined,
          ...itemCategories,
          item_variant: item.code.replace(",", "."),
          price: singleItemPrice,
          quantity
        }
      ]
    }
  })
}

export const trackRemoveFromCart = ({
  item,
  quantity,
  orderGroupId,
  isClearCartEvent
}: {
  item: LineItem
  quantity?: number
  orderGroupId: Cart["orderGroupId"] | undefined
  isClearCartEvent?: boolean
}) => {
  const itemName = `${item.properties.productName} ${
    item.properties.productName2 || "≈ product name 2 unavailable"
  } » ${item.properties.displayName2 || "variant info unavailable"}`
  const singleItemPrice =
    item.discountedPrice / item.quantity || item.placedPrice

  const itemCategories = {}

  if (item.properties.ancestors) {
    for (let index = 0; index <= 4; index++) {
      const key = index === 0 ? "item_category" : `item_category${index + 1}`
      itemCategories[key] = item.properties.ancestors[index]?.toLowerCase()
    }
  }

  clearEcommerce()

  if (isClearCartEvent && orderGroupId) {
    trackClearCart(orderGroupId)
  }

  pushToDataLayer({
    event: "remove_from_cart",
    event_category: "ecommerce",
    event_action: "remove from cart",
    event_status: "success",
    event_message: "success - item(s) removed from cart successfully",
    ecommerce: {
      currency: marketStore.state.defaultMarket?.defaultCurrency,
      value: singleItemPrice * (quantity || item.quantity),
      market: marketStore.state.defaultMarket?.marketName.toLocaleLowerCase(),
      cart_ref: orderGroupId?.toString(),
      items: [
        {
          item_id: item.properties.productCode.replace(/\D/g, ""),
          item_name: itemName.replace(",", ".").toLowerCase(),
          discount: quantity
            ? (item.placedPrice - item.discountedPrice / item.quantity) *
              quantity
            : item.extendedPlacedPrice - item.discountedPrice || undefined,
          item_brand: undefined,
          ...itemCategories,
          item_variant: item.code.replace(",", "."),
          price: singleItemPrice,
          quantity: quantity || item.quantity
        }
      ]
    }
  })
}

const trackClearCart = (cartRef: Cart["orderGroupId"]) => {
  pushToDataLayer({
    event: "empty_cart",
    cart_ref: cartRef.toString()
  })
}

const trackViewCart = (cart: Cart) => {
  clearEcommerce()

  pushToDataLayer({
    event: "view_cart",
    event_category: "ecommerce",
    event_action: "view cart",
    event_status: "success",
    event_message: "success - the cart was viewed",
    ecommerce: {
      currency: cart?.currency,
      value: cart?.total - cart?.shippingTotal,
      market: cart?.marketName.toLocaleLowerCase(),
      cart_ref: cart?.orderGroupId?.toString(),
      coupon: cart?.couponCodes[0]
        ? cart?.couponCodes[0].toUpperCase()
        : undefined,
      items: cart?.lineItems.map((item, index) => {
        const itemCategories = {}
        if (item.properties.ancestors) {
          for (let idx = 0; idx <= 4; idx++) {
            const key = idx === 0 ? "item_category" : `item_category${idx + 1}`
            itemCategories[key] = item.properties.ancestors[idx]?.toLowerCase()
          }
        }
        return {
          item_id: item.properties.productCode.replace(/\D/g, ""),
          item_name: `${item.properties.productName} ${
            item.properties.productName2 || "≈ product name 2 unavailable"
          } » ${item.properties.displayName2 || "variant info unavailable"}`
            .replace(",", ".")
            .toLowerCase(),
          discount:
            item.extendedPlacedPrice - item.discountedPrice || undefined,
          item_brand: undefined,
          ...itemCategories,
          item_variant: item.code.replace(",", "."),
          index: index + 1,
          price: item.discountedPrice / item.quantity || item.placedPrice,
          quantity: item.quantity
        }
      })
    }
  })
}

const trackVoyadoContactId = (contactId: string) => {
  pushToDataLayer({
    event: "member_info_update",
    MemberInfo: { engageContactId: contactId }
  })
}

export const trackBeginCheckout = (cart: Cart) => {
  clearEcommerce()

  if (cart.properties?.engageContactId) {
    trackVoyadoContactId(cart.properties?.engageContactId)
  }

  pushToDataLayer({
    event: "begin_checkout",
    event_category: "ecommerce",
    event_action: "begin checkout",
    event_status: "success",
    event_message: "success - checkout started",
    ecommerce: {
      currency: cart?.currency,
      value: cart?.total - cart?.shippingTotal,
      coupon: cart?.couponCodes[0]
        ? cart?.couponCodes[0].toUpperCase()
        : undefined,
      market: cart?.marketName.toLocaleLowerCase(),
      items: cart?.lineItems.map((item, index) => {
        const itemCategories = {}
        if (item.properties.ancestors) {
          for (let index = 0; index <= 4; index++) {
            const key =
              index === 0 ? "item_category" : `item_category${index + 1}`
            itemCategories[key] =
              item.properties.ancestors[index]?.toLowerCase()
          }
        }
        return {
          item_id: item.properties.productCode.replace(/\D/g, ""),
          item_name: `${item.properties.productName} ${
            item.properties.productName2 || "≈ product name 2 unavailable"
          } » ${item.properties.displayName2 || "variant info unavailable"}`
            .replace(",", ".")
            .toLowerCase(),
          discount:
            item.extendedPlacedPrice - item.discountedPrice || undefined,
          item_brand: undefined,
          ...itemCategories,
          item_variant: item.code.replace(",", "."),
          index: index + 1,
          price: item.discountedPrice / item.quantity || item.placedPrice,
          quantity: item.quantity
        }
      })
    }
  })
}

export const trackUpsellToCart = (
  upsellItem: LineItem,
  addedToBasket: boolean
) => {
  const itemName = `${upsellItem.productName} ${
    upsellItem.productName2 || "≈ product name 2 unavailable"
  } » ${upsellItem.displayName2 || "variant info unavailable"}`

  clearEcommerce()

  pushToDataLayer({
    event: !addedToBasket ? "add_to_cart" : "remove_from_cart",
    event_name: !addedToBasket ? "upsell_to_cart" : "upsell_remove_from_cart",
    event_category: "ecommerce",
    event_action: !addedToBasket ? "upsell to cart" : "upsell remove from cart",
    event_status: "success",
    event_message: !addedToBasket
      ? "success - an item from upsell added to cart successfully"
      : "success - an item from upsell removed from cart successfully",
    ecommerce: {
      currency: marketStore.state.defaultMarket?.defaultCurrency,
      value: upsellItem.priceData.price,
      market: marketStore.state.defaultMarket?.marketName.toLocaleLowerCase(),
      items: [
        {
          item_id: upsellItem.productCode.replace(/\D/g, ""),
          item_name: itemName.replace(",", ".").toLowerCase(),
          discount: undefined,
          item_brand: undefined,
          item_category: pageStore.state.page.data?._type?.toLowerCase(),
          item_category2: undefined,
          item_category3: undefined,
          item_category4: undefined,
          item_category5: undefined,
          item_variant: upsellItem.code.replace(",", "."),
          price: upsellItem.priceData.price,
          quantity: 1
        }
      ]
    }
  })
}

const trackTrustPilotService = ({
  id,
  firstName,
  decryptedEmail
}: Pick<OrderConfirmationDetails, "id" | "firstName" | "decryptedEmail">) => {
  if (!decryptedEmail || !firstName) {
    return
  }

  pushToDataLayer({
    event: "trustpilot_service",
    trustpilot_data: {
      recipientEmail: decryptedEmail,
      recipientName: firstName,
      referenceId: id,
      source: "InvitationScript"
    }
  })
}

export const trackPurchase = (orderConfirmation: OrderConfirmationDetails) => {
  clearEcommerce()

  if (orderConfirmation.engageContactId) {
    trackVoyadoContactId(orderConfirmation.engageContactId)
  }

  if (orderConfirmation.orderGroupId) {
    trackClearCart(orderConfirmation.orderGroupId)
  }

  trackTrustPilotService({
    id: orderConfirmation.id,
    firstName: orderConfirmation.firstName,
    decryptedEmail: orderConfirmation.decryptedEmail
  })

  pushToDataLayer({
    event: "purchase",
    event_category: "ecommerce",
    event_action: "purchase",
    event_status: "success",
    event_message: "success - a purchase was completed",
    ecommerce: {
      currency: orderConfirmation.currencyCode,
      value: orderConfirmation.revenue,
      value_excluding_tax: orderConfirmation.netRevenue,
      coupon:
        orderConfirmation?.coupon !== ""
          ? orderConfirmation?.coupon?.toUpperCase()
          : undefined,
      market: marketStore.state.defaultMarket?.marketName.toLocaleLowerCase(),
      shipping: orderConfirmation.shipping,
      tax: orderConfirmation.tax,
      transaction_id: orderConfirmation.id,
      hashed_email: orderConfirmation.email,
      hashed_phone: orderConfirmation.phone,
      cart_ref: orderConfirmation.orderGroupId.toString(),
      items: orderConfirmation.products.map((item, index) => {
        const itemCategories = {}
        if (item.category) {
          for (let index = 0; index <= 4; index++) {
            const key =
              index === 0 ? "item_category" : `item_category${index + 1}`
            itemCategories[key] = item.category[index]?.toLowerCase()
          }
        }
        return {
          item_id: item.parentCode.replace(/\D/g, ""),
          item_name: `${item.variant} ${
            item.productName2 || "≈ product name 2 unavailable"
          } » ${item.displayName2 || "variant info unavailable"}`
            .replace(",", ".")
            .toLowerCase(),
          affiliation: orderConfirmation.affiliation,
          discount: undefined,
          item_brand: undefined,
          ...itemCategories,
          item_variant: item.id.replace(",", "."),
          index: index + 1,
          price: item.price,
          quantity: item.quantity,
          productUrl: item.productUrl,
          imageUrl: item.imageUrl || ""
        }
      })
    }
  })
}

const GoogleTagManagerTracking: React.FC<RouteComponentProps> = ({
  location
}) => {
  const {
    state: {
      page: { data: page }
    }
  } = useStore(pageStore)

  const {
    state: { defaultMarket }
  } = useStore(marketStore)

  const {
    state: { isLoggedIn }
  } = useStore(authStore)

  const {
    state: {
      lastChangedItems,
      carts: { data: carts }
    }
  } = useStore(cartStore)

  const {
    state: { productPage }
  } = useStore(productStore)

  const {
    state: { orderConfirmationDetails }
  } = useStore(checkoutStore)

  const [handledTransactions, setHandledTransactions] = useState<Array<string>>(
    []
  )

  const cartUniqueSummary = useMemo(() => {
    // Get's a string to summarize the cart, so we can track changes to the cart, like 1021354_10061,121_1,10061,122_1
    if (!carts?.default) {
      return "empty_cart"
    }

    const lineItemSummary = carts.default?.lineItems
      ?.map(item => `${item.code}_${item.quantity}`)
      .join(",")

    return `${carts.default?.orderGroupId}_${lineItemSummary}`
  }, [carts?.default])

  useEffect(() => {
    // cartUniqueSummary will change every time the cart content changes, trigger a view_cart
    if (carts?.default) {
      trackViewCart(carts?.default)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartUniqueSummary])

  useEffect(() => {
    if (page && page._type !== "WebVariant") {
      pushToDataLayer(virtualPageViewParameters(page, isLoggedIn))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (!lastChangedItems?.default) {
      return
    }

    const existingProduct = lastChangedItems.default.quantity > 0
    const product = lastChangedItems.default

    if (
      product.quantity === 0 ||
      (product.quantity > 0 && !quantityCache[product.code])
    ) {
      quantityCache[product.code] = product.quantity

      pushToDataLayer({
        event: (existingProduct && "addToCart") || "removeFromCart",
        ecommerce: {
          currencyCode: defaultMarket?.selectedCurrency,
          [(existingProduct && "add") || "remove"]: {
            products: [
              {
                name: product.properties.productName,
                id: product.code.replace(",", "."),
                price: product.extendedPrice,
                brand: "",
                category: product.properties.displayName2 || "",
                variant: product.properties.productName2 || "",
                quantity: product.quantity
              }
            ]
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastChangedItems.default?.quantity])

  useEffect(() => {
    if (page && EPI_CURRENT_CURRENCY) {
      // Remarketing for homepage
      if (page._type === "StartPage") {
        pushToDataLayer({
          event: "remarketingTriggered",
          google_tag_params: { ecomm_pagetype: "home" }
        })
      }
      if (page._type === "WebVariant" && productPage.content) {
        const price = page["_price"]?.amount || -1

        const category =
          (productPage.pageInfo &&
            productPage.pageInfo._parents &&
            productPage.pageInfo._parents[
              productPage.pageInfo._parents.length - 1
            ].title) ||
          ""

        pushToDataLayer({
          event: "remarketingTriggered",
          google_tag_params: {
            ecomm_pagetype: "product",
            ecomm_pcat: category, // Namn på produktkategori som produkter ligger i
            ecomm_prodid: (page.code || productPage.content.code || "").replace(
              ",",
              "."
            ), // Artikelnummer
            ecomm_pname: `${productPage.content.productName} ${productPage.content.productName2} ${page["displayName2"]}`,
            ecomm_pvalue: price, // Styckpris i lokal valuta inkl. moms.
            ecomm_totalvalue: price // Pris för orderraden, i detta exempel är det två artiklar av denna produkt
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page?._id, defaultMarket, productPage.content])

  // User goes to checkout
  useEffect(() => {
    if (
      EPI_CURRENT_CURRENCY &&
      page?._type === "CheckoutPage" &&
      carts?.default?.lineItems
    ) {
      pushToDataLayer({
        event: "remarketingTriggered",
        google_tag_params: {
          ecomm_pagetype: "cart",
          ecomm_pcat: carts.default.lineItems
            .map(x => x.displayName)
            .join(", "),
          ecomm_prodid: carts.default.lineItems
            .map(x => x.code.replace(",", "."))
            .join(", "),
          ecomm_pname: carts.default.lineItems
            .map(
              x => `${x.properties.productName} ${x.properties.productName2}`
            )
            .join(", "),
          ecomm_pvalue: carts.default.lineItems
            .map(x => x.extendedPrice)
            .join(", "),
          ecomm_totalvalue: carts.default.total,
          ecomm_cartStep: 1,
          ecomm_cartStepName: "checkout",
          ecomm_cartTypeName: "regular"
        }
      })

      pushToDataLayer({
        event: "checkout",
        ecommerce: {
          currencyCode: defaultMarket?.selectedCurrency,
          value: carts.default.total,
          checkout: {
            actionField: {
              step: "checkout"
            },
            products: [
              carts.default.lineItems.map(item => ({
                name: item.properties.productName,
                id: item.code.replace(",", "."),
                category: item.properties.displayName2 || "",
                variant: item.properties.productName2 || "",
                price: item.extendedPrice,
                quantity: item.quantity
              }))
            ]
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page?._id, defaultMarket, carts?.default])

  // User completes the purchase
  useEffect(() => {
    const parsedQuery = qs.parse(location.search.substring(1))

    if (
      page?._type === "OrderConfirmationPage" &&
      orderConfirmationDetails?.id
    ) {
      if (handledTransactions.includes(orderConfirmationDetails.id)) {
        console.log(
          "Already handled order with ID",
          orderConfirmationDetails.id
        )
        return
      }
      if (parsedQuery["reported"]) {
        console.log(
          "Already handled order with ID (page reload)",
          orderConfirmationDetails.id
        )
        return
      }

      // Save handled transaction in cases where it is hit multiple times in one page load
      setHandledTransactions([
        ...handledTransactions,
        orderConfirmationDetails.id
      ])

      pushToDataLayer({
        event: "remarketingTriggered",
        google_tag_params: {
          ecomm_pagetype: "purchase",
          ecomm_pcat: orderConfirmationDetails.products
            .map(x => x.category)
            .join(", "),
          ecomm_prodid: orderConfirmationDetails.products
            .map(x => x.id)
            .join(", "),
          ecomm_pname: orderConfirmationDetails.products
            .map(x => x.name)
            .join(", "),
          ecomm_pvalue: orderConfirmationDetails.products
            .map(x => x.price)
            .join(", "),
          ecomm_totalvalue: orderConfirmationDetails.revenue,
          ecomm_cartStep: 2,
          ecomm_cartStepName: "purchase",
          ecomm_cartTypeName: "regular" // använd regular eftersom det endast är en kassa i nya uppsättningen(?). Tidigare hade vi kco & regular
        }
      })

      pushToDataLayer({
        event: "transaction",
        ecommerce: {
          currencyCode: orderConfirmationDetails.currencyCode,
          purchase: {
            actionField: {
              id: orderConfirmationDetails.id,
              email: orderConfirmationDetails.email,
              affiliation: orderConfirmationDetails.affiliation,
              revenue: orderConfirmationDetails.revenue,
              tax: orderConfirmationDetails.tax,
              shipping: orderConfirmationDetails.shipping,
              coupon: orderConfirmationDetails.coupon,
              netRevenue: orderConfirmationDetails.netRevenue,
              action: "purchase"
            },
            products: [
              ...orderConfirmationDetails.products?.map(item => ({
                name: item.variant,
                id: item.id,
                price: item.price,
                brand: item.brand,
                category: item.displayName2 || "",
                variant: item.productName2 || "",
                quantity: item.quantity
              }))
            ]
          }
        }
      })

      // Set query paramater reported to "true" to prevent transaction push if browser reloads page
      browserHistory.replace({
        pathname: browserHistory.location.pathname,
        search: qs.stringify(
          { ...parsedQuery, reported: "true" },
          { indices: false }
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page?._id, defaultMarket && orderConfirmationDetails?.id])

  return null
}

export default withRouter(GoogleTagManagerTracking)
