import ButtonBase from "@material-ui/core/ButtonBase/ButtonBase"
import { makeStyles } from "@material-ui/core/styles"
import { clearSessionStorage } from "@starrepublic/epi/utils/clearSessionStorage"
import Typography from "common/Typography"
import useStore from "global-hook-store"
import useBreakpoint from "hooks/useBreakpoint"
import * as React from "react"
import { useTranslation } from "react-i18next"
import siteSettingsStore from "stores/siteSettingsStore"

type Props = {
  error: string
}

const ErrorMessage: React.FC<Props> = ({ error }) => {
  const {
    state: { links }
  } = useStore(siteSettingsStore)

  const isMobile = useBreakpoint("md")
  const { t } = useTranslation()
  const classes = useStyles()

  const link = (links.login && links.login.href) || ""

  const refreshAndClear = () => {
    clearSessionStorage()
    window.location.href = "/"
  }

  const redirectLogin = () => {
    clearSessionStorage()
    window.location.href = `${link}`
  }

  return (
    <div className={classes.root}>
      <Typography
        component={"h1"}
        variant={isMobile ? "headline3" : "headline1"}
      >
        {t("error_message.header")}
      </Typography>
      <Typography className={classes.information}>
        {error === "FORBIDDEN" ? t("error_message.information") : error}
      </Typography>
      {error && (
        <>
          <div className={classes.linkContainer}>
            <ButtonBase onClick={refreshAndClear} className={classes.item}>
              <Typography variant="link1" component="span" color="inherit">
                {t("error_message.reload")}
              </Typography>
            </ButtonBase>
            <ButtonBase onClick={redirectLogin} className={classes.item}>
              <Typography variant="link1" component="span" color="inherit">
                {t("error_message.login")}
              </Typography>
            </ButtonBase>
          </div>
        </>
      )}
    </div>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 30,
    width: 550,
    margin: "auto",
    [breakpoints.down("xs")]: {
      width: 390
    }
  },
  linkContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-evenly"
  },
  information: {
    wordWrap: "break-word",
    padding: `${spacing(3)}px 0 ${spacing(3)}px 0`
  },
  item: {
    padding: `${spacing(1)}px 0`,
    "& a": {
      color: palette.rapunzel.dark,
      display: "flex"
    }
  }
}))

export default ErrorMessage
