import { Location } from "history"

import config from "../config"
const QUERY_DELIMITER = "?"

export const extractPath = (location: Location<any>) =>
  location.pathname.split(QUERY_DELIMITER)[0]

export const isLocalHost = () =>
  window.location.hostname === "localhost" ||
  window.location.host === "127.0.0.1"

export const isDetached = () => config.runAsDetached === "true"

export const isNgrok = () => config.runInNgrok === "true"
