import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import { browserHistory } from "common/Root"
import Typography from "common/Typography"
import useStore from "global-hook-store"
import Cookies from "js-cookie"
import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import authStore from "stores/authStore"
import siteSettingsStore from "stores/siteSettingsStore"

import UserIcon from "../../icons/User"

type Props = {
  isMobile?: boolean
  className?: string
}

const UserDropDown: React.FC<Props> = ({ isMobile, className }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    state: {
      isLoggedIn,
      user: { data: user }
    },
    actions: { getToken }
  } = useStore(authStore)

  const {
    state: { links }
  } = useStore(siteSettingsStore)

  useEffect(() => {
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  const handleButtonClick = () => {
    const token = Cookies.get("ea_token")
    if (links.login?.href && (token === undefined || !isLoggedIn)) {
      window.location.href = links.login?.href
    } else if (links.profilePage?.href) {
      browserHistory.push(links.profilePage.href)
    }
  }

  const buttonRef = useRef<HTMLDivElement>(null)

  const userNameLabel = (
    <Typography
      variant="button2"
      color="textSecondary"
      className={classNames(classes.buttonText, {
        [classes.buttonTextLoggedIn]: isLoggedIn
      })}
    >
      {(user && `${user.companyName}`) || t("member.log_in")}
    </Typography>
  )
  return (
    <>
      <div
        ref={buttonRef}
        className={classNames(classes.triggerButtonWrapper, className)}
      >
        <IconButton
          color="inherit"
          className={classes.triggerButton}
          aria-label="Menu"
          aria-owns="simple-menu"
          aria-haspopup="true"
          onClick={handleButtonClick}
        >
          <UserIcon
            filled={isLoggedIn}
            className={classNames(classes.userIcon, {
              [classes.userIconLoggedIn]: isLoggedIn
            })}
          />

          {!isMobile && userNameLabel}
        </IconButton>
      </div>
    </>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints, palette, zIndex }) => ({
  triggerButtonWrapper: {
    display: "flex",
    [breakpoints.up("lg")]: {
      padding: spacing(0, 2)
    }
  },
  triggerButton: {
    "&:hover": {
      [breakpoints.up("lg")]: {
        backgroundColor: "transparent"
      }
    }
  },
  userIcon: {
    width: 20,
    height: 22,
    color: palette.rapunzel.black,
    [breakpoints.up("lg")]: {
      marginRight: spacing(1)
    }
  },
  userIconLoggedIn: {
    color: palette.rapunzel.white
  },
  popper: {
    zIndex: zIndex.drawer,
    top: `${spacing(6)}px !important`,
    maxWidth: spacing(20),
    [breakpoints.down("md")]: {
      top: `${spacing(8)}px !important`
    }
  },
  menuListRoot: {
    padding: 0
  },
  paper: {
    boxShadow: "0px 15px 30px rgba(0, 0, 0, 0.05)"
  },
  buttonText: {
    color: palette.rapunzel.black,
    [breakpoints.down("lg")]: {
      maxWidth: "10em",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    [breakpoints.down(360)]: {
      // iPhone 5
      maxWidth: "6em"
    }
  },
  buttonTextLoggedIn: {
    color: palette.rapunzel.white
  }
}))

export default UserDropDown
