import { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Flag: React.FunctionComponent<Props> = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path fill="#f0f0f0" d="M0 85.3h512v341.3H0z" />
    <g fill="#d80027">
      <path d="M0 85.3h170.7v341.3H0zM341.3 85.3H512v341.3H341.3zM288 279.7l32-16-16-8v-16l-32 16 16-32h-16l-16-24-16 24h-16l16 32-32-16v16l-16 8 32 16-8 16h32v24h16v-24h32z" />
    </g>
  </svg>
)

export default Flag
