import api from "@starrepublic/epi/api"
import { CartSummary, LineItemRequest } from "@starrepublic/epi/api/carts"
import checkoutAPI from "api/checkout"
import { asyncState, createStore } from "global-hook-store"
import Cart, { LineItem, LineItemProperties } from "models/Cart"

const cartTypes = {
  default: null
}

export type CartType = keyof typeof cartTypes

const cartStore = createStore(
  {
    carts: asyncState<Record<CartType, Cart | null>>({ ...cartTypes }),

    lastChangedItems: { ...cartTypes } as Record<CartType, LineItem | null>,

    addingItem: false,

    updatingCart: false,

    _currentItem: asyncState<Cart>(),

    summary: { ...cartTypes } as Record<CartType, CartSummary | null>,

    itemCount: { ...cartTypes } as Record<CartType, number | null>,

    get total() {
      return (
        (this.carts.data.default && this.carts.data.default.totalString) ||
        this.summary.default?.totalString ||
        null
      )
    }
  },
  {
    load: async state => {
      const summeryInfo = await Promise.all(
        Object.keys(cartTypes).map(cartType => api.carts.summary(cartType))
      )

      const itemCount = {} as Record<CartType, number | null>

      const summary = Object.keys(cartTypes).reduce(
        (acc, cartType, i) => {
          itemCount[cartType] = summeryInfo[i].lineItemCount || null
          acc[cartType] = summeryInfo[i]
          return acc
        },
        { ...cartTypes }
      )

      return {
        ...state,
        summary,
        itemCount
      }
    },
    fetchCarts: async (state, payload: null, { asyncAction }) =>
      asyncAction(
        "carts",
        api.carts.getAll<LineItemProperties>().then(allCarts =>
          allCarts.reduce((acc, cart) => {
            acc[cart.name] = cart
            return acc
          }, {})
        )
      ),

    refreshCart: async (state, payload: null, { asyncAction }) =>
      asyncAction(
        "carts",
        checkoutAPI.refreshKlarnaCart().then(allCarts =>
          allCarts.reduce((acc, cart) => {
            acc[cart.name] = cart
            return acc
          }, {})
        )
      ),

    addItemByCode: (
      state,
      {
        cartType,
        code,
        quantity = 1,
        callback
      }: {
        cartType: CartType
        code: string
        quantity: number
        callback?: () => void
      }
    ) => {
      const cartCount = state.itemCount[cartType]

      cartStore.actions._createOrUpdateItem({
        cartType,
        code,
        quantity,
        api: api.carts.items.addByItemCode,
        addNew: true,
        callback
      })

      return {
        ...state,
        addingItem: true,
        updatingCart: true,
        itemCount: {
          ...state.itemCount,
          [cartType]: (cartCount || 0) + 1
        }
      }
    },

    reset: (_state, _payload: null, { reset }) => reset(),

    resetValidationIssues: _state => {
      const newState: any = cartStore.state

      return {
        ...newState,
        _currentItem: {
          validationIssues: null
        }
      }
    },

    updateItemQuantity: (
      state,
      {
        cartType,
        item,
        quantity = 1,
        callback
      }: {
        cartType: CartType
        item: LineItem
        quantity: number
        callback?: () => void
      }
    ) => {
      const cartCount = state.itemCount[cartType]

      cartStore.actions._createOrUpdateItem({
        cartType,
        code: item.code,
        quantity: item.quantity + quantity,
        api: api.carts.items.updateByItemCode,
        callback
      })

      return {
        ...state,
        lastChangedItems: {
          ...state.lastChangedItems,
          [cartType]: {
            ...item,
            quantity: item.quantity + quantity
          }
        },
        addingItem: false,
        updatingCart: true,
        itemCount: {
          ...state.itemCount,
          [cartType]: (cartCount || 0) + quantity
        }
      }
    },

    setCart: (
      state,
      { cartType, cart }: { cartType: CartType; cart: Cart }
    ) => ({
      ...state,
      carts: {
        ...state.carts,
        data: {
          ...state.carts.data,
          [cartType]: cart
        }
      }
    }),

    _createOrUpdateItem: async (
      state,
      {
        cartType,
        code,
        quantity,
        api,
        addNew = false,
        callback
      }: {
        cartType: CartType
        code: string
        quantity: number
        api: (
          cartType: CartType,
          code: string,
          requestModel: LineItemRequest
        ) => Promise<Cart>
        addNew?: boolean
        callback?: () => void
      },
      { asyncAction }
    ) => {
      const requestModel: LineItemRequest = {
        quantity
      }

      const { _currentItem } = await asyncAction(
        "_currentItem",
        api(cartType, code, requestModel)
      )

      let lastChangedItem: LineItem | null = null

      const totalCartCount =
        _currentItem.data?.lineItems.reduce((q, item) => {
          if (item.code === code && addNew) {
            lastChangedItem = item
          }
          return q + item.quantity
        }, 0) || 0

      const newState: any = cartStore.state

      if (callback) callback()

      return {
        ...newState,
        ...((lastChangedItem && {
          lastChangedItems: {
            [cartType]: lastChangedItem
          }
        }) ||
          {}),
        addingItem: false,
        updatingCart: false,
        _currentItem,
        itemCount: {
          ...state.itemCount,
          [cartType]: totalCartCount
        },

        carts: {
          ...newState.carts,
          data: {
            ...newState.carts.data,
            [cartType]: _currentItem.data
          }
        }
      }
    }
  }
)

cartStore.actions.load()

export default cartStore
