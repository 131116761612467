import "@brainhubeu/react-carousel/lib/style.css"

import type { CarouselProps } from "@brainhubeu/react-carousel"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import useStore from "global-hook-store"
import * as React from "react"
import { ComponentType, Suspense } from "react"
import siteSettingsStore from "stores/siteSettingsStore"

import PromotionItem from "./PromotionItem"

const Carousel = React.lazy<ComponentType<CarouselProps>>(
  () => import("@brainhubeu/react-carousel")
)

const Promotion: React.FunctionComponent = () => {
  const {
    state: { promotions, promotionBarBackgroundColor, promotionBarTextColor }
  } = useStore(siteSettingsStore)

  const classes = useStyles()

  const style = {
    backgroundColor: promotionBarBackgroundColor
  }
  return (
    (!!promotions.length && (
      <div className={classes.root} style={style}>
        <Suspense fallback={<Box width={1.0} height={40} />}>
          <Carousel autoPlay={5000} infinite slidesPerPage={1}>
            {promotions.map((promotion: any, index: number) => (
              <PromotionItem
                {...promotion}
                promotionBarTextColor={promotionBarTextColor}
                key={index}
              />
            ))}
          </Carousel>
        </Suspense>
      </div>
    )) ||
    null
  )
}

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    position: "relative",
    marginTop: "64px",
    marginBottom: "-68px",
    zIndex: 1000,
    [breakpoints.down("md")]: {
      marginTop: 0,
      marginBottom: 0
    }
  }
}))

export default Promotion
