import { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Flag: React.FunctionComponent<Props> = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path fill="#f0f0f0" d="M0 85.3h512v341.3H0z" />
    <path
      fill="#d80027"
      d="M288 85.3h-64V224H0v64h224v138.7h64V288h224v-64H288z"
    />
    <g fill="#0052b4">
      <path d="M393.8 315.4L512 381v-65.6zM311.7 315.4L512 426.7v-31.5l-143.7-79.8zM458.6 426.7L311.7 345v81.7z" />
    </g>
    <path fill="#f0f0f0" d="M311.7 315.4L512 426.7v-31.5l-143.7-79.8z" />
    <path fill="#d80027" d="M311.7 315.4L512 426.7v-31.5l-143.7-79.8z" />
    <g fill="#0052b4">
      <path d="M90.3 315.4L0 365.5v-50.1zM200.3 329.5v97.2H25.5z" />
    </g>
    <path fill="#d80027" d="M143.7 315.4L0 395.2v31.5l200.3-111.3z" />
    <g fill="#0052b4">
      <path d="M118.2 196.6L0 131v65.6zM200.3 196.6L0 85.3v31.5l143.7 79.8zM53.4 85.3L200.3 167V85.3z" />
    </g>
    <path fill="#f0f0f0" d="M200.3 196.6L0 85.3v31.5l143.7 79.8z" />
    <path fill="#d80027" d="M200.3 196.6L0 85.3v31.5l143.7 79.8z" />
    <g fill="#0052b4">
      <path d="M421.7 196.6l90.3-50.2v50.2zM311.7 182.5V85.3h174.8z" />
    </g>
    <path fill="#d80027" d="M368.3 196.6L512 116.8V85.3L311.7 196.6z" />
  </svg>
)

export default Flag
