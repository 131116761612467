import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import LinkButton from "common/LinkButton"
import useStore from "global-hook-store"
import * as React from "react"
import authStore from "stores/authStore"
import siteSettingsStore from "stores/siteSettingsStore"

type Props = {
  onLinkClick: (event: React.MouseEvent<HTMLElement>) => void
}

const TopHeaderLinks: React.FunctionComponent<Props> = ({ onLinkClick }) => {
  const {
    state: { menus }
  } = useStore(siteSettingsStore)

  const classes = useStyles()

  const {
    state: { isLoggedIn }
  } = useStore(authStore)

  const items = menus.top || []

  return (
    (items && (
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        {items.map((item: any, index: number) => (
          <li
            key={item._id}
            className={classNames(
              classes.listItem,
              index === items.length - 1 && classes.lastListItem
            )}
          >
            <LinkButton
              link={item.url}
              onClick={onLinkClick}
              variant="body2"
              className={classes.link}
              linkContentClassName={classNames(classes.linkText, {
                [classes.linkTextLoggedIn]: isLoggedIn
              })}
            >
              {item.title}
            </LinkButton>
          </li>
        ))}
      </div>
    )) ||
    null
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  listItem: {
    display: "flex",
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    "&:first-child": {
      paddingLeft: spacing(2)
    }
  },
  lastListItem: {
    paddingRight: spacing(1)
  },
  link: {
    "&> button": {
      height: "100%",
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  linkText: {
    color: "rgba(0, 0, 0, 0.54)",
    textDecoration: "none"
  },
  linkTextLoggedIn: {
    color: palette.rapunzel.white
  }
}))

export default TopHeaderLinks
