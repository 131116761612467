import { CatalogMeta } from "../commerce/models/catalog"
import { SimpleLink } from "../types/cms/general"
import request from "./request"

type Option = {
  displayName: string
  value: string
}

type FilterGroup = {
  displayName: string
  value: string
  options: Option[]
}

type CatalogContent<T> = T & {
  _meta: CatalogMeta
  _parents: SimpleLink[]
  _route: string
  children: any[]
  title: string
  filterGroups?: FilterGroup[]
  sortOptions?: Option[]
}

const catalogs = {
  getAll: <T>() => request.get<CatalogContent<T>[]>("/catalogs"),
  getByRoute: <T>(route: string) =>
    request.get<CatalogContent<T>>(`/catalogs/${route}`)
}

export default catalogs
