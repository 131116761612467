import {
  TypographyOptions,
  TypographyStyleOptions,
  Variant
} from "@material-ui/core/styles/createTypography"

const htmlFontSize = 16
const fontSize = 16

const browserDefaultFontSize = 16

export const pxToRem = (size: number) => {
  const ratio = fontSize / browserDefaultFontSize
  return `${(size / htmlFontSize) * ratio}rem`
}

export type CustomVariant =
  | "display1"
  | "display2"
  | "display3"
  | "display4"
  | "display5"
  | "display6"
  | "display7"
  | "display8"
  | "display9"
  | "display10"
  | "display11"
  | "headline1"
  | "headline2"
  | "headline3"
  | "headline4"
  | "headline5"
  | "headline6"
  | "headline7"
  | "subHeading"
  | "subText"
  | "subTextSmall"
  | "body1"
  | "body2"
  | "body3"
  | "body4"
  | "body5"
  | "body6"
  | "body7"
  | "linksListBig"
  | "linksListMedium"
  | "linksListSmall"
  | "popularCategoriesTitle"
  | "popularCategoriesTitleSmall"
  | "link1"
  | "link2"
  | "link3"
  | "link4"
  | "link5"
  | "button1"
  | "button2"
  | "lengthButton"
  | "label"
  | "labelSmall"
  | "labelBold"
  | "caption"
  | "overline"
  | "productTag"
  | "priceLarge"
  | "priceSmall"
  | "priceSmallMobile"
  | "discountPrice"
  | "discountPriceSmall"
  | "currency"
  | "mainNav"
  | "headlinePdp"
  | "pricePdpNew"
  | "smallTag"

const round = (value: number) => Math.round(value * 1e5) / 1e5

type VariantParams = {
  fontFamily: string
  size: number
  lineHeight: number
  fontStyle?: string
  letterSpacing?: number
  fontWeight?: number
  textTransform?: React.CSSProperties["textTransform"]
  textDecoration?: React.CSSProperties["textDecoration"]
  color?: string
  breakpoints?: any // Should be typed but it's not working
}

const buildVariant = ({
  fontFamily,
  size,
  lineHeight,
  fontStyle,
  letterSpacing,
  fontWeight,
  textTransform,
  textDecoration,
  color,
  breakpoints
}: VariantParams) => ({
  color,
  fontFamily,
  fontWeight,
  fontStyle,
  lineHeight: lineHeight / size,
  fontSize: pxToRem(size),
  ...breakpoints,
  ...(textTransform && { textTransform }),
  ...(textDecoration && { textDecoration }),
  ...(letterSpacing && { letterSpacing: `${round(letterSpacing / size)}em` })
})

type Fonts = {
  [key: string]: {
    fontFamily: string
    fontWeight?: number
  }
}

const fonts: Fonts = {
  circularPro: {
    fontFamily: "'Circular Pro','Helvetica Neue',Helvetica,sans-serif",
    fontWeight: 300
  },
  nib: {
    fontFamily: "'Nib','Times New Roman',Times,serif",
    fontWeight: 300
  }
}

export const customTypography: {
  [key in CustomVariant]: TypographyStyleOptions
} = {
  display1: buildVariant({
    ...fonts.nib,
    size: 40,
    lineHeight: 42,
    fontWeight: 300
  }),
  display2: buildVariant({
    ...fonts.nib,
    size: 32,
    lineHeight: 34,
    fontWeight: 300
  }),
  display3: buildVariant({
    ...fonts.nib,
    size: 28,
    lineHeight: 30,
    fontWeight: 500
  }),
  display4: buildVariant({
    ...fonts.nib,
    size: 22,
    lineHeight: 28,
    fontWeight: 300
  }),
  display5: buildVariant({
    ...fonts.nib,
    size: 16,
    lineHeight: 18,
    fontWeight: 300
  }),
  display6: buildVariant({
    ...fonts.nib,
    size: 14,
    lineHeight: 18,
    fontWeight: 300
  }),
  display7: buildVariant({
    ...fonts.nib,
    size: 22,
    lineHeight: 28,
    fontWeight: 500
  }),
  display8: buildVariant({
    ...fonts.circularPro,
    size: 16,
    lineHeight: 20,
    fontWeight: 500
  }),
  display9: buildVariant({
    ...fonts.nib,
    size: 40,
    lineHeight: 42,
    fontWeight: 300
  }),
  display10: buildVariant({
    ...fonts.nib,
    size: 32,
    lineHeight: 34,
    fontWeight: 300
  }),
  display11: buildVariant({
    ...fonts.circularPro,
    size: 16,
    lineHeight: 20,
    fontWeight: 900
  }),
  smallTag: buildVariant({
    ...fonts.circularPro,
    size: 12,
    lineHeight: 15,
    fontWeight: 300
  }),
  headlinePdp: buildVariant({
    ...fonts.circularPro,
    size: 16,
    lineHeight: 20,
    fontWeight: 500
  }),
  headline1: buildVariant({
    ...fonts.circularPro,
    size: 40,
    lineHeight: 51,
    fontWeight: 700
  }),
  headline2: buildVariant({
    ...fonts.circularPro,
    size: 32,
    lineHeight: 40,
    fontWeight: 700
  }),
  headline3: buildVariant({
    ...fonts.circularPro,
    size: 26,
    lineHeight: 33,
    fontWeight: 700
  }),
  headline4: buildVariant({
    ...fonts.circularPro,
    size: 22,
    lineHeight: 28,
    fontWeight: 700
  }),
  headline5: buildVariant({
    ...fonts.circularPro,
    size: 18,
    lineHeight: 23,
    fontWeight: 700
  }),
  headline6: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 18,
    fontWeight: 700,
    textTransform: "uppercase"
  }),
  headline7: buildVariant({
    ...fonts.circularPro,
    size: 20,
    lineHeight: 25,
    fontWeight: 700
  }),
  subHeading: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 18,
    fontWeight: 700
  }),
  subText: buildVariant({
    ...fonts.nib,
    size: 18,
    lineHeight: 21,
    fontWeight: 300,
    fontStyle: "italic"
  }),
  subTextSmall: buildVariant({
    ...fonts.nib,
    size: 16,
    lineHeight: 19,
    fontWeight: 300,
    fontStyle: "italic"
  }),
  body1: buildVariant({
    ...fonts.circularPro,
    size: 16,
    lineHeight: 24,
    fontWeight: 300
  }),
  body2: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 20,
    fontWeight: 300
  }),
  body3: buildVariant({
    ...fonts.circularPro,
    size: 12,
    lineHeight: 14,
    fontWeight: 300
  }),
  body4: buildVariant({
    ...fonts.circularPro,
    size: 19,
    lineHeight: 28,
    fontWeight: 700
  }),
  body5: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 18,
    fontWeight: 500
  }),
  body6: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 18,
    fontWeight: 300
  }),
  body7: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 18,
    fontWeight: 900
  }),
  linksListBig: buildVariant({
    ...fonts.circularPro,
    size: 18,
    lineHeight: 23,
    fontWeight: 500
  }),
  linksListMedium: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 18,
    fontWeight: 300,
    textTransform: "uppercase",
    letterSpacing: 1
  }),
  linksListSmall: buildVariant({
    ...fonts.circularPro,
    size: 16,
    lineHeight: 20,
    fontWeight: 400
  }),
  popularCategoriesTitle: buildVariant({
    ...fonts.circularPro,
    size: 18,
    lineHeight: 20,
    fontWeight: 400
  }),
  popularCategoriesTitleSmall: buildVariant({
    ...fonts.circularPro,
    size: 12,
    lineHeight: 14,
    fontWeight: 400
  }),
  button1: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 18,
    letterSpacing: 1,
    fontWeight: 500,
    textTransform: "uppercase"
  }),
  button2: buildVariant({
    ...fonts.circularPro,
    size: 12,
    lineHeight: 14,
    letterSpacing: 1,
    fontWeight: 300,
    textTransform: "uppercase"
  }),
  lengthButton: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 20,
    fontWeight: 700
  }),
  link1: buildVariant({
    ...fonts.circularPro,
    size: 16,
    lineHeight: 20,
    fontWeight: 300,
    textDecoration: "underline"
  }),
  link2: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 16,
    fontWeight: 300,
    textDecoration: "underline"
  }),
  link3: buildVariant({
    ...fonts.circularPro,
    size: 16,
    lineHeight: 20,
    fontWeight: 700
  }),
  link4: buildVariant({
    ...fonts.circularPro,
    size: 16,
    lineHeight: 20,
    fontWeight: 700,
    textDecoration: "underline"
  }),
  link5: buildVariant({
    ...fonts.circularPro,
    size: 12,
    lineHeight: 15,
    fontWeight: 300,
    textDecoration: "underline"
  }),
  label: buildVariant({
    ...fonts.circularPro,
    size: 12,
    lineHeight: 18,
    fontWeight: 300
  }),
  labelBold: buildVariant({
    ...fonts.circularPro,
    size: 12,
    lineHeight: 18,
    fontWeight: 500
  }),
  labelSmall: buildVariant({
    ...fonts.circularPro,
    size: 10,
    lineHeight: 16,
    fontWeight: 300
  }),
  caption: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 16,
    fontWeight: 300,
    textDecoration: "none"
  }),
  overline: buildVariant({
    ...fonts.circularPro,
    size: 12,
    lineHeight: 14,
    letterSpacing: 1,
    textTransform: "uppercase",
    fontWeight: 300
  }),
  productTag: buildVariant({
    ...fonts.circularPro,
    size: 16,
    lineHeight: 20,
    fontWeight: 500
  }),
  priceLarge: buildVariant({
    ...fonts.circularPro,
    size: 24,
    lineHeight: 30,
    fontWeight: 700
  }),
  priceSmall: buildVariant({
    ...fonts.circularPro,
    size: 16,
    lineHeight: 20,
    fontWeight: 300
  }),
  priceSmallMobile: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 18,
    fontWeight: 300
  }),
  pricePdpNew: buildVariant({
    ...fonts.circularPro,
    size: 18,
    lineHeight: 24,
    fontWeight: 500
  }),
  discountPrice: buildVariant({
    ...fonts.circularPro,
    size: 20,
    lineHeight: 25
  }),
  discountPriceSmall: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 18
  }),
  currency: buildVariant({
    ...fonts.circularPro,
    size: 14,
    lineHeight: 16,
    letterSpacing: 1,
    fontWeight: 300
  }),
  mainNav: buildVariant({
    ...fonts.circularPro,
    size: 14,
    letterSpacing: 1,
    lineHeight: 18
  })
}

export const customToMuiVariantMapping: { [key in CustomVariant]: Variant } = {
  display1: "h1",
  display2: "h1",
  display3: "h2",
  display4: "h3",
  display5: "h4",
  display6: "h5",
  display7: "h5",
  display8: "h3",
  display9: "h2",
  display10: "h2",
  display11: "h2",
  headline1: "h1",
  headline2: "h2",
  headline3: "h3",
  headline4: "h4",
  headline5: "h5",
  headline6: "h6",
  headline7: "h5",
  subHeading: "h5",
  subText: "h5",
  subTextSmall: "h5",
  body1: "body1",
  body2: "h2",
  body3: "h2",
  body4: "h2",
  body5: "h3",
  body6: "h2",
  body7: "h2",
  linksListBig: "h2",
  linksListMedium: "body2",
  linksListSmall: "body1",
  popularCategoriesTitle: "h2",
  popularCategoriesTitleSmall: "h2",
  button1: "h2",
  button2: "h2",
  lengthButton: "h2",
  link1: "h2",
  link2: "h2",
  link3: "h2",
  link4: "h2",
  link5: "h2",
  label: "h2",
  labelSmall: "h2",
  labelBold: "h2",
  caption: "caption",
  overline: "h2",
  productTag: "body1",
  priceLarge: "h2",
  priceSmall: "h4",
  priceSmallMobile: "h4",
  discountPrice: "h2",
  discountPriceSmall: "h2",
  currency: "h2",
  mainNav: "h1",
  headlinePdp: "h2",
  pricePdpNew: "h2",
  smallTag: "h5"
}

const muiToCustomVariantMapping: Partial<Record<Variant, CustomVariant>> = {
  h1: "headline1",
  h2: "headline2",
  h3: "headline3",
  h4: "headline4",
  h5: "headline5",
  h6: "headline6",
  body1: "body1",
  body2: "body2",
  button: "button1",
  caption: "link1",
  overline: "body1"
}

export const createMuiTypography = (): TypographyOptions => {
  const muiVariants = Object.keys(muiToCustomVariantMapping) as Variant[]
  const typographyStyles = muiVariants.reduce((acc, variant) => {
    const customVariant = muiToCustomVariantMapping[variant]
    const styles = customVariant ? customTypography[customVariant] : {}
    return { ...acc, [variant]: styles }
  }, {})

  return {
    ...typographyStyles,
    htmlFontSize,
    fontSize,
    fontFamily: fonts.circularPro.fontFamily
  }
}
