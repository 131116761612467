import { makeStyles } from "@material-ui/core/styles"
import MUIArrowForward from "@material-ui/icons/ArrowForward"
import React from "react"

const ArrowForward: React.StatelessComponent = () => {
  const classes = useStyles()

  return <MUIArrowForward className={classes.root} />
}

const useStyles = makeStyles(() => ({
  root: {
    height: "18px",
    width: "18px",
    marginLeft: "9px"
  }
}))

export default ArrowForward
