import Grid from "@material-ui/core/Grid"
import Slide from "@material-ui/core/Slide"
import { makeStyles } from "@material-ui/core/styles"
import RelativeLink from "@starrepublic/epi/cms/components/RelativeLink"
import { MenuItem } from "@starrepublic/epi/types/cms/menu"
import KlearInfluencerForm from "common/KlearInfluencerForm"
import Typography from "common/Typography"
import React, { Fragment, useState } from "react"

import MenuFooter from "./MenuFooter"
import MainMenuHeader from "./MenuHeader"
import MenuList from "./MenuList"

type Props = {
  catalogMenu: MenuItem[]
  topMenu: MenuItem[]
  closeDrawer: (isOpen: boolean) => void
}

type State = {
  parentItemStack: MenuItem[]
  menuStack: MenuItem[][]
  key: number
}

const MobileMenu: React.FC<Props> = ({ catalogMenu, topMenu, closeDrawer }) => {
  const [state, setState] = useState<State>({
    parentItemStack: [],
    menuStack: [],
    key: 0
  })

  const classes = useStyles()

  const { parentItemStack, key, menuStack } = state

  const goToSubMenu = (item: MenuItem) => {
    const subMenu = item.children
    setState({
      parentItemStack: [...parentItemStack, item],
      menuStack: [...menuStack, subMenu || []],
      key: 1 - key
    })
  }

  const goBack = () => {
    setState({
      menuStack: menuStack.slice(0, menuStack.length - 1),
      parentItemStack: parentItemStack.slice(0, parentItemStack.length - 1),
      key: 1 - key
    })
  }

  return (
    <div className={classes.root}>
      <MainMenuHeader
        hasBackBtn={!!menuStack.length}
        onBackButtonClick={goBack}
        onCloseButtonClick={closeDrawer}
      />
      <Typography className={classes.body} component="div" variant="link2">
        <Slide in direction="right" key={key}>
          <div>
            <MenuList
              component="nav"
              className={classes.navList}
              goToSubMenu={goToSubMenu}
              closeDrawer={closeDrawer}
              items={
                menuStack && menuStack.length
                  ? menuStack[menuStack.length - 1]
                  : catalogMenu
              }
              parentItem={parentItemStack[parentItemStack.length - 1]}
              listItemProps={{
                className: classes.navMenuItem
              }}
            />
            <Grid container className={classes.topMenuItemsContainer}>
              {topMenu.map(({ title, url }, i) => (
                <Fragment key={i}>
                  <Grid item xs={6} className={classes.topMenuItem}>
                    <RelativeLink to={url} onClick={() => closeDrawer(false)}>
                      <Typography color="textPrimary" component="div">
                        {title}
                      </Typography>
                    </RelativeLink>
                  </Grid>
                </Fragment>
              ))}

              <KlearInfluencerForm
                classBtn={classes.topMenuItem}
                closeDrawer={closeDrawer}
                placement="MobileMenu"
              />
            </Grid>
          </div>
        </Slide>
        <MenuFooter />
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh"
  },
  body: {
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflowY: "auto"
  },
  navList: {
    width: "100%",
    paddingTop: 0,
    overflowY: "auto"
  },
  navMenuItem: {
    padding: `${spacing(2)}px ${spacing(3)}px`,
    height: spacing(7),
    "& a": {
      color: "inherit"
    }
  },
  topMenuItemsContainer: {
    paddingTop: spacing(2),
    paddingLeft: spacing(2),
    paddingRight: spacing(2)
  },
  topMenuItem: {
    height: spacing(8),
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    width: "100%",
    display: "flex",
    alignItems: "center"
  }
}))

export default MobileMenu
