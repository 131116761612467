import { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Flag: React.FunctionComponent<Props> = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path fill="#f0f0f0" d="M0 85.3h512v341.3H0z" />
    <path fill="#d80027" d="M256 85.3h256v341.3H256z" />
    <path
      fill="#acabb1"
      d="M208.2 138.7v-21.4h-21.3v21.4h-21.3V160h21.3v21.3h21.3V160h21.4v-21.3z"
    />
  </svg>
)

export default Flag
