import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"
type Props = SvgIconProps

const FacebookIcon: React.FunctionComponent<Props> = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M9.03153 23L9 13H5V9H9V6.5C9 2.7886 11.2983 1 14.6091 1C16.1951 1 17.5581 1.11807 17.9553 1.17085V5.04948L15.6591 5.05052C13.8584 5.05052 13.5098 5.90614 13.5098 7.16171V9H18.75L16.75 13H13.5098V23H9.03153Z"
      fill="white"
    />
  </SvgIcon>
)

export default FacebookIcon
