import { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Delete: React.FC<Props> = () => {
  return (
    <>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>Icon/Delete</title>
        <g
          id="Icon/Delete"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <rect id="Rectangle" x="0" y="0" width="24" height="24" />
          <path
            d="M6,19 C6,20.1045695 6.8954305,21 8,21 L16,21 C17.1045695,21 18,20.1045695 18,19 L18,7 L6,7 L6,19 M8,9 L16,9 L16,19 L8,19 L8,9 M15.5,4 L14.5,3 L9.5,3 L8.5,4 L5,4 L5,6 L19,6 L19,4 L15.5,4 Z"
            id="Shape"
            fill="#333333"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </>
  )
}

export default Delete
