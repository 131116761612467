import request from "@starrepublic/epi/api/request"

export type ColorsGroup = {
  [key: string]: string[]
}

const colors = {
  getColorsGroup: (model: string[]) =>
    request.post<ColorsGroup>("/colors", model),
  convertColor: (id: string) =>
    request.get<ColorsGroup>(
      `/colors/convert/${id.replace("#", "__").replace("/", "--")}`
    )
}

export default colors
