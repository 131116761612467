import ButtonBase from "@material-ui/core/ButtonBase/ButtonBase"
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "common/Typography"
import useStore from "global-hook-store"
import ArrowSmall from "icons/ArrowSmall"
import CouponField from "pages/Checkout/CouponField"
import * as React from "react"
import { useTranslation } from "react-i18next"
import cartStore from "stores/cartStore"
import checkoutStore from "stores/checkoutStore"
import colors from "theme/colors"

import AppliedCoupon from "./AppliedCoupon"
import CartItem from "./CartItem"

const CartItemListCheckout: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    state: { showCoupon },
    actions: { setShowCoupon }
  } = useStore(checkoutStore)

  const {
    state: {
      itemCount,
      carts: { data: carts }
    }
  } = useStore(cartStore)

  const basket = carts.default

  return (
    <>
      <List className={classes.root} classes={{ padding: classes.padding }}>
        {basket?.lineItems?.map(item => (
          <CartItem key={item.code} item={item} />
        ))}
      </List>
      {basket &&
      basket.properties &&
      basket.lineItems &&
      basket.lineItems.length > 0 ? (
        <>
          {basket.discountTotal > 0 && (
            <Typography
              variant="discountPrice"
              color="textSecondary"
              className={classes.discountText}
            >
              <div>{t("cart.you_save")}</div>
              <div>{basket.discountTotalString}</div>
            </Typography>
          )}
          <Grid container>
            <Grid container direction="column">
              <Typography
                variant="body6"
                color="textPrimary"
                className={classes.shipping}
              >
                {basket.properties &&
                basket.properties["orderQualifyForFreeShipping"]
                  ? t("cart.free_shipping_eligible")
                  : `${t("cart.shipping_label")} ${basket.shippingTotalString}`}
              </Typography>
              <Typography
                variant="display8"
                color="textPrimary"
                className={classes.orderFooter}
              >
                {`${t("common.subtotal")} `}

                {(basket.properties["includingTax"] ||
                  basket.taxTotal === 0) && (
                  <>
                    {basket.properties["overrideOrderSubTotal"]
                      ? basket.properties["overrideOrderSubTotal"]
                      : basket.totalString}
                  </>
                )}
                {!basket.properties["includingTax"] && basket.taxTotal > 0 && (
                  <div>
                    {basket.properties["overrideOrderSubTotal"]
                      ? basket.properties["overrideOrderSubTotal"]
                      : basket.properties["totalExcludingTax"]}
                  </div>
                )}
              </Typography>
            </Grid>
            {/* Add coupon code? */}
            {itemCount?.default &&
              itemCount.default > 0 &&
              !basket?.couponCodes.length && (
                <Grid item xs={12} className={classes.voucher}>
                  <ClickAwayListener onClickAway={() => setShowCoupon(false)}>
                    <Grid
                      item
                      className={showCoupon ? classes.showVoucher : ""}
                    >
                      <ButtonBase
                        onClick={() => setShowCoupon(!showCoupon)}
                        className={classes.discountBtn}
                      >
                        <Typography variant="display8">
                          {t("checkout.voucher_code_question")}
                        </Typography>
                        <ArrowSmall
                          direction="left"
                          stroke={colors.rapunzel.darkCharcoal}
                          className={
                            showCoupon
                              ? classes.arrowDownIcon
                              : classes.arrowUpIcon
                          }
                        />
                      </ButtonBase>
                      {showCoupon ? (
                        <Grid container>
                          <div className={classes.couponBox}>
                            <CouponField />
                          </div>
                        </Grid>
                      ) : null}
                    </Grid>
                  </ClickAwayListener>
                </Grid>
              )}
          </Grid>
        </>
      ) : null}
      <AppliedCoupon />
    </>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    minHeight: spacing(4),
    "& li:last-child": {
      marginBottom: spacing(3)
    }
  },
  padding: {
    padding: 0
  },
  orderFooter: {
    display: "flex",
    justifyContent: "start",
    marginBottom: spacing(0.25)
  },
  taxText: {
    fontStyle: "italic",
    fontSize: 12,
    fontWeight: "normal",
    display: "block",
    textAlign: "left",
    marginTop: spacing(0.25)
  },
  discountText: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
    color: palette.rapunzel.complimentary
  },
  voucher: {
    display: "flex",
    justifyContent: "flex-end"
  },
  couponBox: {
    width: "100%"
  },
  showVoucher: {
    marginTop: spacing(1),
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    width: "100%"
  },
  arrowUpIcon: {
    transform: "rotate(270deg)",
    marginRight: -2
  },
  arrowDownIcon: {
    transform: "rotate(90deg)",
    marginRight: -2
  },
  shipping: {
    textAlign: "left"
  },
  discountBtn: {
    justifyContent: "flex-end",
    marginTop: spacing(-4),
    marginBottom: spacing(1.5)
  }
}))

export default CartItemListCheckout
