import checkout from "./checkout"
import colors from "./colors"
import forms from "./forms"
import markets from "./markets"
import newsletter from "./newsletter"
import pages from "./pages"
import products from "./products"
import recommendations from "./recommendations"
import search from "./search"

export default {
  colors,
  checkout,
  products,
  forms,
  search,
  newsletter,
  recommendations,
  markets,
  pages
}
