import request from "./request"

type MarketIdentifier = {
  value: string
}
export type Market = {
  marketId: MarketIdentifier
  isEnabled: boolean
  isSelected: boolean
  marketName: string
  marketDescription: string
  defaultLanguage: string
  languages: string[]
  defaultCurrency: string
  currencies: string[]
  countries: string[]
  pricesIncludeTax: boolean
  selectedCurrency: string
  selectedLanguage: string
  selectedCountry: string
}

export type MarketModel = {
  marketId: string
  language: string
  currency: string
  country: string
}

const markets = {
  // Returns list of all available markets
  getAll: (includeInactive = true, excludeHostless = true) =>
    request.get<Market[]>(`/markets/${includeInactive}/${excludeHostless}`),
  // Changes market for current customer and updates all of the users carts
  changeMarket: (marketId: string, model: MarketModel) =>
    request.post<Market>(`/markets/${marketId}`, model)
}

export default markets
