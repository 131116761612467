import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"
type Props = SvgIconProps

const InstagramIcon: React.FunctionComponent<Props> = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M12 2.162C15.204 2.162 15.584 2.174 16.849 2.232C18.215 2.294 19.482 2.568 20.457 3.543C21.432 4.518 21.706 5.785 21.768 7.151C21.826 8.416 21.838 8.796 21.838 12C21.838 15.204 21.826 15.584 21.768 16.849C21.706 18.215 21.432 19.482 20.457 20.457C19.482 21.432 18.215 21.706 16.849 21.768C15.584 21.826 15.204 21.838 12 21.838C8.796 21.838 8.416 21.826 7.151 21.768C5.785 21.706 4.518 21.432 3.543 20.457C2.568 19.482 2.294 18.215 2.232 16.849C2.174 15.584 2.162 15.204 2.162 12C2.162 8.796 2.174 8.416 2.232 7.151C2.294 5.785 2.568 4.518 3.543 3.543C4.518 2.568 5.785 2.294 7.151 2.232C8.416 2.174 8.796 2.162 12 2.162ZM12 0C8.741 0 8.332 0.014 7.052 0.072C5.102 0.161 3.389 0.639 2.014 2.014C0.639 3.389 0.161 5.102 0.072 7.052C0.014 8.332 0 8.741 0 12C0 15.259 0.014 15.668 0.072 16.948C0.161 18.898 0.639 20.611 2.014 21.986C3.389 23.361 5.102 23.839 7.052 23.928C8.332 23.986 8.741 24 12 24C15.259 24 15.668 23.986 16.948 23.928C18.898 23.839 20.611 23.361 21.986 21.986C23.361 20.611 23.839 18.898 23.928 16.948C23.986 15.668 24 15.259 24 12C24 8.741 23.986 8.332 23.928 7.052C23.839 5.102 23.361 3.389 21.986 2.014C20.611 0.639 18.898 0.161 16.948 0.072C15.668 0.014 15.259 0 12 0Z"
      fill="white"
    />
    <path
      d="M12 5.83789C8.59701 5.83789 5.83801 8.59689 5.83801 11.9999C5.83801 15.4029 8.59701 18.1619 12 18.1619C15.403 18.1619 18.162 15.4029 18.162 11.9999C18.162 8.59689 15.403 5.83789 12 5.83789ZM12 15.9999C9.79101 15.9999 8.00001 14.2089 8.00001 11.9999C8.00001 9.79089 9.79101 7.99989 12 7.99989C14.209 7.99989 16 9.79089 16 11.9999C16 14.2089 14.209 15.9999 12 15.9999Z"
      fill="white"
    />
    <path
      d="M18.4059 7.03381C19.2012 7.03381 19.8459 6.3891 19.8459 5.59381C19.8459 4.79852 19.2012 4.15381 18.4059 4.15381C17.6107 4.15381 16.9659 4.79852 16.9659 5.59381C16.9659 6.3891 17.6107 7.03381 18.4059 7.03381Z"
      fill="white"
    />
  </SvgIcon>
)

export default InstagramIcon
