import config from "@starrepublic/epi/config"
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-chained-backend"
import HttpApi from "i18next-http-backend"
import LocalStorageBackend from "i18next-localstorage-backend"
import { initReactI18next } from "react-i18next"

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        "path",
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
        "subdomain"
      ]
    },
    load: "currentOnly",
    lowerCaseLng: true,
    supportedLngs: ["da", "de", "en", "fi", "no", "sv"],
    ns: [""],
    defaultNS: "",
    fallbackNS: "",
    nonExplicitSupportedLngs: true,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    },
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        {
          prefix: "i18next_res_",
          expirationTime: 24 * 60 * 60 * 1000 // 24 hours
        },
        {
          loadPath: `${config.apiRoot}/api/translations/{{lng}}`
        }
      ]
    }
  })

export default i18n
