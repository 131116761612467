import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Clear from "@material-ui/icons/Clear"
import { toRelativeLink } from "@starrepublic/epi/cms/components/RelativeLink"
import api from "api"
import classNames from "classnames"
import { pushGaEvent, pushToDataLayer } from "common/GoogleTagManagerTracking"
import { browserHistory } from "common/Root"
import Spinner from "common/Spinners/Spinner"
import Wrapper from "common/Wrapper"
import RemoveIcon from "icons/Remove"
import SearchIcon from "icons/Search"
import { Hit, SearchResult, SearchType } from "models/Search"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { from, fromEvent, never, Observable, of, timer } from "rxjs"
import {
  debounce,
  distinctUntilChanged,
  map,
  onErrorResumeNext,
  switchMap
} from "rxjs/operators"

import ProductResult from "./ProductResult"
import SearchResultText from "./SearchResultText"
import SearchSection from "./SearchSection"

type Props = {
  onCloseClick: () => void
}

type SearchHitMap = Record<string, Hit[]>

type Result = {
  hitMap: SearchHitMap
} & SearchResult

const Search: React.FC<Props> = ({ onCloseClick }) => {
  const [results, setResults] = useState<Result | undefined>(undefined)
  const [keyIndex, setKeyIndex] = useState<number | undefined>(undefined)
  const [query, setQuery] = useState("")
  const [suggestions, setSuggestions] = useState<Array<string>>([])
  const [popularSearches, setPopularSearches] = useState<Array<string>>([])
  const [searching, setSearching] = useState(false)
  const classes = useStyles()
  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null

    api.search.popular().then(data => {
      setPopularSearches(data || [])
      pushGaEvent({
        category: "Search",
        action: "Search Intention"
      })
    })

    timer = setTimeout(() => {
      if (inputRef && inputRef.current instanceof HTMLInputElement) {
        inputRef.current.focus()
      }
    }, 300)

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  // Debounce for autocomplete
  useEffect(() => {
    if (inputRef?.current) {
      const subscription = fromEvent(inputRef.current, "input")
        .pipe(
          map(e => (e.target as HTMLInputElement).value),
          debounce(q => timer((q === "" && 0) || 250)),
          distinctUntilChanged(),
          switchMap((q): Observable<string[]> => {
            if (q === "") {
              return of([])
            }
            return from(api.search.autocomplete(q)).pipe(
              onErrorResumeNext(never())
            )
          })
        )
        .subscribe(suggestions => {
          setSuggestions(suggestions)
        })

      return () => {
        subscription.unsubscribe()
      }
    }
  }, [])

  const search = useCallback(async (q: string) => {
    if (q === undefined || q.length < 3 || searching || q.length >= 100) return
    setSuggestions([])
    setKeyIndex(-1)
    setQuery(q)

    setSearching(true)

    const searchResult = await api.search.search(q)

    const results: Result = {
      ...searchResult,
      hitMap: searchResult.hits.reduce((acc, hit) => {
        if (!acc[hit.hitType]) {
          acc[hit.hitType] = []
        }
        acc[hit.hitType].push(hit)
        return acc
      }, {} as SearchHitMap)
    }

    setSearching(false)

    pushToDataLayer({
      event: "searchEvent",
      searchterm: q.toLowerCase()
    })

    setResults(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Debounce for search
  useEffect(() => {
    if (inputRef?.current) {
      const subscription = fromEvent(inputRef.current, "input")
        .pipe(
          map(e => (e.target as HTMLInputElement).value),
          debounce(q => timer((q === "" && 0) || 500)),
          distinctUntilChanged()
        )

        .subscribe(q => {
          search(q)
        })

      return () => {
        subscription.unsubscribe()
      }
    }
  }, [search, inputRef])

  const onSearchResultClick =
    (
      type: "Product" | "Article" | "Popular Search" | "Suggestion",
      q: string | Hit
    ) =>
    () => {
      const event = {
        category: "Search",
        action: "Click"
      }

      if (typeof q !== "string") {
        pushGaEvent({
          ...event,
          label: `${type} - ${q.title}`
        })
        onCloseClick()
        api.search.track(query, q.hitId, results?.trackId || "")
        browserHistory.push(toRelativeLink(q.url))
      } else {
        pushGaEvent({
          ...event,
          label: `${type} - ${q}`
        })
        search(q)
      }
    }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (e.key) {
      case "Enter":
        return search(inputRef?.current?.value || "")
      case "Tab":
        search(suggestions[0])
        return
      case "ArrowDown":
        setKeyIndex(((keyIndex || 0) + 1) % suggestions.length)
        return
      case "ArrowUp":
        setKeyIndex(
          (suggestions.length + ((keyIndex || 0) - 1)) % suggestions.length
        )
        return
    }
  }

  const articles = (results && results.hitMap[SearchType.ARTICLE]) || []
  const products = (results && results.hitMap[SearchType.PRODUCT]) || []

  return (
    <div className={classes.root}>
      <Wrapper
        className={classes.inputWrapper}
        containerClassName={classes.inputWrapperContainer}
      >
        {!searching && <SearchIcon className={classes.searchIcon} />}

        {searching && <Spinner size="sm" className={classes.searchIcon} />}

        <TextField
          onChange={e => {
            setKeyIndex(-1)
            setQuery(e.target.value)
          }}
          value={query}
          className={classes.inputTextField}
          autoFocus={true}
          InputProps={{
            className: classes.input,
            disableUnderline: true,
            placeholder: t("search.hint")
          }}
          inputProps={{
            onKeyDown: handleKeyDown,
            maxLength: 48,
            ref: inputRef
          }}
          variant="standard"
        />
        <IconButton className={classes.closeIcon} onClick={onCloseClick}>
          <Hidden smDown>
            <RemoveIcon />
          </Hidden>
          <Hidden smUp>
            <Clear className={classes.cleanIcon} />
          </Hidden>
        </IconButton>
      </Wrapper>

      <Wrapper
        className={classes.contentWrapper}
        grid
        containerClassName={classes.content}
      >
        <Grid className={classes.results} item xs={12} sm={12} md={6}>
          {products.length > 0 && (
            <SearchSection
              divider
              title={t("search.products")}
              hitCount={products.length}
            >
              {products.map((h, i) => (
                <ProductResult
                  onClick={onSearchResultClick("Product", h)}
                  key={i}
                  product={h}
                />
              ))}
            </SearchSection>
          )}

          {articles.length > 0 && (
            <SearchSection
              title={t("search.articles")}
              hitCount={articles.length}
            >
              {articles.map((h, i) => (
                <SearchResultText
                  key={i}
                  onClick={onSearchResultClick("Article", h)}
                >
                  {h.title}
                </SearchResultText>
              ))}
            </SearchSection>
          )}
        </Grid>

        <Grid className={classes.suggestions} item xs={12} sm={12} md={6}>
          {suggestions.length > 0 && (
            <SearchSection title={t("search.suggestions")}>
              {suggestions.map((p, i) => (
                <SearchResultText
                  className={classNames({
                    [classes.unSelectedSuggestion]:
                      keyIndex !== -1 && i !== keyIndex
                  })}
                  key={i}
                  onClick={onSearchResultClick("Suggestion", p)}
                >
                  {p}
                </SearchResultText>
              ))}
            </SearchSection>
          )}

          {popularSearches && popularSearches.length > 0 && (
            <SearchSection title={t("search.popular_searches")}>
              {popularSearches.map((p, i) => (
                <SearchResultText
                  key={i}
                  onClick={onSearchResultClick("Popular Search", p)}
                >
                  {p}
                </SearchResultText>
              ))}
            </SearchSection>
          )}
        </Grid>
      </Wrapper>
    </div>
  )
}

const useStyles = makeStyles(({ breakpoints, spacing, palette }) => ({
  root: {
    backgroundColor: "#fff",
    width: "100%",
    display: "flex",
    flexDirection: "column",

    [breakpoints.down("sm")]: {
      position: "absolute",
      boxShadow:
        "0px 4px 5px 0px rgba(0,0,0,0.05), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    },

    maxHeight: 800,
    transition: `opacity 300ms ease-in-out, visibility 300ms ease-in-out, max-height 300ms ease-in-out`
  },
  [breakpoints.down("sm")]: {
    "@global": {
      body: {
        overflowY: "hidden"
      }
    }
  },
  contentWrapper: {
    overflowY: "scroll"
  },
  inputTextField: {
    flexGrow: 1
  },
  input: {
    height: "100%",
    fontSize: 18,
    fontWeight: 400
  },
  closeIcon: {
    [breakpoints.up("md")]: {
      display: "none"
    },
    [breakpoints.down("sm")]: {
      margin: `0 ${spacing(1)}px`
    }
  },
  cleanIcon: {
    color: palette.rapunzel.dark
  },
  unSelectedSuggestion: {
    opacity: 0.56
  },
  searchIcon: {
    width: 22,
    height: 22,
    marginRight: spacing(1)
  },
  content: {
    padding: `${spacing(4)}px ${spacing(1)}px`
  },
  inputWrapper: {
    backgroundColor: palette.rapunzel.lightGray
  },
  hint: {
    fontSize: "1.125rem",
    lineHeight: "1.125rem"
  },
  inputWrapperContainer: {
    display: "flex",
    flexDirection: "row",
    height: spacing(8),
    alignItems: "center",
    [breakpoints.up("md")]: {
      height: spacing(10)
    }
  },
  results: {
    [breakpoints.up("md")]: {
      borderRight: `1px solid ${palette.rapunzel.alto}`
    }
  },
  suggestions: {
    [breakpoints.up("md")]: {
      paddingLeft: spacing(4)
    }
  }
}))

export default Search
