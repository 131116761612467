import request from "@starrepublic/epi/api/request"
import { getCached, getIdentifier } from "@starrepublic/epi/api/utils"
import { ApiDynamicPage } from "@starrepublic/epi/types/content/page"

const pages = {
  getContentByIdentifier: (identifier: string, useCache = true) => {
    const id = getIdentifier(identifier)
    const url = `/contents/${id}${window.location.search}`

    return (
      (useCache &&
        getCached<ApiDynamicPage>(
          url,
          "EPI_CONTENT_DATA",
          lang => `/api/contents/${lang}/${id}`,
          true
        )) ||
      request.get(url)
    )
  }
}

export default pages
