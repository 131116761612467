import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import * as React from "react"

type Props = {
  containerClassName?: string
  grid?: boolean
  narrow?: boolean
  forwardRef?: React.Ref<any>
  className?: string
} & React.HTMLProps<HTMLDivElement>

const Wrapper: React.FunctionComponent<Props> = ({
  children,
  className,
  containerClassName,
  grid,
  forwardRef,
  narrow,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <div
      className={classNames(
        {
          grid
        },
        classes.root,
        className
      )}
      {...rest}
    >
      {(grid && (
        <Grid
          innerRef={forwardRef}
          container
          direction="row"
          spacing={0}
          className={classNames(
            {
              narrow,
              grid
            },
            classes.container,
            containerClassName
          )}
        >
          {children}
        </Grid>
      )) || (
        <div
          ref={forwardRef}
          className={classNames(
            {
              narrow,
              grid
            },
            classes.container,
            containerClassName
          )}
        >
          {children}
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    padding: `0 16px`,
    [breakpoints.up("sm")]: {
      padding: `0 16px`,
      "&.grid": {
        padding: `0 ${spacing(1)}px`
      }
    }
  },
  container: {
    margin: "0 auto"
  },
  [breakpoints.up(1300 + spacing(1))]: {
    container: {
      maxWidth: 1300,
      "&.narrow": {
        maxWidth: 784,
        "&.grid": {
          maxWidth: 784 + spacing(2)
        }
      }
    }
  }
}))

const Enhanced = Wrapper

const WrapperWithForwardRef = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    return <Enhanced {...props} forwardRef={ref} />
  }
)

WrapperWithForwardRef.displayName = "Wrapper"

export default WrapperWithForwardRef
