import { makeStyles } from "@material-ui/core/styles"
import RelativeLink from "@starrepublic/epi/cms/components/RelativeLink"
import classNames from "classnames"
import Logotype from "common/Logotype"
import useStore from "global-hook-store"
import React from "react"
import { LinkProps } from "react-router-dom"
import siteSettingsStore from "stores/siteSettingsStore"

type Size = "small" | "large"

const DESKTOP_WIDTH = 220
const MOBILE_WIDTH = 120
const HEIGHT_RATIO = 102 / 356.3

type Props = Omit<LinkProps, "to"> & {
  size?: Size
  className?: string
}

const LogoHeader: React.FunctionComponent<Props> = ({
  size,
  className,
  ...restProps
}) => {
  const {
    state: { lang }
  } = useStore(siteSettingsStore)

  const classes = useStyles()

  return (
    <RelativeLink to={`/${lang}/`} {...restProps}>
      <Logotype
        className={classNames(classes.logo, className, {
          [classes.small]: size === "small"
        })}
      />
    </RelativeLink>
  )
}

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  logo: {
    transition: "transform 250ms",
    color: palette.rapunzel.black,
    width: MOBILE_WIDTH,
    height: MOBILE_WIDTH * HEIGHT_RATIO,
    [breakpoints.up("lg")]: {
      width: DESKTOP_WIDTH,
      height: DESKTOP_WIDTH * HEIGHT_RATIO
    }
  },
  small: {
    [breakpoints.up("lg")]: {
      transform: "scale(0.8)"
    }
  }
}))

export default LogoHeader
