import request from "@starrepublic/epi/api/request"

export type FormModel = {
  hostedPage: number
  submissionId: string
  stepIndex: number
  values: Record<string, string | FileList>
}

export type FormResponse = {
  isSuccess: boolean
  isProgressiveSubmit: boolean
  redirectUrl: string
  message: string
}

const forms = {
  submitForm: (id: string, model: FormModel) => {
    const formData = new FormData()
    formData.append("hostedPage", `${model.hostedPage}`)
    formData.append("submissionId", `${model.submissionId}`)
    formData.append("stepIndex", `${model.stepIndex}`)
    Object.entries(model.values).map(([key, value]) => {
      if (typeof value === "string" || typeof value === "boolean") {
        formData.append(key, value)
      } else {
        Object.values(value).map(file => {
          formData.append(key, file)
        })
      }
    })
    return request.post<FormResponse>(`/forms/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }
}

export default forms
