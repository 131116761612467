import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import BasketButton from "common/BasketButton"
import KlearInfluencerForm from "common/KlearInfluencerForm"
import LinkButton from "common/LinkButton"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import useStore from "global-hook-store"
import React, { useEffect, useState } from "react"
import authStore from "stores/authStore"
import siteSettingsStore from "stores/siteSettingsStore"
import tryggehandel from "utils/tryggehandel"

import MarketsMenu from "../MarketsMenu"
import TopHeaderLinks from "./TopHeaderLinks"
import UserDropDown from "./UserDropDown"

const TopHeader: React.FC = () => {
  const [marketsMenuPopupOpen, setMarketsMenuPopupOpen] = useState("")
  const {
    state: { lang, trustCertificateImage }
  } = useStore(siteSettingsStore)

  const classes = useStyles()

  const {
    state: { isLoggedIn }
  } = useStore(authStore)

  const handleMarketsMenuButtonClick =
    (popup: string) => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.persist && event.persist()

      setMarketsMenuPopupOpen(marketsMenuPopupOpen === popup ? "" : popup)
    }

  useEffect(() => {
    if (trustCertificateImage && lang === "sv") {
      tryggehandel()
    }
  }, [trustCertificateImage, lang])

  return (
    <Wrapper
      className={classNames(classes.section, {
        [classes.sectionLoggedIn]: isLoggedIn
      })}
      containerClassName={classes.container}
    >
      <Typography component="ul" className={classes.menuItems} variant="link2">
        <TopHeaderLinks
          onLinkClick={event => {
            marketsMenuPopupOpen && handleMarketsMenuButtonClick("")(event)
          }}
        />
        {trustCertificateImage && (
          <div style={{ justifyContent: "flex-start" }}>
            <li className={classes.certificationItem}>
              <LinkButton
                link={""}
                onClick={event => {
                  marketsMenuPopupOpen &&
                    handleMarketsMenuButtonClick("")(event)
                }}
                disableRipple
                linkContentClassName={classes.certificationIconWrapper}
                className={classes.certificationLinkButton}
              >
                {trustCertificateImage && (
                  <img
                    id="TEH_LOGOTYPE"
                    src={trustCertificateImage}
                    width="24"
                    height="24"
                    alt=""
                  />
                )}
              </LinkButton>
            </li>
          </div>
        )}

        <KlearInfluencerForm
          className={classes.menuItems}
          placement="TopHeader"
        />
        <MarketsMenu />
      </Typography>
      <div style={{ display: "flex" }}>
        <UserDropDown />
        <BasketButton />
      </div>
    </Wrapper>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  section: {
    backgroundColor: palette.rapunzel.lightGray,
    width: "100%"
  },
  sectionLoggedIn: {
    backgroundColor: palette.rapunzel.darkGray
  },
  container: {
    height: spacing(6),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    maxWidth: "unset"
  },
  menuItems: {
    padding: 0,
    display: "flex",
    flexGrow: 1,
    alignItems: "stretch",
    listStyle: "none",
    textDecoration: "none",
    color: palette.rapunzel.textDefault,
    justifyContent: "space-between"
  },
  certificationItem: {
    height: "100%",
    borderRight: "1px solid #EEEEEE",
    borderLeft: "1px solid #EEEEEE",
    display: "flex"
  },
  marketItem: {
    "& img": {
      marginRight: spacing(1)
    }
  },
  marketsMenuWrapper: {
    position: "relative"
  },
  certificationIconWrapper: {
    display: "flex"
  },
  certificationLinkButton: {
    padding: `0 ${spacing(2)}px`,
    height: "100%"
  }
}))

export default TopHeader
