import ButtonBase from "@material-ui/core/ButtonBase"
import { makeStyles } from "@material-ui/core/styles"
import ArrowBack from "@material-ui/icons/ArrowBack"
import Clear from "@material-ui/icons/Clear"
import Typography from "common/Typography"
import * as React from "react"
import { useTranslation } from "react-i18next"

type Props = {
  hasBackBtn: boolean
  onCloseButtonClick: (state: boolean) => void
  onBackButtonClick: () => void
}

const MainMenuHeader: React.FunctionComponent<Props> = ({
  hasBackBtn,
  onBackButtonClick,
  onCloseButtonClick
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {hasBackBtn ? (
        <ButtonBase className={classes.button} onClick={onBackButtonClick}>
          <ArrowBack />
        </ButtonBase>
      ) : (
        <Typography color="inherit" variant="display4" className={classes.grow}>
          {t("common.menu_title")}
        </Typography>
      )}
      <ButtonBase
        className={classes.button}
        onClick={() => onCloseButtonClick(false)}
      >
        <Clear />
      </ButtonBase>
    </div>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: spacing(8),
    paddingRight: spacing(3),
    paddingLeft: spacing(3),
    background: "#F7F7F7"
  },
  grow: { flexGrow: 1 },
  button: {
    margin: `0 -${spacing(1)}px`,
    padding: spacing(1)
  }
}))

export default MainMenuHeader
