import { makeStyles } from "@material-ui/core/styles"
import { emphasize } from "@material-ui/core/styles/colorManipulator"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import PageSpinner from "common/Spinners/PageSpinner"
import Typography from "common/Typography"
import useBreakpoint from "hooks/useBreakpoint"
import MarketFlag from "icons/flags"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { Country } from "../../stores/marketStore"
import { selectorClosedLocalStorageKey } from "."

type Props = {
  countries: Country[]
  changeCountry: (country: string) => void
}

const COUNTRIES_TO_SHOW_MOBILE = 5
const COUNTRIES_TO_SHOW_DESKTOP = 9

const MarketSelectorItems: React.FunctionComponent<Props> = ({
  countries,
  changeCountry
}) => {
  const classes = useStyles()
  const isMobile = useBreakpoint("sm")
  const [showAll, setShowAll] = useState(false)
  const [filteredCountries, setFilteredCountries] = useState<typeof countries>(
    []
  )

  const setCountry = (country: string) => {
    window.localStorage.setItem(selectorClosedLocalStorageKey, "true")

    changeCountry(country)
  }

  useEffect(() => {
    let noOfCountriesToShow = 0

    if (showAll) {
      noOfCountriesToShow = countries.length
    } else if (isMobile) {
      noOfCountriesToShow = COUNTRIES_TO_SHOW_MOBILE
    } else {
      noOfCountriesToShow = COUNTRIES_TO_SHOW_DESKTOP
    }

    const filteredListOfCountries = countries.slice(0, noOfCountriesToShow)

    setFilteredCountries(filteredListOfCountries)
  }, [isMobile, showAll, countries])

  if (!filteredCountries || filteredCountries.length < 1) {
    return <PageSpinner fetching className={classes.spinner} />
  }

  return (
    <div className={classes.marketsContainer}>
      {filteredCountries.map(country => (
        <Link
          key={`market-${country.alpha2}`}
          to="/"
          title={`Visit ${country.name}`}
          onClick={() => setCountry(country.alpha2)}
          className={classes.market}
        >
          <MarketFlag
            countryCode={country.alpha2}
            className={classes.marketFlag}
          />

          <Typography
            component="span"
            variant="body1"
            className={classes.marketNameButton}
          >
            {country.name}
          </Typography>
        </Link>
      ))}

      {!showAll && (
        <Link
          to="/"
          title="View all markets"
          onClick={e => {
            e.preventDefault()
            setShowAll(true)
          }}
          className={`${classes.market} ${classes.showAllLink}`}
        >
          <Typography
            component="span"
            variant="body1"
            className={classes.marketNameButton}
          >
            View all markets
          </Typography>
          <ExpandMoreIcon className={classes.expandMoreIcon} />
        </Link>
      )}
    </div>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  marketsContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: `-${spacing(0.5)}px`,
    marginRight: `-${spacing(0.5)}px`,
    marginBottom: spacing(2),

    [breakpoints.up("sm")]: {
      marginLeft: `-${spacing(1)}px`,
      marginRight: `-${spacing(1)}px`
    }
  },
  market: {
    display: "flex",
    alignItems: "center",
    height: 56,
    flex: `1 0 calc(50% - ${spacing(1)}px)`,
    maxWidth: `calc(50% - ${spacing(1)}px)`,
    margin: spacing(0.5),
    padding: `${spacing(1)}px ${spacing(2)}px`,
    backgroundColor: palette.rapunzel.pinkMedium,
    fontSize: 20,

    fontWeight: 400,
    transition: "background-color 0.1s",

    [breakpoints.up("md")]: {
      flex: `1 0 calc(25% - ${spacing(2)}px)`,
      maxWidth: `calc(25% - ${spacing(2)}px)`
    },

    [breakpoints.up("lg")]: {
      flex: `1 0 calc(20% - ${spacing(2)}px)`,
      maxWidth: `calc(20% - ${spacing(2)}px)`
    },

    "&:hover": {
      backgroundColor: emphasize(palette.rapunzel.pinkMedium, 0.05)
    }
  },
  showAllLink: {
    paddingLeft: spacing(2),
    "& > span": {
      fontSize: 14,

      [breakpoints.up("md")]: {
        fontSize: 16
      }
    }
  },
  marketFlag: {
    width: 32,
    verticalAlign: "middle",
    marginRight: spacing(1)
  },
  marketNameButton: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    verticalAlign: "middle",
    maxWidth: "80%",
    fontSize: 16
  },
  expandMoreIcon: {
    verticalAlign: "middle"
  },
  spinner: {
    filter: "brightness(1000%)"
  }
}))

export default MarketSelectorItems
