import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Remove: React.FunctionComponent<Props> = props => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      stroke="#000"
      d="M16 8l-8 8M16 16L8 8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </SvgIcon>
)

export default Remove
