import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"
type Props = SvgIconProps

const LinkedinIcon: React.FunctionComponent<Props> = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M5.4666 23.3333H0.799935V7.99994H5.59993V23.3333H5.4666ZM3.0666 5.8666C1.59994 5.8666 0.266602 4.6666 0.266602 3.0666C0.266602 1.59994 1.4666 0.266602 3.0666 0.266602C4.53327 0.266602 5.8666 1.4666 5.8666 3.0666C5.8666 4.6666 4.6666 5.8666 3.0666 5.8666ZM23.3333 23.3333H18.5333V15.8666C18.5333 14.1333 18.5333 11.8666 16.1333 11.8666C13.5999 11.8666 13.3333 13.7333 13.3333 15.7333V23.3333H8.53327V7.99994H13.0666V10.1333C13.7333 8.93327 15.1999 7.73327 17.5999 7.73327C22.3999 7.73327 23.3333 10.9333 23.3333 15.0666V23.3333Z"
      fill="white"
    />
  </SvgIcon>
)

export default LinkedinIcon
