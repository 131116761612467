import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import useStore from "global-hook-store"
import MarketFlag from "icons/flags"
import React, { ChangeEvent, useState } from "react"
import { useTranslation } from "react-i18next"
import marketStore from "stores/marketStore"
import { langCodeToName } from "utils/language"

import TextField from "../../common/TextField"
import Select from "../Select"

type Props = {
  onClose: () => void
}

const MarketsMenuContent: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles()

  const {
    state: {
      selectedCountry,
      defaultMarket,
      countries,
      languages,
      marketsByCountryCode
    },
    actions: { changeCountry }
  } = useStore(marketStore)

  const [currentCountryCode, setCurrentCountryCode] = useState(
    selectedCountry || ""
  )
  const [currentLanguage, setCurrentLanguage] = useState(
    (defaultMarket && defaultMarket.selectedLanguage.toUpperCase()) || ""
  )
  const [currentCurrency, setCurrentCurrency] = useState(
    (defaultMarket && defaultMarket.selectedCurrency) || ""
  )

  const changeLocalCountry = (e: ChangeEvent<HTMLSelectElement>) => {
    const newCountry = countries.find(x => x.alpha2 === e.target.value)

    if (!newCountry) {
      return
    }

    setCurrentCountryCode(newCountry.alpha2)

    const marketData = marketsByCountryCode[newCountry.alpha2]

    if (!marketData || marketData.length === 0) {
      return
    }

    setCurrentLanguage(marketData[0].defaultLanguage.toUpperCase())
    setCurrentCurrency(marketData[0].defaultCurrency)
  }

  const changeLocalLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrentLanguage(e.target.value)
  }

  const onSubmit = async () => {
    changeCountry([currentCountryCode, currentLanguage.toLowerCase()])
  }

  const { t } = useTranslation()

  return (
    <div className={classes.countryPickerContainer}>
      <Select
        label={t("langpicker.shipping_country", "Shipping country")}
        values={countries.map(x => ({
          label: x.name,
          value: x.alpha2,
          Icon: (
            <MarketFlag countryCode={x.alpha2} className={classes.marketFlag} />
          )
        }))}
        onChange={changeLocalCountry}
        currentValue={currentCountryCode}
      />

      <TextField
        label={t("langpicker.currency", "Currency")}
        InputProps={{ disableUnderline: true }}
        InputLabelProps={{ shrink: false }}
        disabled
        value={currentCurrency}
        variant="filled"
        detachedLabel
      />

      <Select
        label={t("langpicker.language", "Language")}
        values={languages.map(x => ({
          label: langCodeToName(x),
          value: x.toUpperCase()
        }))}
        onChange={changeLocalLanguage}
        currentValue={currentLanguage}
      />

      <div className={classes.buttonContainer}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={onSubmit}
          className={classes.buttonCta}
        >
          {t("langpicker.save", "Save changes")}
        </Button>
      </div>

      <div className={classes.buttonContainer}>
        <Button
          fullWidth
          onClick={onClose}
          variant="outlined"
          color="secondary"
          className={classes.buttonCancel}
        >
          {t("langpicker.cancel", "Cancel")}
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  countryPickerContainer: {
    padding: spacing(2),
    paddingTop: spacing(4),
    paddingBottom: spacing(4),
    "& > select": {
      width: "100%",
      fontSize: spacing(2)
    },
    "& > div > div": {
      display: "flex"
    },
    "& > label": {
      marginBottom: spacing(1)
    },

    "& > .MuiFilledInput-root": {
      padding: 0,
      background: "transparent"
    }
  },
  marketFlag: {
    width: spacing(2.48),
    height: spacing(2.4),
    marginRight: spacing(1.25)
  },
  buttonContainer: {
    marginTop: spacing(2)
  },
  buttonCta: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    flexGrow: 1,
    minHeight: spacing(7)
  },
  buttonCancel: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    maxHeight: spacing(6),
    flexGrow: 1
  }
}))

export default MarketsMenuContent
