import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import * as React from "react"
import { useTranslation } from "react-i18next"

const CheckoutButton: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Button classes={classes} variant="contained" color="primary">
      {t("cart.checkout_button_title")}
    </Button>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    textAlign: "center",
    // Button block
    minWidth: spacing(21.25),
    minHeight: spacing(7),
    paddingTop: 18,
    paddingBottom: 18
  }
}))

export default CheckoutButton
