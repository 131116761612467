import { makeStyles } from "@material-ui/core/styles"
import Spinner from "common/Spinners/Spinner"
import Typography from "common/Typography"
import useStore from "global-hook-store"
import Cart from "models/Cart"
import * as React from "react"
import { useTranslation } from "react-i18next"
import authStore from "stores/authStore"

type Props = {
  basket: Cart | null
  addingItem: boolean
}

const CartSummary: React.FunctionComponent<Props> = ({
  basket,
  addingItem
}) => {
  const {
    state: { isLoggedIn }
  } = useStore(authStore)

  const classes = useStyles()
  const { t } = useTranslation()
  const isB2bCustomer = isLoggedIn
  if (!basket || basket.lineItems.length <= 0) {
    return null
  }

  if (addingItem) {
    return <Spinner className={classes.spinner} size="sm" />
  }

  return (
    <div className={isB2bCustomer ? classes.rootContainer : classes.root}>
      <>
        {isB2bCustomer && basket.taxTotal > 0 && (
          <Typography
            variant="body6"
            color="textPrimary"
            className={classes.cartContent}
          >
            {`${t("cart.taxes_included")}: `}
            {basket.totalString}
          </Typography>
        )}

        {isB2bCustomer && basket.taxTotal > 0 && (
          <Typography
            variant="body6"
            color="textPrimary"
            className={classes.cartContent}
          >
            {`${t("cart.taxes_label")}: `}
            {basket.taxTotalString}
          </Typography>
        )}

        {basket.discountTotal > 0 && (
          <Typography
            variant="body6"
            color="textPrimary"
            className={classes.cartContent}
          >
            {`${t("cart.discount_label")}: `}
            {basket.discountTotalString}
          </Typography>
        )}

        {isB2bCustomer && basket.properties && (
          <Typography
            variant="body6"
            color="textPrimary"
            className={classes.totalExclTax}
          >
            <strong className={classes.emphasized}>
              {t("cart.total_label")}
            </strong>{" "}
            {`${t("cart.taxes_excluded")}: `}
            <strong className={classes.emphasized}>
              {basket.subTotalString}
            </strong>
          </Typography>
        )}

        {!isB2bCustomer && (
          <Typography
            variant="body6"
            color="textPrimary"
            className={classes.totalExclTax}
          >
            <strong className={classes.emphasized}>
              {t("cart.total_label")}
            </strong>{" "}
            <strong className={classes.emphasized}>
              {basket.subTotalString}
            </strong>
          </Typography>
        )}
      </>

      <>
        <Typography
          variant="body6"
          color="textPrimary"
          className={classes.shipping}
        >
          {basket.properties && basket.properties["orderQualifyForFreeShipping"]
            ? t("cart.free_shipping_eligible")
            : t("cart.shipping_calculate_in_checkout")}
        </Typography>
      </>
    </div>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginBottom: spacing(-1),
    width: "55%"
  },
  rootContainer: {
    marginTop: spacing(1),
    marginBottom: "18px",
    width: "55%"
  },
  totalExclTax: {
    textAlign: "left",
    fontWeight: 500
  },
  cartContent: {
    textAlign: "left",
    marginBottom: spacing(0.25)
  },
  spinner: {
    margin: `${spacing(3)}px auto`
  },
  emphasized: {
    display: "inline-block",
    fontSize: 16
  },
  shipping: {
    textAlign: "left"
  }
}))

export default CartSummary
