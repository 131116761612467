import ButtonBase, { ButtonBaseProps } from "@material-ui/core/ButtonBase"
import { makeStyles } from "@material-ui/core/styles"
import { editorAttributes } from "@starrepublic/epi/cms"
import RelativeLink from "@starrepublic/epi/cms/components/RelativeLink"
import { PropertyInfo } from "@starrepublic/epi/types/cms/content"
import classNames from "classnames"
import { pushGaEvent } from "common/GoogleTagManagerTracking"
import Typography from "common/Typography"
import React from "react"
import { CustomVariant } from "theme/typography"

type Props = {
  link: string
  propsInfo?: PropertyInfo
  offset?: boolean
  variant?: CustomVariant
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>
  className?: string
  linkText?: string
  dense?: boolean
  disableRipple?: boolean
  mailto?: boolean
  tel?: boolean
  linkContentClassName?: string
  buttonClassName?: string
} & ButtonBaseProps

const LinkButton: React.FunctionComponent<Props> = ({
  link,
  linkText,
  children,
  dense,
  offset,
  className,
  variant,
  onClick,
  mailto = false,
  tel = false,
  component,
  disableRipple = false,
  linkContentClassName,
  propsInfo,
  buttonClassName
}) => {
  const classes = useStyles()

  const buttonProps = {
    className: classNames(
      classes.root,
      {
        [classes.dense]: dense,
        [classes.offset]: offset && !dense
      },
      className
    ),
    ...editorAttributes(propsInfo)
  }

  const content = (
    <ButtonBase
      disableRipple={disableRipple}
      className={classNames(classes.button, buttonClassName)}
      onClick={onClick}
    >
      <Typography
        component={component || "span"}
        variant={variant}
        className={linkContentClassName}
      >
        {linkText || children}
      </Typography>
    </ButtonBase>
  )

  const external = /(http|https):/.test(link)
  const handleClick = () => {
    const category =
      (external && "ExternalLink") ||
      (mailto && "Email") ||
      (tel && "Phone") ||
      ""
    category !== "" &&
      pushGaEvent({
        category,
        action: "Click",
        label: link.replace(/\s+/g, "")
      })
  }

  return (
    (external && (
      <a
        href={link}
        onClick={handleClick}
        target="_blank"
        rel="noreferrer"
        {...buttonProps}
      >
        {content}
      </a>
    )) ||
    (mailto && (
      <a href={`mailto:${link}`} onClick={handleClick} {...buttonProps}>
        {content}
      </a>
    )) ||
    (tel && (
      <a
        href={`tel:${link.replace(/\s+/g, "")}`}
        onClick={handleClick}
        {...buttonProps}
      >
        {content}
      </a>
    )) || (
      <RelativeLink to={link} {...buttonProps}>
        {content}
      </RelativeLink>
    )
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    cursor: "pointer",
    WebkitTapHighlightColor: "transparent",
    color: "inherit"
  },
  button: {
    padding: spacing(0.5),
    "&:hover": {
      color: "inherit",
      textDecoration: "underline"
    }
  },
  dense: {
    padding: `${spacing(1)}px 0`
  },
  offset: {
    marginLeft: spacing(-1)
  }
}))

export default LinkButton
