import { makeStyles } from "@material-ui/core/styles"
import { CSSProperties } from "@material-ui/core/styles/withStyles"
import MuiTypography, { TypographyProps } from "@material-ui/core/Typography"
import classNames from "classnames"
import React from "react"
import {
  customToMuiVariantMapping,
  customTypography,
  CustomVariant
} from "theme/typography"

const componentMapping: {
  [key in CustomVariant]: React.ElementType<React.HTMLAttributes<HTMLElement>>
} = {
  display1: "h1",
  display2: "h1",
  display3: "h2",
  display4: "h3",
  display5: "h4",
  display6: "h5",
  display7: "h5",
  display8: "h3",
  display9: "h2",
  display10: "h2",
  display11: "h2",
  headline1: "h1",
  headline2: "h2",
  headline3: "h3",
  headline4: "h4",
  headline5: "h5",
  headline6: "h6",
  headline7: "h5",
  subHeading: "h5",
  subText: "h5",
  subTextSmall: "h5",
  body1: "p",
  body2: "p",
  body3: "p",
  body4: "p",
  body5: "p",
  body6: "p",
  body7: "p",
  linksListBig: "h5",
  linksListMedium: "p",
  linksListSmall: "p",
  popularCategoriesTitle: "h2",
  popularCategoriesTitleSmall: "h2",
  link1: "span",
  link2: "span",
  link3: "span",
  link4: "span",
  link5: "span",
  label: "label",
  labelSmall: "label",
  labelBold: "label",
  caption: "caption",
  overline: "p",
  button1: "span",
  button2: "span",
  lengthButton: "span",
  productTag: "span",
  priceLarge: "span",
  priceSmall: "h4",
  priceSmallMobile: "h4",
  discountPrice: "span",
  discountPriceSmall: "span",
  currency: "span",
  mainNav: "span",
  headlinePdp: "h2",
  pricePdpNew: "h2",
  smallTag: "h5"
}

type Props = {
  variant?: CustomVariant
  className?: string
  isDarkTheme?: boolean
  bold?: boolean
  inline?: boolean
  component?: React.ElementType<React.HTMLAttributes<HTMLElement>>
} & Omit<TypographyProps, "variant">

const Typography: React.FC<Props> = ({
  component,
  children,
  inline,
  className,
  variant = "body1",
  isDarkTheme,
  bold,
  ...other
}) => {
  const classes = useStyles()

  return (
    <MuiTypography
      variant={customToMuiVariantMapping[variant]}
      component={component || componentMapping[variant]}
      className={classNames(className, classes[variant], {
        [classes.lightText]: isDarkTheme,
        [classes.bold]: bold
      })}
      {...(inline && { display: "inline" })}
      {...other}
    >
      {children}
    </MuiTypography>
  )
}

const useStyles = makeStyles(({ palette }) => ({
  ...(customTypography as Record<CustomVariant, CSSProperties>),
  lightText: {
    color: palette.rapunzel.white
  },
  bold: {
    fontWeight: 700
  }
}))

export default Typography
