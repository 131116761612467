import { alpha3ToAlpha2 } from "i18n-iso-countries"
import React from "react"

import AustriaFlag from "./at"
import CanadaFlag from "./ca"
import SwitzerlandFlag from "./ch"
import GermanyFlag from "./de"
import DenmarkFlag from "./dk"
import SpainFlag from "./es"
import FinlandFlag from "./fi"
import FranceFlag from "./fr"
import UnitedKingdomFlag from "./gb"
import IcelandFlag from "./is"
import ItalyFlag from "./it"
import MaltaFlag from "./mt"
import NetherlandsFlag from "./nl"
import NorwayFlag from "./no"
import PolandFlag from "./pl"
import SwedenFlag from "./se"
import UnitedStatesFlag from "./us"

type FlagProps = {
  countryCode: string
  className?: string
}

const FlagComponents = {
  se: SwedenFlag,
  sv: SwedenFlag,
  at: AustriaFlag,
  ca: CanadaFlag,
  dk: DenmarkFlag,
  da: DenmarkFlag,
  fi: FinlandFlag,
  fr: FranceFlag,
  de: GermanyFlag,
  is: IcelandFlag,
  it: ItalyFlag,
  mt: MaltaFlag,
  nl: NetherlandsFlag,
  no: NorwayFlag,
  pl: PolandFlag,
  es: SpainFlag,
  ch: SwitzerlandFlag,
  gb: UnitedKingdomFlag,
  en: UnitedKingdomFlag,
  us: UnitedStatesFlag
}

const MarketFlag: React.FunctionComponent<FlagProps> = ({
  countryCode,
  className,
  ...restProps
}) => {
  const alpha2CountryCode =
    countryCode.length === 3 ? alpha3ToAlpha2(countryCode) : countryCode

  if (
    !Object.hasOwnProperty.call(FlagComponents, alpha2CountryCode.toLowerCase())
  ) {
    console.warn(`Missing flag for ${alpha2CountryCode}`)
    return null
  }

  const Component = React.createElement(
    FlagComponents[alpha2CountryCode.toLowerCase()],
    {
      className,
      ...restProps
    }
  )

  return Component
}

export default MarketFlag
