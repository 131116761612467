import { makeStyles } from "@material-ui/core/styles"
import MuiTextField, { TextFieldProps } from "@material-ui/core/TextField"
import classNames from "classnames"
import Typography from "common/Typography"
import * as React from "react"

type OwnProps = {
  detachedLabel?: boolean
}

type Props = OwnProps & TextFieldProps

const TextField: React.FunctionComponent<Props> = ({
  id,
  label,
  variant = "outlined" as any,
  value,
  onChange,
  margin,
  detachedLabel = false,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <>
      {detachedLabel && (
        <Typography variant="body1" className={classes.label}>
          {label}
        </Typography>
      )}

      <MuiTextField
        id={id}
        label={!detachedLabel && label}
        variant={variant}
        className={classNames(classes.textField, {
          [classes.filledTextField]: variant === "filled"
        })}
        inputProps={{
          className: detachedLabel ? classes.inputWithNoPadding : ""
        }}
        value={value}
        onChange={onChange}
        margin={margin}
        fullWidth
        {...rest}
      />
    </>
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  textField: {
    marginTop: spacing(2),
    marginBottom: spacing(2)
  },
  filledTextField: {
    marginTop: 0,
    marginBottom: spacing(3)
  },
  label: {
    fontWeight: "normal",
    marginBottom: spacing(1)
  },
  inputWithNoPadding: {
    padding: 0
  }
}))

export default TextField
