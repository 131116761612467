import { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Flag: React.FunctionComponent<Props> = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path fill="#f0f0f0" d="M341.3 85.3H0v341.4H512V85.3z" />
    <path fill="#6da544" d="M0 85.3h170.7v341.3H0z" />
    <path fill="#d80027" d="M341.3 85.3H512v341.3H341.3z" />
  </svg>
)

export default Flag
