import { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Flag: React.FunctionComponent<Props> = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path fill="#d80027" d="M0 85.3h512v341.3H0z" />
    <path
      fill="#f0f0f0"
      d="M356.2 222.6h-66.8v-66.8h-66.8v66.8h-66.8v66.8h66.8v66.8h66.8v-66.8h66.8z"
    />
  </svg>
)

export default Flag
