import ButtonBase from "@material-ui/core/ButtonBase"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import RelativeLink from "@starrepublic/epi/cms/components/RelativeLink"
import classNames from "classnames"
import BasketButton from "common/BasketButton"
import LogoHeader from "common/LogoHeader"
import Search from "common/Search"
import SearchButton, { DisplayedIcon } from "common/Search/SearchButton"
/* A/B-TEST variant */
import SearchVariant from "common/Search/SearchVariant"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import useStore from "global-hook-store"
import MenuIcon from "icons/Menu"
import React, { useEffect, useState } from "react"
import authStore from "stores/authStore"
import siteSettingsStore from "stores/siteSettingsStore"

import { HEADER_BOX_SHADOW } from "./Header"
import MegaMenuContent from "./MegaMenuContent"
import MobileMenu from "./MobileMenu"
import UserDropDown from "./UserDropDown"

type Props = {
  activeHeader?: boolean
  newSearchDesign?: boolean
}

type State = {
  isDrawerOpen: boolean
  isSearchOpen: boolean
  refs: {
    [key: string]: HTMLElement | null
  }
}

const hoverListener = (enter: boolean) => (e: MouseEvent) => {
  toggleHover(e.target as HTMLElement, enter)
}

const toggleHover = (target: HTMLElement | null, enter: boolean) => {
  if (!target) {
    return
  }

  const classList = target.classList
  if (enter) {
    classList.add("hover")
  } else {
    classList.remove("hover")
  }
}

const MegaMenu: React.FC<Props> = ({
  activeHeader = false,
  newSearchDesign = false
}) => {
  const [state, setState] = useState<State>(() => ({
    isDrawerOpen: false,
    isSearchOpen: false,
    refs: {}
  }))

  const classes = useStyles()

  const {
    state: { menus, menuSignificantWords }
  } = useStore(siteSettingsStore)

  const {
    state: { isLoggedIn }
  } = useStore(authStore)

  const catalogMenu = menus.main || []
  const topMenu = menus.top || []
  const pinnedLinkMenu = menus.pinnedLink || []

  const { isDrawerOpen, refs, isSearchOpen } = state

  const openDrawer = (isDrawerOpen: boolean) => {
    setState({ ...state, isDrawerOpen })
  }

  const onItemClick = () => {
    Object.values(refs).forEach(elem => {
      // add a delay to show effect and delay spinner
      setTimeout(() => toggleHover(elem, false), 200)
    })
  }

  const toggleSearch = () => {
    setState({ ...state, isSearchOpen: !isSearchOpen })
  }

  useEffect(() => {
    Object.values(refs).forEach(elem => {
      if (!elem) {
        return
      }

      elem.addEventListener("mouseenter", hoverListener(true))
      elem.addEventListener("mouseleave", hoverListener(false))
    })

    return () => {
      Object.values(refs).forEach(elem => {
        if (!elem) {
          return
        }

        elem.removeEventListener("mouseenter", hoverListener(true))
        elem.removeEventListener("mouseleave", hoverListener(false))
      })
    }
  })

  useEffect(
    () => () => {
      Object.values(refs).forEach(elem => {
        if (!elem) {
          return
        }

        elem.removeEventListener("mouseenter", hoverListener(true))
        elem.removeEventListener("mouseleave", hoverListener(false))
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const searchButton = (
    <SearchButton
      displayedIcon={
        (!newSearchDesign && isSearchOpen && DisplayedIcon.Close) ||
        DisplayedIcon.Search
      }
      onClick={() => toggleSearch()}
      className={classNames(
        {
          ["active"]: isSearchOpen
        },
        classes.searchButton,
        classes.mobileMenuItem
      )}
    />
  )

  const showSearchButton =
    !newSearchDesign || (newSearchDesign && activeHeader && !isSearchOpen)

  return (
    <>
      <Wrapper
        containerClassName={classNames(classes.root, {
          [classes.rootActive]: activeHeader
        })}
        className={classNames(classes.wrapper, {
          [classes.wrapperLoggedIn]: isLoggedIn
        })}
      >
        <Hidden lgUp>
          <div className={classes.mobileLeftSection}>
            <IconButton
              color="inherit"
              aria-label="Menu"
              className={classNames(classes.mobileMenuItem, {
                [classes.mobileMenuItemLoggedIn]: isLoggedIn
              })}
              onClick={() => openDrawer(true)}
            >
              <MenuIcon className={classes.mobileMenuIcon} />
            </IconButton>

            {showSearchButton && searchButton}
          </div>

          <div className={classes.mobileMiddleSection}>
            <LogoHeader
              className={classNames(classes.logoMobile, {
                [classes.logoMobileLoggedIn]: isLoggedIn
              })}
            />
          </div>

          <div className={classes.mobileRightSection}>
            <UserDropDown className={classes.mobileMenuItem} isMobile />
            <BasketButton className={classes.mobileMenuItem} mobile />
          </div>
        </Hidden>

        <Hidden mdDown>
          <div
            className={classNames(classes.logoSection, {
              [classes.logoSectionActive]: activeHeader
            })}
          >
            <LogoHeader
              className={classes.logo}
              size={activeHeader ? "small" : "large"}
            />
          </div>
        </Hidden>

        <Typography
          variant="mainNav"
          component="ul"
          className={classNames(classes.megaMenu)}
        >
          {catalogMenu.map(item => (
            <li
              className={classes.dropDown}
              ref={elem => {
                refs[item._id] = elem
              }}
              key={item._id}
            >
              <RelativeLink to={item.url}>
                <ButtonBase
                  onClick={() => {
                    onItemClick()
                  }}
                  className={classNames(classes.menuItemTitle, "menuItemTitle")}
                >
                  <Typography
                    variant="mainNav"
                    component="span"
                    className={classNames(classes.menuItem, {
                      [classes.significantMenuItem]: menuSignificantWords
                        .map(x => x.toLowerCase())
                        .includes(item.title.toLowerCase())
                    })}
                  >
                    {item.title}
                  </Typography>
                </ButtonBase>
              </RelativeLink>
              <Wrapper
                className={classNames(
                  classes.megaMenuContent,
                  {
                    [classes.megaMenuContentActive]: activeHeader
                  },
                  "megaMenuContent"
                )}
              >
                <MegaMenuContent
                  onItemClick={onItemClick}
                  childItems={item.children || []}
                  pinnedLinkMenu={pinnedLinkMenu}
                />
              </Wrapper>
            </li>
          ))}
          <li className={classes.iconBoxListWrapper}>
            {!newSearchDesign && (
              <Divider component="span" className={classes.verticalDivider} />
            )}
            <Hidden mdDown>
              <div className={classes.iconBox}>
                <div
                  className={classNames({
                    [classes.searchActive]: isSearchOpen
                  })}
                >
                  {showSearchButton && searchButton}
                </div>
                <div
                  className={classNames(
                    classes.basketCollapsed,
                    activeHeader ? classes.basketCollapsedVisible : ""
                  )}
                >
                  <BasketButton
                    className={classes.mobileMenuItem}
                    mobile
                    collapsed
                  />
                </div>
              </div>
            </Hidden>
            <Drawer
              classes={{ paper: classes.drawer }}
              anchor="left"
              open={isDrawerOpen}
              onClose={() => openDrawer(false)}
            >
              <MobileMenu
                closeDrawer={openDrawer}
                catalogMenu={catalogMenu}
                topMenu={topMenu}
              />
            </Drawer>
          </li>
        </Typography>
      </Wrapper>
      {!newSearchDesign ? (
        <>
          {isSearchOpen && (
            <ClickAwayListener
              onClickAway={() => {
                setState({ ...state, isSearchOpen: false })
              }}
            >
              <div>
                <Search
                  onCloseClick={() => {
                    toggleSearch()
                  }}
                />
              </div>
            </ClickAwayListener>
          )}
        </>
      ) : (
        <SearchVariant
          collapsedHeader={activeHeader}
          isSearchOpen={isSearchOpen}
          onCloseClick={() => {
            setState({ ...state, isSearchOpen: false })
          }}
        />
      )}
    </>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  root: {
    alignItems: "center",
    height: 165,
    paddingTop: 16,
    display: "flex",
    flexWrap: "wrap",
    maxWidth: 1136,
    [breakpoints.up("lg")]: {
      alignItems: "flex-end"
    },
    [breakpoints.down("md")]: {
      height: spacing(8),
      paddingTop: 0
    },
    [breakpoints.down("lg")]: {
      flexWrap: "wrap",
      justifyContent: "space-between"
    }
  },
  rootActive: {
    height: 115,
    paddingBottom: 25,
    [breakpoints.down("md")]: {
      height: spacing(8)
    },
    [breakpoints.down("lg")]: {
      flexWrap: "wrap",
      justifyContent: "space-between"
    }
  },
  wrapper: {
    [breakpoints.down("md")]: {
      backgroundColor: palette.rapunzel.white,
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
      position: "fixed",
      width: "100%",
      top: 0,
      boxShadow:
        "0px 4px 5px 0px rgba(0,0,0,0.05), 0px 1px 10px 0px rgba(0,0,0,0.12)"
    }
  },
  wrapperLoggedIn: {
    [breakpoints.down("md")]: {
      backgroundColor: palette.rapunzel.darkGray
    }
  },
  mobileLeftSection: {
    display: "flex",
    justifyContent: "flex-start",
    flex: 1
  },
  mobileMiddleSection: {
    flex: 1,
    justifyContent: "center",
    display: "flex",
    "& a": {
      display: "flex"
    }
  },
  mobileRightSection: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1
  },
  mobileMenuItem: {
    display: "flex",
    justifyContent: "center",
    width: 48,
    height: 64,
    padding: 0
  },
  mobileMenuItemLoggedIn: {
    color: palette.rapunzel.white
  },
  mobileMenuIcon: {
    width: 22,
    height: 22
  },
  drawer: {
    maxWidth: 450,
    [breakpoints.down("md")]: {
      width: "100vw"
    }
  },
  logoSection: {
    width: "100%",
    height: "55%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  logoSectionActive: {
    height: "45%",
    "& svg": {
      marginTop: 10
    }
  },
  logo: {
    height: "100%",
    [breakpoints.up("lg")]: {
      boxSizing: "content-box",
      padding: "0 20px"
    }
  },
  logoMobile: {
    color: palette.rapunzel.black
  },
  logoMobileLoggedIn: {
    color: palette.rapunzel.white
  },
  flex: {
    flexGrow: 1
  },
  menuIcon: {
    paddingLeft: 0
  },
  megaMenu: {
    paddingInlineStart: `${spacing(1)}px`,
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
    lineHeight: 1,
    flex: 1,
    marginTop: 6,
    [breakpoints.down("md")]: {
      display: "none"
    }
  },
  megaMenuContent: {
    paddingTop: spacing(4),
    background: palette.rapunzel.white,
    position: "absolute",
    boxShadow: HEADER_BOX_SHADOW,
    width: "100%",
    top: spacing(25),
    left: 0,
    zIndex: 999,
    visibility: "hidden",
    opacity: 0,
    transition: `opacity 200ms ease-in-out, visibility 200ms ease-in-out`
  },
  megaMenuContentActive: {
    top: spacing(20)
  },
  dropDown: {
    WebkitTapHighlightColor: "transparent",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: `0 ${spacing(1)}px`,
    "&.hover .megaMenuContent": {
      visibility: "visible",
      opacity: 1
    },
    "&.hover .menuItemTitle": {
      borderBottom: "4px solid rgba(0, 0, 0, 0.84)",
      textShadow: "-0.02em 0 rgba(0, 0, 0, 0.84)", // This is to simulate the bold text effect
      WebkitTextStrokeWidth: "0.02em" // As the rule above, but for Safari and Webkit based browsers
    }
  },
  menuItemTitle: {
    zIndex: 1000,
    width: "fit-content",
    padding: `${spacing(2.5)}px ${spacing(0.5)}px ${spacing(2.125)}px`,
    textTransform: "uppercase",
    borderBottom: "4px solid transparent",
    textAlign: "center",
    display: "flex",
    alignItems: "flex-end",
    "& a": { color: palette.rapunzel.textDefault },
    transition: `all 200ms ease-in-out`,
    textShadow: "none",
    WebkitTextStrokeWidth: "none",
    color: palette.rapunzel.textDefault
  },
  searchActive: {
    borderBottom: `${spacing(0.5)}px solid ${palette.text.primary}`
  },
  searchButton: {
    [breakpoints.up("lg")]: {
      marginBottom: spacing(1)
    },
    "&.active": {
      marginBottom: spacing(0.5)
    }
  },
  menuItem: {
    letterSpacing: "0.06em"
  },
  significantMenuItem: {
    color: palette.rapunzel.textDiscount
  },
  verticalDivider: {
    height: spacing(4),
    borderRight: "0.5px solid #DDD",
    borderLeft: "0.5px solid #DDD",
    marginBottom: spacing(1),
    [breakpoints.down("md")]: {
      display: "none"
    }
  },
  divider: {
    width: 400,
    borderBottom: "2px solid #000",
    marginBottom: -10,
    marginTop: 14,
    [breakpoints.down("md")]: {
      display: "none"
    }
  },
  dividerActive: {
    marginTop: 16
  },
  iconBoxListWrapper: {
    display: "flex",
    alignItems: "center"
  },
  iconBox: {
    display: "flex"
  },
  basketCollapsed: {
    visibility: "hidden",
    opacity: 0,
    transition: `opacity 200ms ease-in-out, visibility 200ms ease-in-out`
  },
  basketCollapsedVisible: {
    visibility: "visible",
    opacity: 1
  }
}))

export default MegaMenu
