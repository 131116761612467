import api from "@starrepublic/epi/api"
import { SiteSettings as ContentSiteSettings } from "@starrepublic/epi/types/cms/content"
import { MenuItem } from "@starrepublic/epi/types/cms/menu"
import { asyncState, createStore } from "global-hook-store"
import SiteSettings, { Link, Promotion } from "models/SiteSettings"
import { FooterContent } from "types/content/footer"

type Menus = Record<"top" | "footer" | "pinnedLink" | "main", MenuItem[]>
type Links = Record<
  | "privacyPolicy"
  | "termsAndConditions"
  | "forgotPassword"
  | "login"
  | "registerAccount"
  | "help"
  | "checkout"
  | "orderConfirmation"
  | "allProducts"
  | "profilePage"
  | "ordersPage"
  | "orderDetailsPage"
  | "editUserInfoPage"
  | "customerCare"
  | "convertColor",
  Link | undefined
>

type Newsletter = {
  enabled: boolean
  cookieTimeout: number
  popupDelaySecs: number
}

const siteSettingsStore = createStore(
  {
    settings: asyncState<ContentSiteSettings<SiteSettings>>(),
    menus: {} as Menus,
    menuSignificantWords: [] as string[],
    lang: "",
    defaultMeasureUnit: "",
    links: {} as Links,
    promotions: [] as Promotion[],
    newsletter: {} as Newsletter,
    footerContent: undefined as FooterContent | undefined,
    trustCertificateImage: undefined as string | undefined,
    freeShippingLabel: "",
    promotionBarBackgroundColor: "#EBADAA",
    promotionBarTextColor: "#1b1b1b",
    enableRecommendationsCarousel: false
  },
  {
    load: async (state, _payload: null, { asyncAction }) => {
      const { settings } = await asyncAction(
        "settings",
        api.site.get<SiteSettings>()
      )

      if (settings.error) {
        return { ...state, settings }
      }

      const menuByType = settings.data?.mainMenuContentArea[0]?.items?.reduce(
        (acc, curr) => {
          const id = curr._id.toString()
          acc[id.substring(id.indexOf("-") + 1)] = curr.items
          return acc
        },
        {}
      )

      const menus: Menus = {
        top: menuByType?.["TopMenu"] || [],
        footer: menuByType?.["FooterMenu"] || [],
        pinnedLink: menuByType?.["PinnedLinkMenu"] || [],
        main: menuByType?.["CatalogMenu"] || []
      }

      const menuSignificantWords = settings.data?.menuSignificantWords || []
      const promotions = settings.data?.promotions || []
      const lang = settings.data?._lang || ""
      const links = settings.data?.links.reduce((acc, curr) => {
        acc[curr.text] = curr
        return acc
      }, {}) as Links
      const newsletter = {
        cookieTimeout: settings.data?.newsletterCookieTimeout || 24,
        enabled: settings.data?.enableNewsletterPopup || false,
        popupDelaySecs: settings.data?.newsletterPopupDelaySecs || 10
      }
      const footerContent = settings.data?.footer
      const defaultMeasureUnit = settings.data?.defaultMeasureUnit || ""
      const trustCertificateImage = settings.data?.trustCertificateImage
      const freeShippingLabel = settings.data?.freeShippingLabel || ""
      const promotionBarTextColor = settings.data?.promotionBarTextColor || ""
      const promotionBarBackgroundColor =
        settings.data?.promotionBarBackgroundColor || ""
      const enableRecommendationsCarousel =
        settings.data?.enableRecommendationsCarousel || false

      return {
        ...state,
        settings,
        menus,
        menuSignificantWords,
        promotions,
        lang,
        links,
        newsletter,
        footerContent,
        defaultMeasureUnit,
        trustCertificateImage,
        freeShippingLabel,
        promotionBarBackgroundColor,
        promotionBarTextColor,
        enableRecommendationsCarousel
      }
    }
  }
)

siteSettingsStore.actions.load()

export default siteSettingsStore
