import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = {
  direction?: "left" | "right"
  stroke?: string
} & SvgIconProps

const ArrowSmall: React.FC<Props> = ({
  direction = "right",
  stroke = "#000",
  ...props
}) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="square"
      strokeWidth="2"
      d="m14 8-4 4 4 4"
      {...(direction === "right" && {
        transform: "rotate(180, 12, 12)"
      })}
    />
  </SvgIcon>
)

export default ArrowSmall
