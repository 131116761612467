import request from "@starrepublic/epi/api/request"
import { CardProduct, FacetFilter } from "models/Products"
import Variant from "models/Variant"
import qs from "qs"

export type CatalogApiParams = {
  limit: number
  offset: number
  route: string
  excludeIds: number[]
  sort?: string
  sale?: boolean
  descendants?: boolean
  filters?: Record<string, string[]>
}

export type CatalogHist = {
  limit: number
  offset: number
  totalItems: number
  value: CardProduct[]
}

export type ProductsApiResponse = {
  hits: CatalogHist
  facets?: FacetFilter[]
}

const products = {
  getProducts: ({
    route,
    limit,
    offset,
    excludeIds = [],
    sort,
    sale,
    descendants,
    filters
  }: CatalogApiParams) => {
    const query = qs.stringify(
      { ...filters, limit, offset, sort, sale, descendants },
      { indices: false }
    )

    return request.get<ProductsApiResponse>(
      `/filterproducts/${route}?${query}&excludeIds=${excludeIds.join(",")}`
    )
  },

  getVariants: (code: string) => {
    return request.get<Record<string, Variant>>(`/variants/${code}`)
  }
}

export default products
