import ButtonBase from "@material-ui/core/ButtonBase"
import { makeStyles } from "@material-ui/core/styles"
import KlearInfluencerForm from "common/KlearInfluencerForm"
import Typography from "common/Typography"
import ArrowForward from "icons/ArrowForward"
import * as React from "react"
import { Link } from "react-router-dom"

import { MenuItem } from "./types"
type Props = {
  items: MenuItem[]
}

const FooterMenu: React.FunctionComponent<Props> = ({ items }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {items &&
        items.length &&
        items.map(item => (
          <div className={classes.itemContainer} key={item._id}>
            <ButtonBase className={classes.item}>
              <Link to={item.url}>
                <Typography variant="link1" component="span" color="inherit">
                  {item.title}
                </Typography>
                <ArrowForward />
              </Link>
            </ButtonBase>
          </div>
        ))}
      <div id="gtm-cookie-modal"></div>
      <div className={classes.itemContainer}>
        <KlearInfluencerForm
          className={classes.item}
          classText={classes.linkColor}
          placement="FooterMenu"
        />
      </div>
    </div>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    [breakpoints.down("sm")]: {
      flexFlow: "wrap"
    }
  },
  itemContainer: {
    flexGrow: 1,
    [breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  item: {
    padding: `${spacing(1)}px 0`,
    "& a": {
      color: palette.primary.contrastText,
      display: "flex"
    }
  },
  linkColor: {
    color: palette.rapunzel.white,
    textDecoration: "underline",
    display: "flex"
  }
}))

export default FooterMenu
