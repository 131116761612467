import { makeStyles } from "@material-ui/core/styles"
import RelativeLink from "@starrepublic/epi/cms/components/RelativeLink"
import XhtmlString from "@starrepublic/epi/cms/components/XhtmlString"
import Typography from "common/Typography"
import { Promotion } from "models/SiteSettings"
import * as React from "react"

type PromotionProps = Promotion & {
  selected?: boolean
  promotionBarTextColor: string
}

const PromotionItem: React.FunctionComponent<PromotionProps> = ({
  title,
  link,
  promotionBarTextColor
}: PromotionProps) => {
  const classes = useStyles()

  const style = {
    color: promotionBarTextColor
  }
  return (
    <RelativeLink to={link || ""}>
      <Typography
        align="center"
        variant="link1"
        color="inherit"
        className={classes.root}
        style={style}
      >
        <XhtmlString content={title} />
      </Typography>
    </RelativeLink>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    width: "100vw",
    height: 40,
    textDecoration: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: 14,
    "&:hover": { textDecoration: "underline" }
  }
}))

export default PromotionItem
