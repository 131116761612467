import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Slide from "@material-ui/core/Slide"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import WarningIcon from "@material-ui/icons/Warning"
import LinkButton from "common/LinkButton"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

type Props = {
  warning: string | null
  resetValidationIssues: () => void
}

const CartWarningMessage: React.SFC<Props> = ({
  warning,
  resetValidationIssues
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [show, setShow] = useState(true)

  const transitionMs = 250

  const hideWarningMessage = () => {
    setShow(false)
    resetValidationIssues()
  }

  if (!warning) {
    return null
  }

  return (
    <Slide
      direction="down"
      in={show}
      timeout={{
        enter: 0,
        exit: transitionMs
      }}
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.warningContent}>
            <WarningIcon className={classes.warningIcon} />
            <Typography align="left">
              {t(`error.${warning.toLowerCase()}`, warning)}
            </Typography>
          </div>
        </CardContent>

        <CardActions className={classes.cardActions}>
          <LinkButton
            link={"#"}
            variant="body2"
            linkText={t("cart.warnings_close")}
            onClick={hideWarningMessage}
          />
        </CardActions>
      </Card>
    </Slide>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  card: {
    backgroundColor: palette.rapunzel.warningBackground,
    margin: `${spacing(1)}px ${spacing(2)}px`
  },
  cardContent: {
    paddingTop: spacing(2),
    paddingBottom: 0
  },
  cardActions: {
    paddingTop: 0,
    paddingBottom: spacing(1)
  },
  warningContent: {
    display: "flex",
    alignItems: "center"
  },
  warningIcon: {
    marginRight: spacing(2)
  }
}))

export default CartWarningMessage
