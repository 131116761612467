import CmsContext from "@starrepublic/epi/cms/components/CmsContext/context"
import OnPageEditing, {
  FetchContentProps
} from "@starrepublic/epi/cms/components/OnPageEditing"
import {
  convertContentToPage,
  getPropsInfoForContent
} from "@starrepublic/epi/cms/utils/contentConverter"
import { logDevMessage, Severity } from "@starrepublic/epi/cms/utils/logging"
import { DynamicPage } from "@starrepublic/epi/types/cms/content"
import { GetContentType } from "@starrepublic/epi/types/cms/props"
import React, { useContext, useEffect, useState } from "react"

type OwnProps = {
  autoScroll?: boolean | ((content: DynamicPage) => boolean)
  content?: DynamicPage
  scrollBackToPosition: number
} & FetchContentProps

type Props = OwnProps

// Keep here until we know if we can merge it to Core

const PageResolver: React.FC<Props> = ({
  content,
  fetchContent,
  autoScroll = true,
  scrollBackToPosition = window.scrollY
}) => {
  const [navigatedBack, setNavigatedBack] = useState(false)

  window.onpopstate = () => {
    setNavigatedBack(true)
  }

  useEffect(() => {
    if (content && autoScroll) {
      // Delay does the trick but a better approach would be to wait for the DOM to render?
      // If we do not wait when navigating back then the page might not have loaded so instead of getting to Y pos 1000 we might end up at Y POS 800
      setTimeout(
        () => {
          const scrollTo = navigatedBack ? scrollBackToPosition : 0
          setNavigatedBack(false)
          window.scrollTo(0, scrollTo)
        },
        navigatedBack ? 500 : 0
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content && content._id])

  const { isEditMode, pageMap } = useContext(CmsContext)

  if (!content) return null

  const PageComponent = pageMap[content._type]
  if (!PageComponent) {
    logDevMessage(
      // prettier-ignore
      `No page component is found for type ${content._type}, nothing to render`,
      Severity.Error
    )
    return null
  }

  type ContentType = GetContentType<typeof PageComponent>

  const page = convertContentToPage<ContentType>(content)
  const propsInfo = isEditMode ? getPropsInfoForContent(content, page) : {}

  const { data, info } = page

  return isEditMode ? (
    <OnPageEditing
      content={data}
      propsInfo={propsInfo}
      pageInfo={info}
      fetchContent={fetchContent}
    >
      {(opeData: ContentType) => (
        <PageComponent
          content={opeData}
          propsInfo={propsInfo}
          pageInfo={info}
        />
      )}
    </OnPageEditing>
  ) : (
    <PageComponent content={data} propsInfo={propsInfo} pageInfo={info} />
  )
}

export default PageResolver
