import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import Typography from "common/Typography"
import * as React from "react"
import { useTranslation } from "react-i18next"

import { FormatPrice } from "../../utils/FormatPrice"

type Props = {
  price: number
  priceString: string
  discount?: string
  includeTaxes?: boolean
  isSmall?: boolean
  className?: string
  currency?: string
}

const ProductPrice: React.FunctionComponent<Props> = ({
  price,
  priceString,
  discount,
  includeTaxes = false,
  className,
  isSmall = false,
  currency = "EUR"
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <>
      <div className={classNames(classes.root, className)}>
        <Typography
          variant={isSmall ? "priceSmall" : "priceLarge"}
          className={classNames(classes.price, {
            [classes.hasDiscount]: discount && discount !== priceString
          })}
        >
          {discount ? discount : FormatPrice(price, priceString, currency)}
        </Typography>
        {discount && discount !== priceString && (
          <Typography
            variant={isSmall ? "priceSmall" : "discountPrice"}
            className={classes.discountPrice}
          >
            {FormatPrice(price, priceString, currency)}
          </Typography>
        )}
      </div>

      {!isSmall && (
        <div className={classes.taxInfo}>
          {!includeTaxes && (
            <Typography variant="body2" color="textSecondary">
              {t("common.price_excl_taxes")}
            </Typography>
          )}
        </div>
      )}
    </>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    marginBottom: spacing(2)
  },
  hasDiscount: {
    color: palette.rapunzel.textDiscount,
    justifyContent: "center"
  },
  discountPrice: {
    marginLeft: spacing(0.75),
    textDecoration: "line-through",
    paddingTop: spacing(2)
  },
  taxInfo: {
    marginTop: `${spacing(-2)}px`
  },
  price: {
    paddingTop: spacing(2)
  }
}))

export default ProductPrice
