import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import { FastField, getIn } from "formik"
import * as React from "react"

import {
  FormikFieldProps,
  FormikTransformer,
  FormikTransformerProps
} from "./types"

const transformer: FormikTransformer<TextFieldProps> = ({
  field,
  form,
  transformValue,
  onChange,
  ...props
}) => {
  const { name, onChange: fieldOnChange, value } = field
  const { touched, errors, isSubmitting, setFieldValue } = form

  const fieldError = getIn(errors, name)
  const showError = getIn(touched, name) && !!fieldError

  return {
    ...props,
    ...field,
    value: value || "",
    onChange: e => {
      if (transformValue) {
        setFieldValue(name, transformValue(field.value, e.target.value))
      } else if (fieldOnChange) {
        fieldOnChange(e)
      }
      if (onChange) {
        onChange(e)
      }
    },
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    disabled: isSubmitting || props.disabled
  }
}

const TextFieldComponent: React.FunctionComponent<
  FormikTransformerProps<TextFieldProps>
> = props => <TextField fullWidth {...transformer(props)} />

const FormikTextField: React.FunctionComponent<
  FormikFieldProps<TextFieldProps>
> = props => {
  return <FastField component={TextFieldComponent} {...props} />
}

export default FormikTextField
