import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { toRelativeLink } from "@starrepublic/epi/cms/components/RelativeLink"
import api from "api"
import classNames from "classnames"
import { pushGaEvent, pushToDataLayer } from "common/GoogleTagManagerTracking"
import { browserHistory } from "common/Root"
import Spinner from "common/Spinners/Spinner"
import Wrapper from "common/Wrapper"
import useBreakpoint from "hooks/useBreakpoint"
import RemoveIcon from "icons/Remove"
import SearchIcon from "icons/Search"
import { Hit, SearchResult, SearchType } from "models/Search"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Transition } from "react-transition-group"
import { from, fromEvent, never, Observable, of, timer } from "rxjs"
import {
  debounce,
  distinctUntilChanged,
  map,
  onErrorResumeNext,
  switchMap
} from "rxjs/operators"

import ProductResult from "./ProductResult"
import SearchResultText from "./SearchResultText"
import SearchSection from "./SearchSection"

type OwnProps = {
  onCloseClick: () => void
  collapsedHeader?: boolean
  isSearchOpen?: boolean
}

type Props = OwnProps

type SearchHitMap = Record<string, Hit[]>

type Result = {
  hitMap: SearchHitMap
} & SearchResult

const SearchVariant: React.FC<Props> = ({
  onCloseClick,
  collapsedHeader = false,
  isSearchOpen = false
}) => {
  const [results, setResults] = useState<Result | undefined>(undefined)
  const [keyIndex, setKeyIndex] = useState<number | undefined>(undefined)
  const [query, setQuery] = useState("")
  const [suggestions, setSuggestions] = useState<Array<string>>([])
  const [popularSearches, setPopularSearches] = useState<Array<string>>([])
  const [searching, setSearching] = useState(false)
  const [active, setActive] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const openedFromHeaderScrollPosition = useRef(0)
  const classes = useStyles()
  const { t } = useTranslation()

  const rootRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!active) {
      return
    }
    if (popularSearches.length === 0) {
      api.search.popular().then(data => {
        setPopularSearches(data || [])
      })
    }
    pushGaEvent({
      category: "Search",
      action: "Search Intention"
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  useEffect(() => {
    if (isSearchOpen) {
      if (inputRef.current) {
        inputRef.current.focus()
      }
      openedFromHeaderScrollPosition.current = window.pageYOffset
    }
  }, [isSearchOpen])

  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Debounce for autocomplete
  useEffect(() => {
    if (!inputRef.current) {
      return
    }

    const subscription = fromEvent<React.ChangeEvent<HTMLInputElement>>(
      inputRef.current,
      "input"
    )
      .pipe(
        map(e => e.target.value),
        debounce(q => timer((q === "" && 0) || 250)),
        distinctUntilChanged(),
        switchMap((q): Observable<string[]> => {
          if (q === "") {
            return of([])
          }
          return from(api.search.autocomplete(q)).pipe(
            onErrorResumeNext(never())
          )
        })
      )
      .subscribe(suggestions => {
        setSuggestions(suggestions)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  const onScroll = () => {
    const currentScroll = window.pageYOffset
    if (
      openedFromHeaderScrollPosition.current > 0 &&
      currentScroll > openedFromHeaderScrollPosition.current
    ) {
      openedFromHeaderScrollPosition.current = 0
      onCloseClick()
    }
  }

  const search = useCallback(async (q: string) => {
    if (q === undefined || q.length < 2 || searching || q.length >= 100) return
    setSuggestions([])
    setKeyIndex(-1)
    setQuery(q)

    setSearching(true)

    const searchResult = await api.search.search(q)

    const results: Result = {
      ...searchResult,
      hitMap: searchResult.hits.reduce((acc, hit) => {
        if (!acc[hit.hitType]) {
          acc[hit.hitType] = []
        }
        acc[hit.hitType].push(hit)
        return acc
      }, {} as SearchHitMap)
    }

    setSearching(false)

    pushToDataLayer({
      event: "searchEvent",
      searchterm: q.toLowerCase()
    })

    setResults(results)
    setExpanded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Debounce for search
  useEffect(() => {
    if (!inputRef.current) {
      return
    }

    const subscription = fromEvent<React.ChangeEvent<HTMLInputElement>>(
      inputRef.current,
      "input"
    )
      .pipe(
        map(e => e.target.value),
        debounce(q => timer((q === "" && 0) || 500)),
        distinctUntilChanged()
      )

      .subscribe(q => {
        search(q)
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [search, inputRef])

  const onSearchResultClick =
    (
      type: "Product" | "Article" | "Popular Search" | "Suggestion",
      q: string | Hit
    ) =>
    () => {
      const event = {
        category: "Search",
        action: "Click"
      }

      if (typeof q !== "string") {
        pushGaEvent({
          ...event,
          label: `${type} - ${q.title}`
        })
        handleCloseClick()
        api.search.track(query, q.hitId, results?.trackId || "")
        browserHistory.push(toRelativeLink(q.url))
      } else {
        pushGaEvent({
          ...event,
          label: `${type} - ${q}`
        })
        search(q)
      }
    }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (e.key) {
      case "Enter":
        if (inputRef && inputRef.current instanceof HTMLInputElement) {
          return search(inputRef.current.value)
        }
        return
      case "Tab":
        search(suggestions[0])
        return
      case "ArrowDown":
        setKeyIndex(((keyIndex || 0) + 1) % suggestions.length)
        return
      case "ArrowUp":
        setKeyIndex(
          (suggestions.length + ((keyIndex || 0) - 1)) % suggestions.length
        )
        return
    }
  }

  const handleSearchButtonClick = () => {
    if (inputRef.current) {
      if (inputRef.current.value.length === 0) {
        inputRef.current.focus()
      } else {
        search(inputRef.current.value)
      }
    }
  }

  const handleCloseClick = () => {
    openedFromHeaderScrollPosition.current = 0
    setExpanded(false)
    setQuery("")
    setResults(undefined)
    if (isSearchOpen) {
      onCloseClick()
    }
  }

  const articles = (results && results.hitMap[SearchType.ARTICLE]) || []
  const products = (results && results.hitMap[SearchType.PRODUCT]) || []

  const isSmall = useBreakpoint("sm")
  const contHeight = isSmall ? "56px" : "64px"

  const transitionStyles = {
    entering: { height: "0px" },
    entered: { height: "0px" },
    exiting: { height: contHeight },
    exited: { height: contHeight }
  }

  const collapsed = collapsedHeader && !expanded && !isSearchOpen

  return (
    <div ref={rootRef}>
      <Transition in={collapsed} timeout={{ enter: 500, exit: 500 }}>
        {state => (
          <div
            className={classNames(
              classes.root,
              active ? classes.rootActive : "",
              expanded ? classes.rootExpanded : ""
            )}
            style={{
              ...transitionStyles[state]
            }}
          >
            <Wrapper
              className={classes.inputWrapper}
              containerClassName={classes.inputWrapperContainer}
            >
              <TextField
                onChange={e => {
                  setKeyIndex(-1)
                  setQuery(e.target.value)
                }}
                onFocus={() => {
                  setActive(true)
                  if (results) {
                    setExpanded(true)
                  }
                }}
                onBlur={() => {
                  setActive(false)
                }}
                value={query}
                className={classes.inputTextField}
                InputProps={{
                  className: classes.input,
                  disableUnderline: true,
                  placeholder: t("search.hint")
                }}
                inputProps={{
                  className: classes.inputField,
                  onKeyDown: handleKeyDown,
                  maxLength: 48,
                  ref: inputRef
                }}
                variant="standard"
              />
              <div className={classes.searchIconContainer}>
                {!searching ? (
                  <div
                    onClick={handleSearchButtonClick}
                    className={classes.searchIconWrapper}
                  >
                    <SearchIcon className={classes.searchIcon} />
                  </div>
                ) : (
                  <Spinner size="sm" className={classes.searchIcon} />
                )}
              </div>
              {expanded && (
                <IconButton
                  className={classes.closeButton}
                  onClick={handleCloseClick}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </Wrapper>
          </div>
        )}
      </Transition>
      {expanded && (
        <ClickAwayListener
          onClickAway={event => {
            if (
              rootRef.current &&
              !rootRef.current.contains(event.target as Node)
            ) {
              event.stopPropagation()
              event.preventDefault()
              handleCloseClick()
            }
          }}
        >
          <Wrapper
            className={classes.contentWrapper}
            grid
            containerClassName={classes.content}
          >
            <Grid className={classes.results} item xs={12} sm={12} md={6}>
              {products.length > 0 && (
                <SearchSection
                  divider
                  title={t("search.products")}
                  hitCount={products.length}
                >
                  {products.map((h, i) => (
                    <ProductResult
                      onClick={onSearchResultClick("Product", h)}
                      key={i}
                      product={h}
                    />
                  ))}
                </SearchSection>
              )}

              {articles.length > 0 && (
                <SearchSection
                  title={t("search.articles")}
                  hitCount={articles.length}
                >
                  {articles.map((h, i) => (
                    <SearchResultText
                      key={i}
                      onClick={onSearchResultClick("Article", h)}
                    >
                      {h.title}
                    </SearchResultText>
                  ))}
                </SearchSection>
              )}
            </Grid>

            <Grid className={classes.suggestions} item xs={12} sm={12} md={6}>
              {suggestions.length > 0 && (
                <SearchSection title={t("search.suggestions")}>
                  {suggestions.map((p, i) => (
                    <SearchResultText
                      className={classNames({
                        [classes.unSelectedSuggestion]:
                          keyIndex !== -1 && i !== keyIndex
                      })}
                      key={i}
                      onClick={onSearchResultClick("Suggestion", p)}
                    >
                      {p}
                    </SearchResultText>
                  ))}
                </SearchSection>
              )}
              <SearchSection title={t("search.popular_searches")}>
                {popularSearches.map((p, i) => (
                  <SearchResultText
                    key={i}
                    onClick={onSearchResultClick("Popular Search", p)}
                  >
                    {p}
                  </SearchResultText>
                ))}
              </SearchSection>
            </Grid>
          </Wrapper>
        </ClickAwayListener>
      )}
    </div>
  )
}

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  root: {
    backgroundColor: "#fff",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "height 300ms ease-in-out",
    position: "relative",
    height: spacing(8),
    overflow: "hidden",
    [breakpoints.down("sm")]: {
      position: "absolute",
      top: spacing(8),
      boxShadow: "0 16px 8px 8px rgba(0,0,0,0.05)",
      zIndex: 1
    }
  },
  rootActive: {},
  rootExpanded: {
    [breakpoints.down("sm")]: {
      top: 0
    }
  },
  [breakpoints.down("sm")]: {
    "@global": {
      body: {
        overflowY: "hidden"
      }
    }
  },
  contentWrapper: {
    overflowY: "scroll",
    paddingTop: spacing(8),
    [breakpoints.down("sm")]: {
      paddingTop: `0 !important`,
      position: "absolute",
      top: spacing(8),
      width: "100vw",
      height: `calc(100vh - ${spacing(8)}px)`,
      background: "#fff"
    }
  },
  inputTextField: {
    flexGrow: 1
  },
  input: {
    height: "100%"
  },
  inputField: {
    background: "#fbf1ea",
    borderTopLeftRadius: spacing(1),
    borderBottomLeftRadius: spacing(1),
    padding: "0 24px",
    lineHeight: `${spacing(6)}px`,
    height: spacing(6),
    [breakpoints.down("sm")]: {
      lineHeight: `${spacing(5)}px`,
      height: spacing(5)
    },
    "&::-ms-clear": {
      display: "none"
    }
  },
  closeIcon: {
    [breakpoints.down("sm")]: {
      margin: `0 ${spacing(-1)}px`
    }
  },
  cleanIcon: {
    color: palette.rapunzel.dark
  },
  unSelectedSuggestion: {
    opacity: 0.56
  },
  searchIconContainer: {
    background: "#000",
    color: "#fff",
    height: "100%",
    width: spacing(10),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopRightRadius: spacing(1),
    borderBottomRightRadius: spacing(1),
    [breakpoints.down("sm")]: {
      width: spacing(8)
    }
  },
  searchIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    cursor: "pointer"
  },
  searchIcon: {},
  content: {
    padding: `${spacing(4)}px ${spacing(1)}px`
  },
  inputWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    paddingBottom: spacing(1),
    [breakpoints.down("sm")]: {
      paddingRight: spacing(3)
    }
  },
  hint: {
    fontSize: "1.125rem",
    lineHeight: "1.125rem"
  },
  inputWrapperContainer: {
    display: "flex",
    flexDirection: "row",
    height: spacing(6),
    alignItems: "center",
    [breakpoints.down("sm")]: {
      height: spacing(5)
    }
  },
  results: {
    [breakpoints.up("md")]: {
      borderRight: `1px solid ${palette.rapunzel.alto}`
    }
  },
  suggestions: {
    [breakpoints.up("md")]: {
      paddingLeft: spacing(4)
    }
  }
}))

export default SearchVariant
