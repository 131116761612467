import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import { makeStyles } from "@material-ui/core/styles"
import newsLetter from "api/newsletter"
import CustomTextField from "common/Formik/CustomTextField"
import { pushNewsletterAction } from "common/GoogleTagManagerTracking"
import Spinner from "common/Spinners/Spinner"
import Typography from "common/Typography"
import { Form as FormikForm, Formik, FormikActions } from "formik"
import useBreakpoint from "hooks/useBreakpoint"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"

type Props = {
  setIsSignupDone: React.Dispatch<React.SetStateAction<boolean>>
}

const NewsletterDialog: React.FunctionComponent<Props> = ({
  setIsSignupDone
}) => {
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const isMobile = useBreakpoint("sm")
  const classes = useStyles()
  const { t } = useTranslation()
  const newsletterStorageRegistered = "NewsleterModalRegistered"

  type FormType = {
    email: string
  }

  const validationSchema = yup.object<FormType>().shape({
    email: yup
      .string()
      .required(t("member.email_required_message") as string)
      .email(t("member.email_invalid_message") as string)
  })

  const saveSignupToLocalStorage = () => {
    window.localStorage.setItem(newsletterStorageRegistered, "true")
  }

  const handleSubmit = async (
    values: FormType,
    { setSubmitting }: FormikActions<FormType>
  ) => {
    if (isLoading) {
      return
    }

    setIsLoading(true)

    let hasError = false

    await newsLetter
      .post(email)
      .catch(err => {
        hasError = true

        if (err === "EMAIL_ALREADY_REGISTERED") {
          setErrorMessage(t("error.email_already_registered"))
        } else {
          setErrorMessage(err.toString())
        }
      })
      .then(() => {
        if (!hasError) {
          saveSignupToLocalStorage()
          pushNewsletterAction("email", "Subscribe", "NewsletterDialog")
        }
      })
      .finally(() => {
        setIsLoading(false)
        setSubmitting(false)
        setIsSignupDone(!hasError)
      })
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialValues={{ email: "" }}
    >
      {({ isValid }) => (
        <FormikForm className={classes.form}>
          <DialogTitle disableTypography className={classes.dialogTitle}>
            <Typography
              align="center"
              variant={isMobile ? "display7" : "display3"}
              className={classes.dialogHeading}
            >
              {t("newsletter.welcome_title")}
            </Typography>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <Typography align="center" paragraph>
              {t("newsletter.welcome_text1")}
            </Typography>

            <FormControl className={classes.emailInput}>
              <CustomTextField
                required
                name="email"
                label={t("newsletter.welcome_email_label")}
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </FormControl>

            <Typography paragraph>{t("newsletter.welcome_text2")}</Typography>

            {errorMessage && (
              <Typography
                variant="body1"
                color="error"
                className={classes.errorMessage}
              >
                {errorMessage}
              </Typography>
            )}
          </DialogContent>

          <DialogActions className={classes.dialogActions}>
            <Button
              type="submit"
              disabled={!isValid || isLoading}
              className={classes.ctaButton}
            >
              {isLoading && <Spinner size="sm" className={classes.spinner} />}
              {t("newsletter.welcome_register_button")}
            </Button>
          </DialogActions>
        </FormikForm>
      )}
    </Formik>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, typography, palette }) => ({
    form: {
      padding: spacing(4),
      [breakpoints.down("sm")]: {
        padding: spacing(2)
      }
    },
    dialogTitle: {
      [breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    dialogContent: {
      [breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    dialogActions: {
      [breakpoints.down("sm")]: {
        marginLeft: 0,
        marginRight: 0
      }
    },
    dialogHeading: {
      [breakpoints.down("sm")]: {
        fontSize: typography.fontSize * 1.5
      }
    },
    ctaButton: {
      textAlign: "center",
      color: palette.rapunzel.white,
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      flexGrow: 1,
      border: `1px solid ${palette.rapunzel.black}`,
      backgroundColor: palette.rapunzel.black,
      "&:hover": {
        backgroundColor: palette.rapunzel.white
      },
      "&[disabled]": {
        backgroundColor: palette.rapunzel.lightGray,
        color: "#000",
        cursor: "default"
      }
    },
    emailInput: {
      paddingTop: spacing(4),
      paddingBottom: spacing(4),
      width: "100%"
    },
    spinner: {
      marginRight: spacing(1)
    },
    errorMessage: {
      paddingTop: spacing(2)
    }
  })
)

export default NewsletterDialog
