import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import Clear from "@material-ui/icons/Clear"
import RelativeLink from "@starrepublic/epi/cms/components/RelativeLink"
import Spinner from "common/Spinners/Spinner"
import Typography from "common/Typography"
import useStore from "global-hook-store"
import useBreakpoint from "hooks/useBreakpoint"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import cartDrawerStore from "stores/cartDrawerStore"
import cartStore from "stores/cartStore"
import siteSettingsStore from "stores/siteSettingsStore"

import AppliedCoupon from "./AppliedCoupon"
import CartShipping from "./CartShipping"
import CartSummary from "./CartSummary"
import CartWarningMessage from "./CartWarningMessage"
import CheckoutButton from "./CheckoutButton"
import OrderDetails from "./OrderDetails"

const ShoppingCart: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  const {
    actions: { setCartOpen }
  } = useStore(cartDrawerStore)

  const {
    state: { links }
  } = useStore(siteSettingsStore)

  const {
    state: {
      carts: { data: carts, loading },
      _currentItem: { data: currentItem },
      addingItem
    },
    actions: { fetchCarts, resetValidationIssues }
  } = useStore(cartStore)

  const isMobile = useBreakpoint("sm")

  useEffect(() => {
    if (!carts.default) fetchCarts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeCart = () => {
    setCartOpen(false)
    document.documentElement.removeAttribute("style")
    document.body.removeAttribute("style")
  }

  const showCartSpinner =
    loading ||
    (carts &&
      addingItem &&
      (!carts.default ||
        (carts.default !== null && carts.default.lineItems.length === 0)))
  return (
    <>
      {carts &&
        carts.default &&
        carts.default.properties &&
        carts.default.properties["freeShippingConditionAmountString"] && (
          <CartShipping basket={carts.default} />
        )}
      <div
        className={`${classes.header} ${
          !carts.default ||
          carts.default.lineItems.length === 0 ||
          (carts &&
            carts.default &&
            carts.default.properties &&
            !carts.default.properties["freeShippingConditionAmountString"])
            ? classes.headerEmpty
            : classes.headerShipping
        }`}
      >
        <Typography
          variant="display8"
          color="textPrimary"
          className={classes.headerTitle}
        >
          {t("cart.title")}
        </Typography>
        <IconButton onClick={closeCart} className={classes.closeCart}>
          <Clear className={classes.closeIcon} />
        </IconButton>
      </div>
      <div className={classes.container}>
        {(showCartSpinner && (
          <div className={classes.spinnerContainer}>
            <Spinner />
          </div>
        )) || (
          <>
            {!isMobile &&
              currentItem &&
              currentItem.validationIssues &&
              currentItem.validationIssues.map((issue, i) => (
                <CartWarningMessage
                  key={`warning-${i}`}
                  warning={issue.value}
                  resetValidationIssues={resetValidationIssues}
                />
              ))}
            <div className={classes.orderDetailsContainer}>
              <OrderDetails />
            </div>
          </>
        )}
        <div className={classes.couponApplied}>
          <AppliedCoupon />
        </div>
        <div className={classes.cartDetails}>
          <CartSummary addingItem={addingItem} basket={carts.default} />
          {carts.default &&
            carts.default !== null &&
            carts.default.lineItems.length > 0 &&
            !loading && (
              <RelativeLink
                to={(links.checkout && links.checkout.href) || ""}
                onClick={closeCart}
                className={classes.checkoutButton}
              >
                <CheckoutButton />
              </RelativeLink>
            )}
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  header: {
    display: "flex",
    alignItems: "center",
    height: spacing(5),
    paddingLeft: "52px",
    paddingRight: "10px",
    [breakpoints.down("sm")]: {
      paddingRight: spacing(2)
    }
  },
  headerEmpty: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  headerShipping: {
    backgroundColor: palette.rapunzel.white,
    position: "relative",
    top: "-20px",
    paddingTop: spacing(2.5)
  },
  headerTitle: {
    flexGrow: 2,
    textAlign: "center",
    paddingTop: spacing(1)
  },
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    maxHeight: "calc(100vh - 200px)",
    [breakpoints.down("sm")]: {
      maxHeight: "calc(100vh - 240px)"
    }
  },
  spinnerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flex: 1
  },
  closeIcon: {
    color: palette.rapunzel.dark,
    marginTop: spacing(1)
  },
  closeCart: {
    marginRight: spacing(1),
    padding: "0 4px 4px",
    [breakpoints.down("sm")]: {
      marginRight: spacing(0.25)
    },
    [breakpoints.down("xs")]: {
      marginRight: spacing(1)
    }
  },
  checkoutButton: {
    position: "absolute",
    right: spacing(1),
    bottom: spacing(2)
  },
  orderDetailsContainer: {
    overflowY: "auto",
    paddingRight: spacing(3),
    paddingLeft: spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1
  },
  cartDetails: {
    display: "flex",
    flex: "1 0 auto",
    justifyContent: "space-between",
    minHeight: "88px",
    alignItems: "center",
    marginLeft: spacing(2),
    marginRight: spacing(1),
    marginBottom: spacing(0.5)
  },
  couponApplied: {
    marginLeft: spacing(2),
    marginRight: spacing(3)
  }
}))

export default ShoppingCart
