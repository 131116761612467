import * as React from "react"

import { editorAttributes } from ".."
import {
  AdditionalBlockInfo,
  DynamicBlock,
  PageInfo,
  PropertyInfo
} from "../../types/cms/content"
import { GetContentType } from "../../types/cms/props"
import {
  convertContentToBlock,
  getPropsInfoForContent
} from "../utils/contentConverter"
import { logDevMessage, Severity } from "../utils/logging"
import CmsContext from "./CmsContext/context"

type BlockResolverProps = {
  content?: DynamicBlock
  blockInfo?: PropertyInfo
  pageInfo?: PageInfo
  className?: string
  componentProps?: Record<string, any>
} & AdditionalBlockInfo

const BlockResolver: React.FunctionComponent<BlockResolverProps> = ({
  content,
  blockInfo,
  pageInfo,
  getAdditionalBlockInfo,
  className,
  componentProps
}) => {
  const { isEditMode, blockMap, formMap } = React.useContext(CmsContext)

  if (!content) return null

  const Component = blockMap[content._type] || formMap[content._type]
  if (!Component) {
    // prettier-ignore
    logDevMessage(
      `No content component is found for type ${content._type}, no component to render`,
      Severity.Error
    )
    return null
  }

  type ContentType = GetContentType<typeof Component>

  const block = convertContentToBlock<ContentType>(content)

  // block properties shouldn't be decorated with edit attributes if the block
  // is displayed as property of a page, but only in preview mode for the block
  const propsInfo = isEditMode ? getPropsInfoForContent(pageInfo, block) : {}

  const renderComponent = (
    <Component
      getAdditionalBlockInfo={getAdditionalBlockInfo}
      content={block.data}
      blockInfo={{ ...blockInfo, ...block.info }}
      pageInfo={pageInfo}
      propsInfo={propsInfo}
      className={className}
      {...componentProps}
    />
  )

  return (
    (isEditMode && (
      <div {...editorAttributes(blockInfo)} data-epi-block-id={block.info._id}>
        {renderComponent}
      </div>
    )) ||
    renderComponent
  )
}

export default BlockResolver
