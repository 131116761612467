import { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Flag: React.FunctionComponent<Props> = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path fill="#ffda44" d="M0 85.3h512v341.3H0z" />
    <g fill="#d80027">
      <path d="M0 85.3h512v113.8H0zM0 312.9h512v113.8H0z" />
    </g>
  </svg>
)

export default Flag
