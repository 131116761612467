export const FormatPrice = (
  price: number,
  priceString: string,
  currency: string | undefined = "EUR"
) => {
  if (!price) {
    return priceString
  }

  const countryCode = currencyToCountryCode(currency)

  const stringPrice = new Intl.NumberFormat(countryCode, {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(price)

  return stringPrice
}

function currencyToCountryCode(currency?: string | undefined) {
  switch (currency) {
    case "USD":
      return "en-US"
    case "EUR":
      return "de-DE"
    case "GBP":
      return "en-GB"
    case "DKK":
      return "da-DK"
    case "SEK":
      return "en-SE"
    case "NOK":
      return "nb-NO"
    default:
      return "en-GB"
  }
}
