import ButtonBase from "@material-ui/core/ButtonBase"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import Typography from "common/Typography"
import React from "react"

type Props = {
  className?: string
  secondary?: string
  onClick?: () => void
}

const SearchResultText: React.FC<Props> = ({
  className,
  children,
  onClick,
  secondary
}) => {
  const classes = useStyles()

  const content = (
    <div className={classes.root}>
      <Typography className={classes.primary} variant="body1">
        {children}
      </Typography>
      {secondary && (
        <Typography className={classes.secondary} variant="body2">
          {secondary}
        </Typography>
      )}
    </div>
  )

  const allClasses = classNames(classes.button, className)

  return (
    (onClick && (
      <ButtonBase
        disableRipple={!onClick}
        className={allClasses}
        onClick={onClick}
      >
        {content}
      </ButtonBase>
    )) || <div className={allClasses}>{content}</div>
  )
}

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  root: {
    padding: `${spacing(0.5)}px 0`,
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    [breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "start"
    }
  },
  primary: {},
  secondary: {
    color: palette.rapunzel.textSecondary,
    [breakpoints.up("md")]: {
      marginLeft: spacing(4)
    }
  },
  button: {
    padding: `${spacing(0.5)}px ${spacing(1)}px`,
    marginLeft: spacing(-1),
    display: "flex",
    width: "100%",
    justifyContent: "left"
  }
}))

export default SearchResultText
