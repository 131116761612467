import request from "./request"

export type Cart<P> = {
  name: string
  status: string
  currency: string
  customerId: string
  created: Date
  modified: Date
  marketId: string
  marketName: string
  couponCodes: string[]
  addressId?: any
  shippingTotal: number
  shippingTotalString: string
  taxTotal: number
  taxTotalString: string
  subTotal: number
  subTotalString: string
  total: number
  totalString: string
  billingCurrency?: any
  owner?: any
  handlingTotal: number
  handlingTotalString: string
  lineItems: LineItem<P>[]
  discountTotal: number
  discountTotalString: string
  orderGroupId: number
}

export type CartSummary = {
  name: string
  lineItemCount: number
  totalString: string
}

type PriceData = {
  multiPrice: boolean
  originalPrice: number
  price: number
  priceString: string
}

export type LineItem<T> = {
  lineItemId: number
  code: string
  productCode: string
  displayName: string
  displayName2: string
  productName: string
  productName2: string
  isGift: boolean
  quantity: number
  placedPrice: number
  placedPriceString: string
  extendedPrice: number
  extendedPriceString: string
  discountedPrice: number
  discountedPriceString: string
  priceData: PriceData
  ancestors: string[]
} & T

export type LineItemRequest = {
  quantity: number
}

const carts = {
  getAll: <T>() => request.get<Cart<T>[]>("/carts"),

  getByCartTypeName: <T>(cartTypeName: string) =>
    request.get<Cart<T>>(`/carts/${cartTypeName}`),
  deleteByCartTypeName: (cartTypeName: string) =>
    request.delete(`/carts/${cartTypeName}`),
  createByCartTypeName: <T>(cartTypeName: string) =>
    request.post<Cart<T>>(`/carts/${cartTypeName}`),

  summary: (cartTypeName: string) =>
    request.get<CartSummary>(`/carts/${cartTypeName}/summary`),

  items: {
    getAllByCartTypeName: <T>(cartTypeName: string) =>
      request.get<LineItem<T>[]>(`/carts/${cartTypeName}/items`),
    deleteAllByCartTypeName: (cartTypeName: string) =>
      request.delete(`/carts/${cartTypeName}/items`),
    getByItemCode: <T>(cartTypeName: string, itemCode: string) =>
      request.get<LineItem<T>>(`/carts/${cartTypeName}/items/${itemCode}`),
    deleteByItemCode: (cartTypeName: string, itemCode: string) =>
      request.delete(`/carts/${cartTypeName}/items/${itemCode}`),
    addByItemCode: <T>(
      cartTypeName: string,
      itemCode: string,
      model: LineItemRequest
    ) =>
      request.post<Cart<T>>(`/carts/${cartTypeName}/items/${itemCode}`, model),
    updateByItemCode: <T>(
      cartTypeName: string,
      itemCode: string,
      model: LineItemRequest
    ) => request.put<Cart<T>>(`/carts/${cartTypeName}/items/${itemCode}`, model)
  }
}

export default carts
