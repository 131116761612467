import { Theme, useTheme } from "@material-ui/core/styles"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { isSearchBot } from "utils/device"
const useBreakpoint = (breakpoint: Breakpoint | number, noSsr = true) => {
  const theme = useTheme<Theme>()

  const mediaQueryResult = useMediaQuery(theme.breakpoints.down(breakpoint), {
    // MUI 4.x bug:
    // noSsr must be set to true to avoid image scaling issues etc (default MUI returns false on first render for queries).
    // This application using Prerender.io to server static pages and do not use SSR.
    // MUI 5.x:
    // This issue is resolved and remove this if MUI is upgraded to 5.x in the application.
    noSsr
  })

  if (isSearchBot()) {
    switch (breakpoint) {
      case "xs":
        return false
      case "sm":
        return false
      case "md":
        return true
      case "lg":
        return false
      case "xl":
        return false
    }
  }

  return mediaQueryResult
}

export default useBreakpoint
