import request from "@starrepublic/epi/api/request"
import Cart from "models/Cart"
import CheckoutResponse from "models/Checkout"

export type NewsletterUpdateRequest = {
  key: string
  checked: boolean
}

const checkout = {
  getCheckout: (cartTypeName: string) =>
    request.get<CheckoutResponse>(`/carts/${cartTypeName}/checkout`),
  addCouponCode: (cartTypeName: string, couponCode: string) =>
    request.post<Cart>(`/carts/${cartTypeName}/coupon/${couponCode}`),
  deleteCouponCode: (cartTypeName: string, couponCode: string) =>
    request.delete<Cart>(`/carts/${cartTypeName}/coupon/${couponCode}`),
  refreshKlarnaCart: () => request.get<Cart[]>("/checkout/refresh"),
  newsletterUpdate: (update: NewsletterUpdateRequest) =>
    request.post<void>("/checkout/newsletterupdate", update)
}

export default checkout
