import { ProductMeta, VariationMeta } from "../commerce/models/catalog"
import { ContentInfo } from "../types/cms/content"
import { SimpleLink } from "../types/cms/general"
import request from "./request"

type Variation<T> = {
  _inventory: InventoryItem[]
  _meta: VariationMeta
  name?: string
  status: string
  assets: Asset[]
} & BaseProduct &
  T

type InventoryItem = {
  warehouseCode: string
  warehouse: string
  purchaseAvailableQuantity: number
  purchaseAvailableQuantityText: string
  purchaseAvailableUtc: Date
}

type BaseProduct = ContentInfo & {
  _route: string
  code: string
  title: string
}

export type ProductListItem<T> = BaseProduct & T

type Asset = {
  url: string
  _id: number
  color: string
  group: string
  width: number
  height: number
}

export type ProductContent<T> = BaseProduct & {
  _meta: ProductMeta
  _parents: SimpleLink[]
  heading: string
  assets: Asset[]
} & T

type ProductsApiResponse<T> = {
  limit: number
  offset: number
  totalItems: number
  value: ProductListItem<T>[]
}

type ProductsApiParams = {
  limit: number
  offset: number
  route: string
  excludeIds: number[]
  sort?: string
  filters: { [key: string]: string[] }
}

type ProductVariationsResponse<T> = {
  limit: number
  offset: number
  totalItems: number
  value: Variation<T>[]
}

const products = {
  getProducts: <T>({
    route,
    limit,
    offset,
    sort,
    excludeIds = []
  }: ProductsApiParams) =>
    request.get<ProductsApiResponse<T>>(
      `/products${route}?limit=${limit}&offset=${offset}&` +
        `excludeIds=${excludeIds.join(",")}&sort=${sort}`
    ),
  getVariations: <T>(productId: number) =>
    request.get<ProductVariationsResponse<T>>(
      `/products/${productId}/variations`
    ),
  getVariation: <T>(variationId: number) =>
    request.get<Variation<T>>(`/products/${variationId}`)
}

export default products
