import { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Flag: React.FunctionComponent<Props> = props => (
  <svg viewBox="0 0 512 512" {...props}>
    <path fill="#f0f0f0" d="M0 85.3h512v341.3H0z" />
    <path fill="#a2001d" d="M0 85.3h512v113.8H0z" />
    <path fill="#0052b4" d="M0 312.9h512v113.8H0z" />
  </svg>
)

export default Flag
