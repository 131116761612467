import IconButton, { IconButtonProps } from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import useStore from "global-hook-store"
import CloseIcon from "icons/Remove"
import SearchIcon from "icons/Search"
import * as React from "react"
import authStore from "stores/authStore"

export enum DisplayedIcon {
  Search = "SEARCH",
  Close = "CLOSE"
}

type OwnProps = {
  className?: string
  iconClassName?: string
  displayedIcon?: DisplayedIcon
}

type Props = OwnProps & IconButtonProps

const SearchButton: React.FC<Props> = ({
  className,
  iconClassName,
  displayedIcon = DisplayedIcon.Search,
  onClick
}) => {
  const {
    state: { isLoggedIn }
  } = useStore(authStore)

  const classes = useStyles()

  return (
    <IconButton
      aria-label={`${
        displayedIcon === DisplayedIcon.Search ? "Open" : "Close"
      } Search`}
      className={classNames(className)}
      onClick={onClick}
    >
      {displayedIcon === DisplayedIcon.Search ? (
        <SearchIcon
          className={classNames(classes.searchIcon, iconClassName, {
            [classes.searchIconLoggedIn]: isLoggedIn
          })}
        />
      ) : (
        <CloseIcon className={classNames(classes.closeIcon, iconClassName)} />
      )}
    </IconButton>
  )
}

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  closeIcon: {
    "& g": {
      stroke: palette.text.primary
    }
  },
  searchIcon: {
    width: 22,
    height: 22,
    "& path": {
      fill: palette.text.primary
    }
  },
  searchIconLoggedIn: {
    "& path": {
      fill: palette.rapunzel.white,
      [breakpoints.up("lg")]: {
        fill: palette.text.primary
      }
    }
  }
}))

export default SearchButton
