import api from "@starrepublic/epi/api"
import {
  ResetPasswordModel,
  TokenResponse,
  User
} from "@starrepublic/epi/api/users"
import jwt from "@starrepublic/epi/services/jwt"
import { clearSessionStorage } from "@starrepublic/epi/utils/clearSessionStorage"
import { asyncState, createStore } from "global-hook-store"

type RapunzelUser = User & {
  customerDiscount: string
  organizationNumber?: string
  market?: string
  companyName?: string
}

const authStore = createStore(
  {
    tokenResponse: asyncState<TokenResponse>(),
    user: asyncState<RapunzelUser>(),
    resetPasswordResponse: asyncState<any>(),
    changePasswordResponse: asyncState<any>(),
    resetPasswordSuccess: false,
    isLoggedIn: false,
    isInitiallyLoaded: false
  },
  {
    login: async (state, { userName, password }, { asyncAction }) => {
      clearSessionStorage()
      const { tokenResponse } = await asyncAction(
        "tokenResponse",
        api.users.login(userName, password)
      )
      if (tokenResponse.error) {
        return { ...state, tokenResponse }
      }

      if (tokenResponse.data && tokenResponse.data.access_token) {
        authStore.actions.getUser()
        state.isInitiallyLoaded = true
        return { ...state, tokenResponse, isLoggedIn: true }
      }

      return { ...state, tokenResponse, isLoggedIn: false }
    },
    resetPassword: async (_state, userName: string, { asyncAction }) =>
      asyncAction("resetPasswordResponse", api.users.resetPassword(userName)),
    changePassword: async (
      _state,
      model: ResetPasswordModel,
      { asyncAction }
    ) => asyncAction("changePasswordResponse", api.users.changePassword(model)),
    getUser: async (_state, _payload: null, { asyncAction }) =>
      asyncAction("user", api.users.getUser()),
    logout: state => {
      clearSessionStorage()
      state.isInitiallyLoaded = true
      return { ...state, isLoggedIn: false }
    },
    getToken: async state => {
      let newState = state

      if (jwt.token) {
        if (!newState.isLoggedIn) {
          // Only fetch user if it is not logged in yet
          newState = await authStore.actions.getUser()
        }

        localStorage.setItem("token", jwt.token)
        newState.isLoggedIn = true
      } else {
        newState.isLoggedIn = false
      }
      newState.isInitiallyLoaded = true
      return newState
    }
  }
)

export default authStore
