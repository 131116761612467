import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const Menu: React.FunctionComponent<Props> = props => (
  <SvgIcon {...props} viewBox="0 0 24 17">
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="square"
      strokeWidth="2"
      d="M1 8.5h22m-22-7h22m-22 14h11"
    />
  </SvgIcon>
)

export default Menu
