import ButtonBase from "@material-ui/core/ButtonBase"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import { MenuItem } from "@starrepublic/epi/types/cms/menu"
import LinkButton from "common/LinkButton"
import ListItemLink from "common/ListItemLink"
import Typography from "common/Typography"
import ArrowForward from "icons/ArrowForward"
import * as React from "react"
import { Link } from "react-router-dom"

type Props = {
  childItems: MenuItem[]
  pinnedLinkMenu: MenuItem[]
  onItemClick: () => void
}

const MegaMenuContent: React.FunctionComponent<Props> = ({
  childItems,
  onItemClick,
  pinnedLinkMenu
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container className={classes.itemContainer}>
        {childItems.map(item => {
          return (
            <Grid item md={3} key={item._id}>
              <LinkButton
                onClick={onItemClick}
                offset
                link={item.url}
                linkText={item.title}
                variant="body1"
                className={classes.itemGroupTitle}
              />
              <Typography
                component="ul"
                variant="link2"
                color="textPrimary"
                className={classes.menuItemList}
              >
                {item.children?.map(childItem => {
                  return (
                    <ButtonBase
                      component="div"
                      key={childItem._id}
                      className={classes.menuItemLinkWrapper}
                    >
                      <ListItemLink
                        onClick={onItemClick}
                        to={childItem.url}
                        disableGutters
                        className={classes.menuItemLink}
                      >
                        {childItem.title}
                      </ListItemLink>
                    </ButtonBase>
                  )
                })}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
      <Grid container className={classes.footer}>
        <Grid item md={12}>
          {!!pinnedLinkMenu.length && (
            <>
              <Divider />
              <ul className={classes.footerMenu}>
                {pinnedLinkMenu.map((item: any) => (
                  <ButtonBase
                    component="li"
                    key={item._id}
                    className={classes.footerMenuLinkItemWrapper}
                  >
                    <Link to={item.url} className={classes.footerMenuLinkItem}>
                      <Typography className={classes.footerMenuLinkItemText}>
                        {item.title}
                      </Typography>
                      <ArrowForward />
                    </Link>
                  </ButtonBase>
                ))}
              </ul>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  itemContainer: {
    letterSpacing: 0
  },
  menuItemList: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    textDecoration: "none"
  },
  menuItemLinkWrapper: {
    lineHeight: "20px",
    padding: `${spacing(0.75)}px 0`,
    border: "none",
    width: "fit-content",
    "& a": {
      color: palette.text.primary
    }
  },
  menuItemLink: {
    maxWidth: spacing(26),
    padding: 0,
    "& a": {
      textDecoration: "none",
      textTransform: "none",
      color: palette.text.primary
    },
    "&:hover": {
      textDecoration: "underline"
    }
  },
  root: {},
  itemGroupTitle: {
    marginTop: spacing(2),

    "& span": {
      fontWeight: 700
    }
  },
  footer: {
    marginTop: spacing(3)
  },
  footerMenu: {
    display: "flex",
    flexDirection: "row",
    padding: 0
  },
  footerMenuLinkItemWrapper: {
    border: "none",
    padding: `${spacing(4)}px ${spacing(2)}px`,
    marginLeft: spacing(-2),
    marginRight: spacing(14)
  },
  footerMenuLinkItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: "#000000",
    textDecoration: "underline"
  },
  footerMenuLinkItemText: {
    fontWeight: 400
  },
  arrowIcon: {
    fontSize: 16
  }
}))

export default MegaMenuContent
