import { ImageBlock } from "types/content/media"

export enum SearchType {
  PRODUCT = "Rapunzel_Storefront_Features_ProductFeature_Models_WebProduct",
  ARTICLE = "Rapunzel_Website_Shared_Features_ArticlePageFeature_Pages_ArticlePage"
}

export type Hit = {
  title: string
  title2: string
  image?: ImageBlock
  url: string
  hitType: SearchType.PRODUCT | SearchType.ARTICLE
  hitId: string
}

export type SearchResult = {
  relatedSearches: string[]
  popularSearches: string[]
  hits: Hit[]
  language: string
  trackId: string
}
