import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "common/Typography"
import React from "react"
import { useTranslation } from "react-i18next"

type Props = {
  closeDialog: () => void
}

const NewsletterDialogConfirmation: React.FunctionComponent<Props> = ({
  closeDialog
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography
          align="center"
          variant="headline2"
          className={classes.dialogHeading}
        >
          {t("newsletter.confirmation_title")}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography align="center" paragraph className={classes.paragraph}>
          {t("newsletter.confirmation_text1")}
        </Typography>

        <Typography align="center" paragraph className={classes.paragraph}>
          {t("newsletter.confirmation_text2")}
        </Typography>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          type="button"
          onClick={closeDialog}
          className={classes.ctaButton}
        >
          {t("newsletter.confirmation_shop_button")}
        </Button>
      </DialogActions>
    </div>
  )
}

const useStyles = makeStyles(
  ({ spacing, breakpoints, palette, typography }) => ({
    root: {
      padding: spacing(4),
      [breakpoints.down("sm")]: {
        padding: spacing(2)
      }
    },
    dialogTitle: {
      [breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    dialogContent: {
      [breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    dialogActions: {
      [breakpoints.down("sm")]: {
        marginLeft: 0,
        marginRight: 0
      }
    },
    dialogHeading: {
      [breakpoints.down("sm")]: {
        fontSize: typography.fontSize * 1.5
      }
    },
    paragraph: {
      marginBottom: spacing(1)
    },
    ctaButton: {
      textAlign: "center",
      color: palette.rapunzel.white,
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      flexGrow: 1,
      border: `1px solid ${palette.rapunzel.black}`,
      backgroundColor: palette.rapunzel.black,
      "&:hover": {
        backgroundColor: palette.rapunzel.white
      },
      "&[disabled]": {
        backgroundColor: palette.rapunzel.lightGray,
        color: "#000",
        cursor: "default"
      }
    }
  })
)

export default NewsletterDialogConfirmation
