import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon"
import React from "react"

type Props = SvgIconProps

const ArrowDown: React.FunctionComponent<Props> = props => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <g
      id="icon/arrow/micro"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <rect id="Rectangle" x="0" y="0" width="24" height="24" />
      <g
        id="small-right"
        transform="translate(12.000000, 13.000000) rotate(90.000000) translate(-12.000000, -13.000000) translate(10.000000, 9.000000)"
        stroke="#333333"
        strokeLinecap="square"
      >
        <polyline id="Path" points="0 0 4 4 0 8" />
      </g>
    </g>
  </SvgIcon>
)

export default ArrowDown
