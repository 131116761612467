const background = (fill: string) =>
  `url("data:image/svg+xml;utf8,${(
    `<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%'>` +
    `<rect fill='${fill}' id='canvas_background' height='100%' width='100%' y='0' x='0'/>` +
    `</svg>`
  ).replace(/#/gi, "%23")}")`

const footerBackground = background("#181918")
const menuBackground = background("#F5EBE1")

export { footerBackground, menuBackground }
