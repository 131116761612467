import Grid from "@material-ui/core/Grid/Grid"
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles"
import LinkButton from "common/LinkButton"
import LogoHeader from "common/LogoHeader"
import { browserHistory } from "common/Root"
import Typography from "common/Typography"
import Wrapper from "common/Wrapper"
import useStore from "global-hook-store"
import ArrowSmall from "icons/ArrowSmall"
import * as React from "react"
import { useTranslation } from "react-i18next"
import siteSettingStore from "stores/siteSettingsStore"

const CheckoutHeader: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    state: { lang }
  } = useStore(siteSettingStore)

  const theme = useTheme<Theme>()

  return (
    <>
      <Wrapper
        containerClassName={classes.root}
        className={classes.rootContainer}
      >
        <Grid container justifyContent="space-between">
          <Grid item xs className={classes.backToShopping}>
            <LinkButton link={`/${lang}/`} buttonClassName={classes.backButton}>
              <Typography
                variant="link1"
                className={classes.continueShoppingTypo}
              >
                <ArrowSmall
                  direction="left"
                  stroke={theme.palette.rapunzel.darkCharcoal}
                />
                {t("checkout.continue_shopping")}
              </Typography>
            </LinkButton>
          </Grid>
          <Grid item xs>
            <LogoHeader
              className={classes.logo}
              onClick={() => browserHistory.push(`/${lang}/`)}
              size="small"
            />
          </Grid>
        </Grid>
      </Wrapper>
    </>
  )
}

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  rootContainer: {
    background: palette.rapunzel.lightGray,
    position: "fixed",
    top: 0,
    zIndex: 999,
    width: "100vw"
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: spacing(8),
    background: palette.rapunzel.lightGray,
    [breakpoints.down("sm")]: {
      height: spacing(6),
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  logo: {
    display: "flex",
    marginLeft: -60,
    [breakpoints.up("lg")]: {
      marginLeft: -110
    }
  },
  continueShoppingTypo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none"
  },
  backToShopping: {
    display: "flex",
    alignItems: "center",
    flexBasis: 0
  },
  backButton: {
    paddingLeft: 0
  }
}))

export default CheckoutHeader
