import Divider from "@material-ui/core/Divider"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "common/Typography"
import useStore from "global-hook-store"
import * as React from "react"
import { useTranslation } from "react-i18next"
import cartStore from "stores/cartStore"

import CartItemList from "./CartItemList"
import CartItemListCheckout from "./CartItemListCheckout"

type Props = {
  couponCodes?: string[]
  inCheckout?: boolean
}

const OrderDetails: React.FunctionComponent<Props> = ({
  inCheckout = false
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    state: {
      carts: { data: carts }
    }
  } = useStore(cartStore)

  const content = (
    <>
      {(!carts.default || carts.default.lineItems.length === 0) && (
        <div>
          <Typography
            variant="body1"
            align="center"
            className={classes.cartMessage}
          >
            {t("cart.empty_cart")}
          </Typography>
        </div>
      )}
      <CartItemList />
    </>
  )

  const contentCheckout = (
    <div className={classes.root}>
      {(!carts.default || carts.default.lineItems.length === 0) && (
        <>
          <Typography
            variant="body1"
            align="center"
            className={classes.cartMessage}
          >
            {t("cart.empty_cart")}
          </Typography>
          <Divider />
        </>
      )}
      <CartItemListCheckout />
    </div>
  )

  return inCheckout ? contentCheckout : content
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  spinner: {
    width: spacing(2),
    height: spacing(2)
  },
  cartMessage: {
    marginTop: 26,
    marginBottom: spacing(2.5)
  }
}))

export default OrderDetails
