/**
 * Material UI theme configuration
 */
import { createTheme } from "@material-ui/core"
import createBreakpoints from "@material-ui/core/styles/createBreakpoints"

import themeColors from "./colors"
import { createMuiTypography } from "./typography"

const breakpoints = createBreakpoints({})
const typography = createMuiTypography()

export default createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: themeColors.rapunzel.primary,
      dark: themeColors.rapunzel.textDefault,
      contrastText: themeColors.rapunzel.white
    },
    text: {
      primary: themeColors.rapunzel.textDefault,
      secondary: themeColors.rapunzel.textSecondary,
      disabled: themeColors.rapunzel.textDisabled
    },
    background: {
      paper: themeColors.rapunzel.paperBackground,
      default: themeColors.rapunzel.white
    },
    rapunzel: {
      ...themeColors.rapunzel
    }
  },
  shape: {
    borderRadius: 0
  },

  // prettier-ignore
  shadows: [
      "none","none","none","none","none","none","none","none","none","none","none","none","none","none","none","none","none","none","none","none","none","none","none","none","none"
     ],

  typography: {
    ...typography
  },
  overrides: {
    MuiDivider: {
      light: {
        color: "#ddd"
      }
    },
    MuiOutlinedInput: {
      input: {
        paddingTop: 10,
        paddingBottom: 10
      }
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 1.5px) scale(1)"
      }
    },
    MuiFormLabel: {
      root: {
        "&$error": {
          color: "#CB5E4F !important"
        },
        "&$focused": {
          color: "#000 !important"
        }
      }
    },
    MuiFormHelperText: {
      root: {
        "&$error": {
          color: "#CB5E4F !important"
        }
      }
    },
    MuiCard: {
      root: {
        boxShadow: "none",
        border: "none"
      }
    },
    MuiCardContent: {
      root: {
        paddingTop: 25,
        paddingBottom: 25,
        textAlign: "center"
      }
    },
    MuiButton: {
      root: {
        backgroundColor: themeColors.rapunzel.ctaButtonDark,
        color: themeColors.rapunzel.white,
        minWidth: 200,
        padding: "12px 24px",
        "&:hover": {
          color: themeColors.rapunzel.ctaButtonDark,
          backgroundColor: themeColors.rapunzel.white,
          border: `1px solid ${themeColors.rapunzel.ctaButtonDark}`
        }
      },
      containedPrimary: {
        color: themeColors.rapunzel.white,
        backgroundColor: themeColors.rapunzel.ctaButtonDark,
        "&:hover": {
          backgroundColor: themeColors.rapunzel.white,
          color: themeColors.rapunzel.ctaButtonDark,
          border: `1px solid ${themeColors.rapunzel.ctaButtonDark}`,
          transitions: "all 0.4s"
        },
        [breakpoints.down("sm")]: {
          "&:hover": {
            color: themeColors.rapunzel.white,
            backgroundColor: themeColors.rapunzel.ctaButtonDark,
            transitions: "all 0.4s"
          }
        }
      },
      containedSecondary: {
        color: themeColors.rapunzel.ctaButtonDark,
        backgroundColor: themeColors.rapunzel.white,
        "&:hover": {
          color: themeColors.rapunzel.white,
          backgroundColor: themeColors.rapunzel.ctaButtonDark,
          transitions: "all 0.4s"
        }
      },
      outlinedSecondary: {
        color: themeColors.rapunzel.ctaButtonDark,
        backgroundColor: themeColors.rapunzel.white,
        border: `1px solid ${themeColors.rapunzel.ctaButtonDark}`,
        "&:hover": {
          color: themeColors.rapunzel.white,
          backgroundColor: themeColors.rapunzel.ctaButtonDark,
          border: `1px solid ${themeColors.rapunzel.ctaButtonDark}`,
          transitions: "all 0.4s"
        },
        [breakpoints.down("sm")]: {
          "&:hover": {
            color: themeColors.rapunzel.ctaButtonDark,
            border: `1px solid ${themeColors.rapunzel.ctaButtonDark}`,
            backgroundColor: themeColors.rapunzel.white
          }
        }
      }
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: themeColors.rapunzel.darkGray
      }
    },
    MuiFilledInput: {
      root: {
        transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        backgroundColor: "rgba(0, 0, 0, 0.09)",
        padding: "18px 16px",
        fontSize: "1rem",
        "&$disabled": {
          color: "rgba(0, 0, 0, 0.6)"
        }
      }
    },
    MuiSelect: {
      filled: {
        position: "relative",
        transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        backgroundColor: "rgba(0, 0, 0, 0.09)",
        padding: "18px 16px",
        fontSize: "1rem",
        "&$disabled": {
          color: "rgba(0, 0, 0, 0.6)"
        },
        "& ~ svg": {
          right: 16,
          width: 22,
          height: 22,
          fill: "#000"
        },
        [breakpoints.down("sm")]: {
          width: "calc(100% - 32px)"
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        "&$expanded": {
          minHeight: "48px"
        },
        "&$disabled": {
          opacity: 1
        }
      }
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          overflowY: "scroll"
        },
        body: {
          paddingRight: "0 !important"
        },
        "#cevoid-modal-portal,  #cevoid-container": {
          fontFamily: "'Circular Pro','Helvetica Neue',Helvetica,sans-serif",
          fontWeight: 300
        },
        ".cevoid-product-link .cevoid-product-details .cevoid-price .cevoid-original-price":
          {
            fontWeight: `300 !important` as any
          },
        ".cevoid-tooltip-footer .cevoid-tooltip-price .cevoid-original-price": {
          fontWeight: `300 !important` as any
        }
      }
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "rgba(0,0,0,0.2)"
      }
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  }
})
