import ListItem, { ListItemProps } from "@material-ui/core/ListItem"
import RelativeLink from "@starrepublic/epi/cms/components/RelativeLink"
import * as React from "react"
import { LinkProps } from "react-router-dom"

type CompatibleLinkProps = Pick<LinkProps, Exclude<keyof LinkProps, "innerRef">>

type Props = CompatibleLinkProps & ListItemProps

const ListItemLink: React.FunctionComponent<Props> = ({ to, children }) => (
  <ListItem>
    <RelativeLink to={to.toString()}>{children}</RelativeLink>
  </ListItem>
)

export default ListItemLink
