import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import ArrowForward from "icons/ArrowForward"
import React from "react"
import { useTranslation } from "react-i18next"

import LinkButton from "./LinkButton"

type Props = {
  className?: string
  classText?: string
  classBtn?: string
  placement: string
  closeDrawer?: (isOpen: boolean) => void
}

const KlearInfluencerForm: React.FC<Props> = ({
  className,
  classText,
  classBtn,
  placement,
  closeDrawer
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const handleButtonClick = () => {
    window.klrRecruit.openRecruit()

    if (closeDrawer) {
      closeDrawer(false)
    }
  }

  return window.KLEAR_ENABLED ? (
    <>
      {placement === "TopHeader" && (
        <div className={classNames(classes.container, className)}>
          <LinkButton
            link=""
            onClick={handleButtonClick}
            variant="body2"
            className={classes.btnLink}
            linkContentClassName={classNames(classes.linkText, classText)}
          >
            {t("common.klear_influencer").toUpperCase()}
          </LinkButton>
        </div>
      )}

      {placement === "FooterMenu" && (
        <LinkButton
          link=""
          onClick={handleButtonClick}
          variant="link1"
          className={classes.footerLink}
          linkContentClassName={classNames(classes.linkText, classText)}
          buttonClassName={classes.linkButton}
        >
          {t("common.klear_influencer")}
          <ArrowForward />
        </LinkButton>
      )}

      {placement === "MobileMenu" && (
        <LinkButton
          link=""
          onClick={handleButtonClick}
          variant="body1"
          className={classNames(classes.btnLink, classBtn)}
          buttonClassName={classes.mobileButton}
        >
          {t("common.klear_influencer").toUpperCase()}
        </LinkButton>
      )}
    </>
  ) : null
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexGrow: 1
  },
  btnLink: {
    "&> button": {
      height: "100%",
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  linkSpacing: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1)
  },
  linkText: {
    color: "rgba(0, 0, 0, 0.54)",
    textDecoration: "none"
  },
  footerLink: {
    "& a": {
      color: palette.primary.contrastText,
      display: "flex"
    }
  },
  linkButton: {
    padding: `${spacing(1)}px 0`
  },
  mobileButton: {
    padding: `${spacing(0.5)}px 0`
  }
}))

export default KlearInfluencerForm
