import ButtonBase from "@material-ui/core/ButtonBase"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "common/Typography"
import useStore from "global-hook-store"
import * as React from "react"
import { useTranslation } from "react-i18next"
import cartStore from "stores/cartStore"
import checkoutStore from "stores/checkoutStore"

const AppliedCoupon: React.FunctionComponent = () => {
  const {
    state: {
      carts: { data: carts }
    }
  } = useStore(cartStore)

  const {
    actions: { removeCouponCode, setShowCoupon }
  } = useStore(checkoutStore)

  const { t } = useTranslation()
  const classes = useStyles()

  const basket = carts.default

  return (
    (basket && basket.couponCodes && !!basket.couponCodes.length && (
      <div className={classes.root}>
        <Grid container className={classes.grid}>
          <Grid item xs={6} className={classes.gridItem}>
            <Typography component="span">{basket.couponCodes[0]}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.gridItem}>
            <ButtonBase
              onClick={() => {
                removeCouponCode(basket.couponCodes[0])
                setShowCoupon(false)
              }}
              className={classes.removeButton}
            >
              <Typography component="span">
                {t("cart.remove_coupon")}
              </Typography>
            </ButtonBase>
          </Grid>
        </Grid>
      </div>
    )) ||
    null
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(3),
    marginRight: 2
  },
  grid: {
    marginBottom: spacing(0.5)
  },
  gridItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignSelf: "center",
    "& > span": {
      marginTop: 2
    },
    "&:last-child": {
      justifyContent: "flex-end"
    }
  },
  removeButton: {
    padding: `${spacing(1)}px 0`,
    margin: `${spacing(1)}px 0`,
    "& > span": {
      textDecoration: "underline"
    }
  }
}))

export default AppliedCoupon
