import Box, { BoxProps } from "@material-ui/core/Box"
import { useTheme } from "@material-ui/core/styles"
import { Breakpoints } from "@material-ui/core/styles/createBreakpoints"
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react"

export type ImageProps = Omit<
  JSX.IntrinsicElements["img"],
  "src" | "srcSet" | "width" | "height"
> & {
  alt?: string
  src?: JSX.IntrinsicElements["img"]["src"]
  srcSet?: JSX.IntrinsicElements["img"]["srcSet"] | null
  width?: BoxProps["width"]
  originWidth?: number
  height?: BoxProps["height"]
  lazy?: boolean
  color?: string
  containerProps?: BoxProps
  objectFit?: CSSProperties["objectFit"]
  objectPosition?: CSSProperties["objectPosition"]
  title?: string
  aspectRatio?: CSSProperties["aspectRatio"] | number
  disableSkeleton?: boolean
  fetchpriority?: string
}

const IMAGE_SIZE_BASIS = 128

// Source can be found in ./public/img/ShimmerSkeletonSvg.svg
export const SHIMMER_LOADING_IMAGE =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMDAgMjAwIj4KICAgIDxkZWZzPgogICAgICAgIDxsaW5lYXJHcmFkaWVudCBpZD0iZ3JhZGllbnQiIHgxPSIwIiB5MT0iMCIgeDI9IjEiIHkyPSIxIiBncmFkaWVudFRyYW5zZm9ybT0icm90YXRlKDQ1KSI+CiAgICAgICAgICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiNmN2Y3ZjciIC8+CiAgICAgICAgICAgIDxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjZTdlN2U3IiAvPgogICAgICAgICAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmN2Y3ZjciIC8+CiAgICAgICAgICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9ImdyYWRpZW50VHJhbnNmb3JtIiB0eXBlPSJ0cmFuc2xhdGUiIHZhbHVlcz0iLTEgMDsgMSAwOyAtMSAwIiBkdXI9IjJzIgogICAgICAgICAgICAgICAgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiIC8+CiAgICAgICAgPC9saW5lYXJHcmFkaWVudD4KICAgIDwvZGVmcz4KICAgIDxyZWN0IGZpbGw9InVybCgjZ3JhZGllbnQpIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgLz4KPC9zdmc+"

export const nearestDimension = (width: ImageProps["width"]) =>
  Math.max(
    IMAGE_SIZE_BASIS,
    Number(width) - (Number(width) % IMAGE_SIZE_BASIS) + IMAGE_SIZE_BASIS
  )

const findParentDimensionsRecursively = (
  element: HTMLElement | null | undefined
): DOMRect | null => {
  if (!element) {
    return null
  }
  const rect = element.getBoundingClientRect()

  if (rect.width > 0) {
    return rect
  }

  return findParentDimensionsRecursively(element.parentElement)
}

const generateSourceSetValues = (
  src: string,
  containerWidth: ImageProps["width"],
  originWidth: number | undefined = undefined
) => {
  const width = nearestDimension(containerWidth)
  const queryBinder = src.includes("?") ? "&" : "?"

  if (originWidth && originWidth <= width) {
    return [`${src}${queryBinder}w=${originWidth}`]
  }

  return Array.from({ length: 2 }, (v, k) => k + 1).map(
    v => `${src}${queryBinder}w=${width * v}`
  )
}

const getClosestMuiBreakpoint = (
  breakpoints: Breakpoints,
  dimensions: BoxProps["width"] | BoxProps["height"]
) => {
  if (typeof dimensions === "undefined") {
    return "xs"
  }
  // Get highest matching breakpoints in image by viewport width or height
  const matchingBreakpoint = breakpoints.keys
    .slice()
    .reverse()
    .filter(breakpoint => typeof dimensions[breakpoint] !== "undefined")
    .find(breakpoint => window.innerWidth >= breakpoints.values[breakpoint])
  return matchingBreakpoint || "xs"
}

const Image = ({
  alt = "",
  src,
  srcSet,
  lazy = true,
  color = "transparent",
  containerProps,
  objectFit = "cover",
  objectPosition = "center",
  title,
  width = "100%",
  height = "auto",
  aspectRatio,
  disableSkeleton,
  fetchpriority,
  originWidth,
  ...rest
}: ImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [actualWidth, setActualWidth] = useState<BoxProps["width"]>(width)
  const [actualHeight, setActualHeight] = useState<BoxProps["height"]>(height)
  const theme = useTheme()

  const imageRef = useRef<HTMLImageElement>(null)

  const setImageSize = useCallback(() => {
    // Set correct image size if the image is lacking width, height or aspect ratio
    let actualWidth = width
    let actualHeight = height
    let surroundingElementRect

    const surroundingElement = imageRef.current?.parentElement?.parentElement

    if (typeof width === "object" && Object.keys(width).length > 0) {
      // Width is an object with breakpoints
      const closestBreakpoint = getClosestMuiBreakpoint(
        theme.breakpoints,
        width
      )
      actualWidth = width[closestBreakpoint] || width
    }

    if (typeof height === "object" && Object.keys(height).length > 0) {
      // Height is an object with breakpoints
      const closestBreakpoint = getClosestMuiBreakpoint(
        theme.breakpoints,
        height
      )
      actualHeight = height[closestBreakpoint] || height
    }

    if (
      (actualWidth.toString().endsWith("%") ||
        actualWidth === "auto" ||
        actualHeight.toString().endsWith("%") ||
        actualHeight === "auto") &&
      surroundingElement
    ) {
      surroundingElementRect =
        findParentDimensionsRecursively(surroundingElement)
    }

    if (
      (actualWidth.toString().endsWith("%") || actualWidth === "auto") &&
      surroundingElementRect
    ) {
      // If percentage width, get actual width from container
      actualWidth = Math.ceil(surroundingElementRect.width)
    }

    if (
      (actualHeight.toString().endsWith("%") || actualHeight === "auto") &&
      surroundingElementRect
    ) {
      // If percentage height, get actual height from container
      actualHeight = Math.ceil(surroundingElementRect.height)
    }

    setActualWidth(actualWidth)
    setActualHeight(actualHeight)
  }, [width, height, theme.breakpoints])

  useEffect(setImageSize, [setImageSize])

  let computedSrc

  if (typeof actualWidth !== "number") {
    computedSrc = SHIMMER_LOADING_IMAGE
  } else if (!srcSet && srcSet !== null) {
    // srcSet isn't set, but if it's null we really don't want a srcset at all
    const srcSetValues =
      (src && generateSourceSetValues(src, actualWidth, originWidth)) || []
    computedSrc = srcSetValues[0] ? srcSetValues[0] : ""
    srcSet = srcSetValues.map((set, index) => `${set} ${index + 1}x`).toString()
  } else {
    computedSrc = src
  }

  if (!computedSrc) {
    return null
  }

  // Remove not needed props from API
  delete rest["id"]
  delete rest["type"]
  delete rest["properties"]

  return (
    <Box
      display="flex"
      width={width}
      height={height}
      maxWidth="100%"
      {...containerProps}
      style={{
        ...containerProps?.style,
        ...(color !== "transparent" && { backgroundColor: color }),
        ...(!disableSkeleton &&
          !isLoaded && {
            backgroundColor: "#f7f7f7",
            backgroundImage: `url(${SHIMMER_LOADING_IMAGE})`,
            backgroundSize: "cover"
          })
      }}
    >
      <img
        src={computedSrc}
        {...(srcSet && { srcSet })}
        {...(lazy && { loading: "lazy" })}
        width={actualWidth as number}
        height={actualHeight as number}
        title={title}
        alt={alt}
        draggable={false}
        ref={imageRef}
        fetchpriority={fetchpriority}
        style={{
          width: "100%",
          height: "100%",
          objectFit,
          objectPosition,
          opacity: isLoaded || !lazy ? 1 : 0,
          transition: "opacity 200ms ease-in-out",
          ...(aspectRatio && { aspectRatio: aspectRatio.toString() })
        }}
        {...rest}
        onLoad={() => setIsLoaded(true)}
        onError={() => setIsLoaded(true)}
      />
    </Box>
  )
}

export default Image
